import { Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { AssigneeInterface } from '../../../../../../../interfaces/assignee.interface';
import { AssignmentInterface } from '../../../../../../../interfaces/assignment.interface';
import { TaskInterface } from '../../../../../../../interfaces/task.interface';
import { TasksHelperService } from '../../../../../../../services/helper.service';
import { Subscription } from 'rxjs';
import { AssignmentService } from '../../../../network/assignment.service';
import { TasksService } from '../../../../../../../network/tasks.service';
import { AssignmentResponseInterface } from '../../../../../../../interfaces/responses.interface';
import { SubtaskService } from '../../../../../../../network/subtask.service';
import { SubtaskInterface } from '../../../../../../../interfaces/subtask.interface';
import { TasksSettingsService } from '../../../../../../../services/tasks-settings.service';

@Component({
  selector: 'troi-task-modal-assigned-employee',
  templateUrl: './assigned-employee.component.html',
  styleUrls: ['./assigned-employee.component.scss'],
})
export class AssignedEmployeeComponent implements OnInit, OnDestroy {
  @Input() assignee: AssigneeInterface;
  @Input() task: TaskInterface | SubtaskInterface;
  @Input() parentTask: TaskInterface;
  @Input() subtaskLayout = false;
  @Input() isSubtask = false;
  @Input() hideTimerange = false;
  @Input() showProgressCircle = true;
  @Output() assigneeToRemove = new EventEmitter<AssigneeInterface>();
  @Output() showPopover = new EventEmitter<boolean>();

  public get assigneeHours(): number {
    return this.assignee.minutesTotal ? this.assignee.minutesTotal / 60 : 0;
  }
  public set assigneeHours(val: number) {
    this.assignee.minutesTotal = val * 60;
  }

  private subscriptions: Subscription = new Subscription();
  public assignHours = false;
  public showTimerange = false;
  public showTrashIcon = false;
  public dateNow = new Date();

  public taskLoaded = false;

  constructor(
    private helperService: TasksHelperService,
    private assignmentService: AssignmentService,
    private tasksService: TasksService,
    public settingsService: TasksSettingsService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.helperService.assignHoursSub.subscribe((assignHours) => {
        this.assignHours = assignHours;
        if (!this.assignee.minutesTotal) this.calcTotalHoursOfAssignee();
      }),
    );
  }

  public getMinDate() {
    if (this.task.startDate && this.task.startDate !== undefined) {
      return moment(this.task.startDate);
    } else {
      return moment(this.dateNow);
    }
  }

  public getMaxDate() {
    if (this.task.endDate && this.task.endDate !== undefined) {
      return moment(this.task.endDate);
    }
  }

  public getTodaysDate(): string {
    const today = new Date();
    return today.toString();
  }

  public calcTotalHoursOfAssignee(): void {
    this.assignee.minutesTotal = this.assignee.assignments.reduce(
      (accumulator, currentValue) => accumulator + currentValue.minutes,
      0,
    );
    this.calcTotalHoursOfTask();
  }

  public saveHoursAssignment(hoursSpent: number) {
    const minutesSpent = hoursSpent * 60;
    this.assignee.minutesTotal = minutesSpent;
    this.subscriptions.add(
      this.tasksService.updateAssignee(this.task.id, this.assignee).subscribe(() => {
        this.helperService.setTaskHoursChanged(hoursSpent);
      }),
    );
  }

  public calcTotalHoursOfTask(): void {
    let totalAssignedHours = 0;
    let totalAssignedSubtaskHours = 0;

    if (!this.isSubtask) {
      totalAssignedHours = this.calculateTotalAssignedHours(this.task.assignees);

      if ((this.task as TaskInterface).subtasks) {
        (this.task as TaskInterface).subtasks.forEach((subtask) => {
          totalAssignedSubtaskHours += this.calculateTotalAssignedHours(subtask.assignees);
        });
      }
    } else {
      totalAssignedHours = this.calculateTotalAssignedHours(this.parentTask.assignees);

      if (this.parentTask.subtasks) {
        this.parentTask.subtasks.forEach((subtask) => {
          totalAssignedSubtaskHours += this.calculateTotalAssignedHours(subtask.assignees);
        });
      }
    }

    totalAssignedHours += totalAssignedSubtaskHours;

    const targetTask = this.isSubtask ? this.parentTask : this.task;

    if (!(targetTask as TaskInterface).hoursBudget || totalAssignedHours > (targetTask as TaskInterface).hoursBudget) {
      (targetTask as TaskInterface).hoursBudget = totalAssignedHours;
    }
  }

  private calculateTotalAssignedHours(assignees: any[]): number {
    if (assignees)
      return (
        assignees.reduce(
          (accumulator, currentValue) => accumulator + (currentValue.minutesTotal ? currentValue.minutesTotal : 0),
          0,
        ) / 60
      );
  }

  public addTimerange(): void {
    if (this.task.startDate && this.task.endDate) {
      const assignment: AssignmentInterface = {
        startDate: new Date(),
        endDate: new Date(),
        minutes: 0,
        assignee: {
          id: this.assignee.id,
        },
      };

      // setting the time is necessary for the timerange selector
      assignment.startDate.setHours(0, 0, 0, 0);
      assignment.endDate.setHours(23, 59, 59, 0);

      this.subscriptions.add(
        this.assignmentService
          .createAssignment(assignment, this.isSubtask)
          .subscribe((res: AssignmentResponseInterface) => {
            // necessary to avoid another data fetch in the backend
            assignment.id = (res.data as AssignmentInterface).id;
            this.assignee.assignments.push(assignment);
          }),
      );
    } else this.showPopover.emit();
  }

  public removeAssigneeFromTask(): void {
    this.assigneeToRemove.emit(this.assignee);
  }

  public updateAssignmentRange(dates: any, assignment: AssignmentInterface): void {
    const changedStartDate = new Date(dates.date[0]);
    const changedEndDate = new Date(dates.date[1]);
    const startDate = new Date(assignment.startDate);
    const endDate = new Date(assignment.endDate);

    if (startDate.getDate() - changedStartDate.getDate() !== 0 || endDate.getDate() - changedEndDate.getDate() !== 0) {
      assignment.startDate = changedStartDate;
      assignment.endDate = changedEndDate;
      // necessary to avoid circular structure for JSON.stringify() (assignees are just references)
      assignment.assignee = undefined;
      this.updateAssignment(assignment);
    }
  }

  public updateAssignment(assignment: AssignmentInterface) {
    this.subscriptions.add(this.assignmentService.updateAssignment(assignment, this.isSubtask).subscribe());
  }

  public deleteAssignment(assignment: AssignmentInterface): void {
    this.assignee.assignments = this.assignee.assignments.filter((x) => x.id !== assignment.id);
    this.calcTotalHoursOfAssignee();

    this.subscriptions.add(this.assignmentService.deleteAssignment(assignment, this.isSubtask).subscribe());
  }

  public getAssignedHours(assignment: AssignmentInterface) {
    return assignment.minutes / 60;
  }

  public setAssignedHours(newVal: number, assignment: AssignmentInterface) {
    assignment.minutes = newVal * 60;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
