import { FilterElementInterface } from '../troi-filter-with-modal/filter-element.interface';
import { Currency } from '../troi-money/currency';
import { ForecastViewEnum } from '../../modules/tasks/enum/forecast-view';

export class FiltersInterface {
  search: string;

  dropdownFirst: number;

  dropdownSecond: number | string | number[] | string[];

  dropdownThird?: number | string | number[] | string[];

  isWorkLoadInPercent?: boolean;

  calendarScaleUnit?: ForecastViewEnum;

  filters: FilterElementInterface[];

  currentPage: number;

  pageSize: number;

  sortingDir?: string;

  sortBy?: string;

  dropdownOptional?: FilterElementInterface;
}

export class CurrencyMoneyInterface {
  currency: Currency;

  decimalPlaces: number;
}
