<form
  class="timeentry"
  [formGroup]="timeEntryForm"
  (mouseenter)="showTrashIcon = true"
  (mouseleave)="showTrashIcon = false"
>
  <troi-person
    class="timeentry__assigned-person"
    [person]="timeEntry.employee"
    [large]="true"
  ></troi-person>

  <div class="flex-2 timeentry__datepicker">
    <tnd-datepicker
      [validationEnabled]="false"
      [rangeFrom]="getEntryDate()"
      [singleDatePicker]="true"
      [emitIfEmpty]="true"
      [minDate]="minDate"
      (rangeChanged)="dateChanged($event)"
      [paddingLeft]="'40px'"
      [disabled]="isDisabled()"
    ></tnd-datepicker>
  </div>

  <div class="tnd-text-input input-time flex-1 timeentry__clock">
    <troi-icon [icon]="'icon-clock-open'"></troi-icon>

    <input
      (click)="openTimePicker()"
      id="time-picker"
      #timePicker
      type="time"
      formControlName="time"
      [(ngModel)]="timeEntry.quantity"
      (focusout)="updateTimeEntry()"
      [disabled]="isDisabled()"
    />
  </div>

  <div *ngIf="!singleComment && internalCommentVisible" class="flex-2">
    <troi-input-field
      title="{{ timeEntry.internalComment }}"
      [(ngModel)]="timeEntry.internalComment"
      class="tnd-text-input input-time ng-valid ng-touched ng-dirty"
      type="text"
      class="comment"
      [placeholder]="'Interner Kommentar'"
      formControlName="internalComment"
      (focusout)="onFocusOut()"
      [disabled]="isDisabled()"
    ></troi-input-field>
  </div>

  <div *ngIf="!singleComment" class="flex-2">
    <troi-input-field
      title="{{ timeEntry.externalComment }}"
      [(ngModel)]="timeEntry.externalComment"
      type="text"
      class="comment"
      [placeholder]="'Externer Kommentar'"
      formControlName="externalComment"
      (focusout)="onFocusOut()"
      [disabled]="isDisabled()"
    >
    </troi-input-field>
  </div>

  <troi-input-field
    *ngIf="singleComment"
    title="{{ timeEntry.internalComment }}"
    class="single-comment-input"
    [(ngModel)]="timeEntry.internalComment"
    formControlName="internalComment"
    [icon]="'icon-can-edit'"
    [iconSize]="'15px'"
    focus-directive
    [placeholder]="'Kommentar hinzufügen'"
    [fieldInvalid]="false"
    (focusout)="onFocusOut()"
    [disabled]="isDisabled()"
  ></troi-input-field>

  <div class="timeentry__trash mr-45">
    <troi-icon
      *ngIf="showTrashIcon && !isDisabled()"
      (click)="onDeleteEntryClick()"
      class="timeentry__trash--icon"
      [icon]="'icon-trash-can'"
    ></troi-icon>
  </div>
</form>
