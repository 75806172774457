<form [formGroup]="moveCopyTaskForm" class="move-copy-task">
  <troi-modal-header (modalWantClose)="close()" [askBeforeClose]="true">
    <span>{{ translations.title | translate }}</span>
  </troi-modal-header>
  <troi-modal-content style="overflow-y: inherit">
    <troi-modal-row [withLabel]="false" [noRwd]="true" class="content__name">
      <troi-input-field
        row-value
        formControlName="projectName"
        focus-directive
        cssSelector=".troi-input-field__input"
        [(ngModel)]="taskTitle"
        [placeholder]="'Tasks.labels.moveCopyModal.fillProjectNameField' | translate"
        [fieldInvalid]="moveCopyTaskForm.status === 'INVALID' && submitted"
        [validationEnabled]="true"
        [outline]="true"
        requiredFieldErrorMessage="Tasks.labels.moveCopyModal.fillProjectNameField"
      ></troi-input-field>
    </troi-modal-row>

    <tnd-search-select
      *ngIf="!task.calculationPosition?.client.mandant.id"
      #clientSelect
      [loading]="!loadedClients"
      [transparentBackground]="true"
      (searchStringChanged)="onClientSearchStringChanged($event)"
      [searchResult]="resultClients"
      labelFieldName1="name"
      valueFieldName="id"
      [placeholder]="'Common.labels.client' | translate"
      [icon]="'icon-persons-with-enter'"
      (valueSelected)="onClientSelected($event)"
    ></tnd-search-select>

    <tnd-search-select
      #customerSelect
      [transparentBackground]="true"
      (searchStringChanged)="onCustomerSearchStringChanged($event)"
      [searchResult]="resultCustomers"
      [placeholder]="'Common.labels.customer' | translate"
      placeholder="{{ 'Common.labels.customer' | translate }}"
      labelFieldName1="name"
      valueFieldName="id"
      [icon]="'icon-person-with-list'"
      (valueSelected)="onCustomerSelected($event)"
      [disabled]="!taskToCopy.calculationPosition.client.mandant"
      [hideCloseIcon]="!taskToCopy.calculationPosition.client.mandant"
      [loading]="!loadedCustomers"
    ></tnd-search-select>

    <tnd-search-select
      #projectSelect
      [loading]="!loadedProjects"
      [transparentBackground]="true"
      (searchStringChanged)="onProjectSearchStringChanged($event)"
      [searchResult]="resultProjects"
      [placeholder]="'Common.labels.project' | translate"
      labelFieldName1="name"
      valueFieldName="id"
      [icon]="'icon-folder'"
      (valueSelected)="onProjectSelected($event)"
      [disabled]="
        !taskToCopy.calculationPosition.client.mandant || !taskToCopy.calculationPosition.client
      "
      [hideCloseIcon]="
        !taskToCopy.calculationPosition.client.mandant || !taskToCopy.calculationPosition.client
      "
    ></tnd-search-select>

    <tnd-search-select
      #calcPosSelect
      [transparentBackground]="true"
      (searchStringChanged)="onCalcPosSearchStringChanged($event)"
      [showSpinner]="false"
      [searchResult]="findCalcPosition()"
      [placeholder]="'Common.labels.cp' | translate"
      labelFieldName1="name"
      valueFieldName="id"
      [icon]="'icon-calc'"
      (valueSelected)="onCalcPosSelect($event)"
      [disabled]="
        !taskToCopy.calculationPosition.client.mandant || !taskToCopy.calculationPosition.client
      "
      [hideCloseIcon]="
        !taskToCopy.calculationPosition.client.mandant || !taskToCopy.calculationPosition.client
      "
    ></tnd-search-select>

    <div *ngIf="taskToCopy.calculationPosition.project.id" class="assignee-selection">
      <troi-task-modal-select-assignee
        [updateTaskDirectly]="false"
        [task]="taskToCopy"
      ></troi-task-modal-select-assignee>
    </div>

    <div *ngIf="taskToCopy.assignees && taskToCopy.assignees.length > 0" class="assigned-employees">
      <troi-task-modal-assigned-employee
        *ngFor="let assignee of taskToCopy.assignees"
        [assignee]="assignee"
        (assigneeToRemove)="removeAssigneeFromTask($event)"
        [task]="taskToCopy"
        [isEmpty]="true"
        [hideTimerange]="true"
        [showProgressCircle]="false"
      ></troi-task-modal-assigned-employee>
    </div>
  </troi-modal-content>
  <troi-modal-footer>
    <troi-btn class="modal-confirmation__button footer__button" [outline]="true" (click)="close()">
      {{ translations.cancelBtnTitle | translate }}
    </troi-btn>
    <troi-btn
      class="modal-confirmation__button footer__button"
      [outline]="false"
      (click)="execute()"
    >
      {{ translations.executeBtnTitle | translate }}
    </troi-btn>
  </troi-modal-footer>
</form>
