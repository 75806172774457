import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, Observable, Subject, take } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { Routes } from '../enum/routes';

import {
  EmployeeResponseInterface,
  ProjectResponseInterface,
  StatusResponseInterface,
  SubtaskResponseInterface,
  TagDropdownResponseInterface,
  TagResponseInterface,
  TasksResponseInterface,
  TeamResponseInterface,
} from '../interfaces/responses.interface';
import { StatusInterface } from '../interfaces/status.interface';
import { PersonInterface } from '../../../shared/troi-person/interfaces/person.interface';
import { TaskModel } from '../models/task.model';
import { TaskInterface } from '../interfaces/task.interface';
import { AssigneeInterface } from '../interfaces/assignee.interface';
import { ProjectInterface } from '../interfaces/project.interface';
import { SubtaskInterface } from '../interfaces/subtask.interface';
import { TasksHelperService } from '../services/helper.service';
import { UserService } from '../../../core/user/user.service';
import { TagDropdownInterface, TagInterface } from '../interfaces/tag.interface';
import { ForecastTeamInterface } from '../interfaces/forecast.interface';

@Injectable()
export class TasksService {
  isLoading = false;
  totalCount = 0;
  listLoaded = new Subject();
  statusOptionsLoaded = new BehaviorSubject(false);
  employeeOptionsLoaded = new BehaviorSubject(false);
  projectOptionsLoaded = new BehaviorSubject(false);

  private headers: HttpHeaders;

  public tasks: TaskModel[] = [];
  public resourceTasks: ForecastTeamInterface[] = [];
  public statusOptions: StatusInterface[] = [];

  public employeeOptions: PersonInterface[] = [];
  public projectOptions: ProjectInterface[] = [];
  public tagOptions: TagDropdownInterface[] = [];
  private project: ProjectInterface;

  public constructor(
    private http: HttpClient,
    private helperService: TasksHelperService,
    private userService: UserService,
  ) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');

    this.listLoaded.subscribe(() => this.getStatuses());
    // take --> auto-unsubscribe
    this.getEmployeeOptions()
      .pipe(
        take(1),
        catchError((e: any) => {
          console.log(e.toString());
          this.employeeOptionsLoaded.next(true);
          return [];
        }),
      )
      .subscribe((res: EmployeeResponseInterface) => {
        this.employeeOptions = res.data.map((e: PersonInterface) => {
          e.name = `${e.firstName} ${e.lastName}`;
          return e;
        });
        this.employeeOptionsLoaded.next(true);
      });

    this.getProjectOptions()
      .pipe(
        take(1),
        catchError((e: any) => {
          console.log(e.toString());
          this.projectOptionsLoaded.next(true);
          return [];
        }),
      )
      .subscribe((res: ProjectResponseInterface) => {
        this.projectOptions = res.data;
        this.projectOptionsLoaded.next(true);
      });

    this.getTagOptions().subscribe((res) => {
      this.tagOptions = res.items as TagDropdownInterface[];
    });

    this.helperService.projectId.subscribe((value) => {
      if (value)
        this.project = {
          id: +value,
        };
    });
  }

  public getStatuses() {
    this.getStatusOptions()
      .pipe(
        take(1),
        catchError((e: any) => {
          console.log(e.toString());
          this.statusOptionsLoaded.next(true);
          return [];
        }),
      )
      .subscribe((res: StatusResponseInterface) => {
        const statusOptions = res.data as StatusInterface[];

        this.statusOptions = statusOptions.sort((a, b) => {
          if (a.statusGroup.id === 5) return 1;
          else if (b.statusGroup.id === 5) return -1;
          else return a.orderId - b.orderId;
        });

        this.statusOptionsLoaded.next(true);
      });
  }

  public loadTaskList(taskUrl: string) {
    this.isLoading = true;
    this.getTasks(taskUrl)
      .pipe(
        take(1),
        catchError((e: any) => {
          console.log(e.toString());
          this.isLoading = false;
          this.totalCount = 0;
          this.listLoaded.next(true);
          return [];
        }),
      )
      .subscribe((result: TasksResponseInterface) => {
        this.isLoading = false;

        if (Array.isArray(result.data)) {
          this.tasks = result.data.map((t: TaskModel) => {
            return new TaskModel(t);
          });
        } else {
          this.resourceTasks = [result.data];
        }

        this.totalCount = result.totalCount;
        this.listLoaded.next(true);
      });
  }

  private getTasks(url: string): Observable<TasksResponseInterface> {
    return this.http.get<TasksResponseInterface>(url, {
      headers: this.headers,
    });
  }

  public createTask(newTask: TaskInterface): Observable<TasksResponseInterface> {
    const task = {
      title: newTask.title,
      description: newTask.description,
      priority: newTask.priority,
      startDate: newTask.startDate ?? undefined,
      endDate: newTask.endDate ?? undefined,
      subtaskAll: newTask.subtaskAll ? newTask.subtaskAll : 0,
      subtaskFinished: newTask.subtaskFinished ? newTask.subtaskFinished : 0,
      hoursBudget: newTask.hoursBudget ? newTask.hoursBudget : 0,
      hoursSpent: newTask.hoursSpent ? newTask.hoursSpent : 0,
      averageDailyHours: newTask.averageDailyHours ? newTask.averageDailyHours : 8,
      projectpath: newTask.projectpath ? newTask.projectpath : 'Unknown',
      isDeleted: false,
      createdBy: {
        id: this.userService.getUser().id,
      },
      clientId: newTask.clientId,
      project: this.project ? this.project : newTask.project,
      calculationPosition: newTask.calculationPosition,
      statusGroup: newTask.statusGroup,
      status: newTask.status ?? this.statusOptions[0],
      subtasks: newTask.subtasks,
      comments: newTask.comments,
      attachments: newTask.attachments,
      assignees: newTask.assignees,

      kanbanOrder: newTask.kanbanOrder
        ? newTask.kanbanOrder
        : this.tasks.length > 0
        ? this.tasks[this.tasks.length - 1].kanbanOrder + 10000
        : 100000,
    };

    return this.http.post<TasksResponseInterface>(environment.url + Routes.TASKS, task, {
      headers: this.headers,
    });
  }

  public updateTask(task: TaskModel | SubtaskInterface): Observable<TasksResponseInterface> {
    return this.http.put<TasksResponseInterface>(environment.url + Routes.TASKS + `/${task.id}`, task, {
      headers: this.headers,
    });
  }

  public updateAssignee(taskId: string, assignee: AssigneeInterface): Observable<any> {
    return this.http.put<any>(environment.url + Routes.TASKS + `/${taskId}/assignee/${assignee.id}`, assignee, {
      headers: this.headers,
    });
  }

  public deleteTask(task: TaskModel): Observable<TasksResponseInterface> {
    return this.http.delete<TasksResponseInterface>(environment.url + Routes.TASKS + `/${task.id}`);
  }

  public getTagOptions(): Observable<TagDropdownResponseInterface> {
    return this.http.get<TagDropdownResponseInterface>(environment.url + Routes.TAGS_DROPDOWN, {
      headers: this.headers,
    });
  }

  private getStatusOptions(): Observable<StatusResponseInterface> {
    const clientId = new URLSearchParams(window.parent.location.search).get('client');
    const client = clientId ? `?mandantId=${clientId}` : '?mandantId=*';
    return this.http.get<StatusResponseInterface>(environment.url + Routes.STATUS + client, {
      headers: this.headers,
    });
  }

  private getEmployeeOptions(): Observable<EmployeeResponseInterface> {
    return this.http.get<EmployeeResponseInterface>(environment.url + Routes.EMPLOYEES, {
      headers: this.headers,
    });
  }

  private getProjectOptions(): Observable<ProjectResponseInterface> {
    return this.http.get<ProjectResponseInterface>(environment.url + Routes.PROJECTS, {
      headers: this.headers,
    });
  }

  public getAssigneeOptions(
    projectId?: number,
    clientId?: number,
    startDate?: number,
    endDate?: number,
  ): Observable<EmployeeResponseInterface> {
    let searchQuery = '?';
    if (projectId) searchQuery += `clientId=${clientId}&`;
    if (projectId) searchQuery += `projectId=${projectId}&`;
    if (startDate) searchQuery += `timerangeFrom=${startDate}&`;
    if (endDate) searchQuery += `timerangeTo=${endDate}`;

    return this.http.get<EmployeeResponseInterface>(environment.url + Routes.ASSIGNEES + searchQuery, {
      headers: this.headers,
    });
  }

  public getTeamAssigneeOptions(
    projectId?: number,
    startDate?: number,
    endDate?: number,
  ): Observable<TeamResponseInterface> {
    let searchQuery = '?';
    if (projectId) searchQuery += `projectId=${projectId}&`;
    if (startDate) searchQuery += `timerangeFrom=${startDate}&`;
    if (endDate) searchQuery += `timerangeTo=${endDate}`;

    return this.http.get<TeamResponseInterface>(environment.url + Routes.TEAM_UNITS + searchQuery, {
      headers: this.headers,
    });
  }

  public updateSubtasksStatus(task: TaskModel) {
    const statusesToChange = {
      subtaskIds: task.subtasks.filter((subtask) => subtask.status.statusGroup.id !== 5).map((subtask) => subtask.id),
      statusId: task.status.id,
    };

    return this.http.put<TasksResponseInterface>(
      environment.url + Routes.TASKS_SUBTASKS_STATUS_BATCH,
      statusesToChange,
      {
        headers: this.headers,
      },
    );
  }

  public sendEmailNotificationToAssignees(taskId: number, newAssignees: AssigneeInterface[]) {
    const assignees = {
      projectTaskId: taskId,
      employeeIds: newAssignees.map((a) => a.user.id),
    };

    return this.http.post<TasksResponseInterface>(environment.url + Routes.TASKS_EMAIL_NOTIFICATION, assignees, {
      headers: this.headers,
    });
  }
}
