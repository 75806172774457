import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { TroiDropdownListModel } from '../troi-dropdown-list/models/troi-dropdown-list.model';
import { DataTypeEnum } from '../../core/enums/data-type.enum';
import { DropdownsService } from '../../modules/common/services/dropdowns.service';
import { DropdownEventInterface } from './interfaces/dropdown-event.interface';
import { BackendResponseDropdownDataInterface } from '../../core/interfaces/backend.response.interface';
import { DropdownRoutesEnum } from '../../modules/common/enums/dropdown-routes.enum';
import { HttpHeaders } from '@angular/common/http';
import { TranslationMode } from '../../core/enums/translationMode';
import { FiltersParamsInterface } from '../../modules/common/interfaces/filters-params.interface';
import { StorageService } from '../../core/storage/storage.service';

@Component({
  selector: 'troi-dropdown-select-lazy',
  templateUrl: './troi-dropdown-select-lazy.component.html',
  styleUrls: ['./troi-dropdown-select-lazy.component.scss'],
})
export class TroiDropdownSelectLazyComponent implements OnInit, OnChanges {
  optionsLoading = false;

  totalOptions = 0;

  page = 1;

  totalPages = NaN;

  searchPhrase = '';

  options: Array<TroiDropdownListModel> = [];

  touched = false;

  @Input() pageSize = 10;

  @Input() dataType: DataTypeEnum;

  @Input() filterObject: number;

  @Input() secondFilterObject;

  @Input() thirdFilterObject;

  @Input() initValue;

  @Input() predefinedOptions: Array<TroiDropdownListModel> = [];

  @Input() refreshPreloadedOptionsOnChange = false;

  @Input() lang = 'de';

  @Input() defaultEmptyValue = null;

  @Input() year: number;

  @Input() withSearch: boolean;

  @Input() searchPrefix: string;

  @Input() searchInputType = 'text';

  @Input() searchRoute: string;

  @Input() searchParams: FiltersParamsInterface;

  @Input() searchHeaders: HttpHeaders;

  @Input() reloadInitData;

  @Input() forceReloadInitData;

  @Input() clearButton = true;

  @Input() translatableName = false;

  @Input() translationMode = TranslationMode.DATA;

  @Input() fieldInvalid = false;

  @Input() validationEnabled = false;

  @Input() multipleSelect = false;

  @Input() selectAllOption = false;

  @Input() selectAllOptionLabel = '';

  @Input() enabled = true;

  @Input() initLazyOptionOnInit = false;

  @Input() returnSelectedObject = false;

  @Input() disableIds = [];

  @Input() noBorder = false;

  @Input() placeholder = 'Booking.labels.pleaseSelect';

  @Input() validationMessage = 'Form.Error.Required';

  @Input() colorizeBackgroundProperty = '';

  @Input() width = '100%';

  @Output() selected = new EventEmitter<Record<string, unknown>>();

  @Output() optionsLoaded = new EventEmitter<Array<TroiDropdownListModel>>();

  constructor(private dropdownService: DropdownsService, private storageService: StorageService) {}

  ngOnInit(): void {
    this.options = this.predefinedOptions;
  }

  enableSearch(): boolean {
    return this.withSearch !== false;
  }

  loadData(concat = true) {
    this.touched = true;
    this.optionsLoading = true;
    if (this.dataType) {
      this.selectCorrectFetch(this.dataType).subscribe((result) => {
        this.totalPages = result.page.total_pages;
        const uniquePredefinedOptions = _.uniqBy(this.predefinedOptions, 'value');

        this.options =
          concat && this.options
            ? _.uniqBy(
                this.options.concat(
                  this.dropdownService.buildOptionList(
                    result.items,
                    this.dataType,
                    this.translatableName,
                    uniquePredefinedOptions,
                    this.colorizeBackgroundProperty,
                    this.translationMode,
                  ),
                ),
                'value',
              )
            : this.dropdownService.buildOptionList(
                result.items,
                this.dataType,
                this.translatableName,
                uniquePredefinedOptions,
                this.colorizeBackgroundProperty,
                this.translationMode,
              );

        this.options =
          this.dataType === DataTypeEnum.EMPLOYEES
            ? this.sortByLastName(this.options)
            : this.dataType === DataTypeEnum.TASK_STATES
            ? this.sortStatuses(this.options)
            : this.sortByLabel(this.options);
        this.totalOptions = result.page.total_elements;
        this.optionsLoading = false;
        this.optionsLoaded.emit(this.options);
        this.disableOptions(this.disableIds);
      });
    }
  }

  sortStatuses(options: Array<TroiDropdownListModel>) {
    return options.sort((a, b) => {
      if (a.value === 5) return 1;
      else if (b.value === 5) return -1;
    });
  }

  // splits the labes and sorts by last string (lastname)
  sortByLastName(options: Array<TroiDropdownListModel>): Array<TroiDropdownListModel> {
    const optionAll = options[0];
    const remainingOptions = options.slice(1);
    const sortedRemainingOptions = remainingOptions.sort((a, b) =>
      a.label.split(' ').pop().localeCompare(b.label.split(' ').pop()),
    );
    const sortedOptions = [optionAll, ...sortedRemainingOptions];
    return sortedOptions;
  }

  sortByLabel(options: Array<TroiDropdownListModel>): Array<TroiDropdownListModel> {
    const optionAll = options[0];
    const remainingOptions = options.slice(1);
    const sortedRemainingOptions = remainingOptions.sort((a, b) => a.label.localeCompare(b.label));
    const sortedOptions = [optionAll, ...sortedRemainingOptions];
    return sortedOptions;
  }

  selectCorrectFetch = (type: DataTypeEnum): Observable<BackendResponseDropdownDataInterface<any>> => {
    switch (type) {
      case DataTypeEnum.COST_CENTERS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.COST_CENTERS,
          'searchPhrase',
        );
      case DataTypeEnum.ACCOUNTS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.ACCOUNTS,
          'searchPhrase',
        );
      case DataTypeEnum.ACCOUNTS_CREATE_BOOKING:
        return this.dropdownService.fetchAccountsForCreateBooking(
          this.filterObject,
          this.secondFilterObject,
          this.thirdFilterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
        );
      case DataTypeEnum.SUPPLIER:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.SUPPLIERS,
        );
      case DataTypeEnum.ACCOUNT_GROUPS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.ACCOUNT_GROUPS,
          'description',
        );
      case DataTypeEnum.PROTOCOL_STATUS:
        return this.dropdownService.fetchProtocolStatus(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          this.year,
        );
      case DataTypeEnum.TAX_RATES:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.TAX_RATES,
        );
      case DataTypeEnum.UNITS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.UNITS,
        );
      case DataTypeEnum.CUSTOMERS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.CUSTOMERS,
        );
      case DataTypeEnum.PROJECT_TYPES:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.PROJECT_TYPES,
        );
      case DataTypeEnum.PROJECT_FOLDERS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          this.searchRoute,
          'name',
          'customer',
          this.searchHeaders,
        );
      case DataTypeEnum.PROJECTS_BY_CUSTOMER:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.PROJECTS_BY_CUSTOMER,
          'name',
          'customer',
        );
      case DataTypeEnum.PROJECTS_BY_CLIENT:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.PROJECTS_BY_CLIENT,
          'name',
          'client',
        );
      case DataTypeEnum.CP_BY_K_NUMBER:
        return this.dropdownService.fetchCPsByKNumber(
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          this.searchRoute,
          this.searchParams,
          this.searchHeaders,
        );
      case DataTypeEnum.SUB_PROJECTS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.SUB_PROJECTS,
          'name',
          'project',
        );
      case DataTypeEnum.EMPLOYEES:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.EMPLOYEES,
          'searchPhrase',
        );
      case DataTypeEnum.PROJECT_LEADERS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.PROJECT_LEADERS,
          'searchPhrase',
        );
      case DataTypeEnum.CPS:
        return this.dropdownService.fetchCPs(
          this.filterObject,
          this.secondFilterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
        );
      case DataTypeEnum.TASK_STATES:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          0,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.TASK_STATES,
        );
      case DataTypeEnum.TASK_TAGS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          0,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.TASK_TAGS,
        );
      case DataTypeEnum.TASK_PROJECTS:
        return this.dropdownService.fetchObjects(
          this.filterObject,
          this.page,
          this.pageSize,
          this.searchPhrase,
          this.lang,
          DropdownRoutesEnum.TASK_PROJECTS,
          'search',
        );
    }
  };

  eventTriggered(event: DropdownEventInterface) {
    const searchTriggered = this.searchPhrase !== event.searchPhrase;
    if (Number(this.page) >= Number(this.totalPages) && !searchTriggered) {
      return;
    }
    this.page = event.page;

    this.searchPhrase = event.searchPhrase;
    this.loadData(!searchTriggered);
  }

  refreshData() {
    this.options = this.predefinedOptions;
    this.page = 1;
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.reloadInitData &&
        !changes.reloadInitData.firstChange &&
        changes.reloadInitData.currentValue === true &&
        this.touched) ||
      changes.year ||
      changes.client ||
      changes.lang ||
      (changes.forceReloadInitData && !changes.forceReloadInitData.firstChange && this.touched)
    ) {
      this.refreshData();
    }

    if (changes.disableIds) {
      this.disableOptions(changes.disableIds.currentValue);
    }

    if (changes.predefinedOptions && this.refreshPreloadedOptionsOnChange) {
      this.options = _.uniqBy(this.options.concat(changes.predefinedOptions.currentValue), 'value');
    }
  }

  disableOptions(optionsToDisable: any[]) {
    if (optionsToDisable.length)
      _.forEach(this.options, (option: TroiDropdownListModel) => {
        option.disabled = optionsToDisable.includes(option.value);
      });
  }
}
