import { Injectable, OnDestroy } from '@angular/core';
import { ReminderInterface } from '../interfaces/reminder.interface';
import { Observable, Subscription } from 'rxjs';
import { ForecastResponseInterface, TeamResponseInterface } from '../interfaces/responses.interface';
import { ForecastViewEnum } from '../enum/forecast-view';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { environment } from '../../../../environments/environment';
import { Routes } from '../enum/routes';

@Injectable()
export class ResourcesService {
  private headers: HttpHeaders;

  public viewSelectionDropdown = [
    {
      rangeSelect: [
        {
          value: ForecastViewEnum.DAY,
          option: 'Tasks.labels.createTaskModal.resources.filter.dayView',
        },
        {
          value: ForecastViewEnum.WEEK,
          option: 'Tasks.labels.createTaskModal.resources.filter.weekView',
        },
        {
          value: ForecastViewEnum.MONTH,
          option: 'Tasks.labels.createTaskModal.resources.filter.monthView',
        },
      ],
    },
    {
      utilSelect: [
        {
          value: true,
          option: 'Tasks.labels.createTaskModal.resources.filter.utilPercent',
        },
        {
          value: false,
          option: 'Tasks.labels.createTaskModal.resources.filter.utilHours',
        },
      ],
    },
  ];

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  public getProjectGroups(): Observable<TeamResponseInterface> {
    return this.http.get<TeamResponseInterface>(environment.url + Routes.PROJECT_UNIT, {
      headers: this.headers,
    });
  }

  public getForecastValues(filterUrl: string): Observable<ForecastResponseInterface> {
    return this.http.get<ForecastResponseInterface>(environment.url + Routes.FORECAST + filterUrl, {
      headers: this.headers,
    });
  }

  public buildForecastUrl(
    timerangeFrom: number,
    timerangeTo: number,
    percent: boolean,
    view: ForecastViewEnum,
    projectId?: number,
    projectUnit?: number,
  ): string {
    let filterUrl = `?timerangeFrom=${timerangeFrom}&timerangeTo=${timerangeTo}&percent=${percent}&view=${view}`;

    if (projectId) filterUrl += `&projectId=${projectId}`;
    if (projectUnit > 0) filterUrl += `&projectunitId=${projectUnit}`;

    return filterUrl;
  }
}
