<div class="assigned-employee">
  <div class="avatar-container" (mouseleave)="showTrashIcon = false">
    <div class="avatar-container__image-and-name" (mouseenter)="showTrashIcon = true">
      <troi-task-modal-employee-avatar
        [employee]="assignee.user"
        [showProgressCircle]="showProgressCircle"
        [utilization]="assignee.utilization"
        [width]="showProgressCircle ? '75%' : '2rem'"
        [height]="showProgressCircle ? ' 75%' : '2rem'"
        [position]="showProgressCircle ? 'absolute' : 'relative'"
      ></troi-task-modal-employee-avatar>

      <div>
        {{ assignee.user.firstName }}
        {{ assignee.user.lastName }}
      </div>
    </div>
    <div
      [ngStyle]="{
        'padding-right': assignHours || isSubtask ? '1.4rem' : '0'
      }"
      class="avatar-container__icon-container"
    >
      <div *ngIf="!hideTimerange" class="avatar-container__icon-container--timerange">
        <span *ngIf="showTimerange && assignee.assignments.length === 0"> Zeitraum </span>
        <span *ngIf="assignee.assignments.length > 0"> {{ assignee.assignments.length }} x </span>
        <troi-icon
          (mouseenter)="showTimerange = true"
          (mouseleave)="showTimerange = false"
          [medium]="true"
          class="avatar-container__icon-container--icon"
          (click)="addTimerange()"
          icon="icon-list-points"
        ></troi-icon>
      </div>

      <troi-icon
        [medium]="true"
        class="avatar-container__icon-container--icon"
        (click)="removeAssigneeFromTask()"
        icon="icon-trash-can"
      ></troi-icon>

      <div class="assign-hours-input-container">
        <input
          *ngIf="assignHours || isSubtask"
          class="assign-hours-input-container__input"
          type="number"
          [(ngModel)]="assigneeHours"
          [disabled]="assignee.assignments.length > 0"
          (input)="calcTotalHoursOfTask()"
          (blur)="saveHoursAssignment($event.target.value)"
        />
        <span *ngIf="assignHours || isSubtask">h</span>
      </div>
    </div>
  </div>
  <div class="assignment" *ngFor="let assignment of assignee.assignments">
    <div (click)="helperService.getDatepickerPosition($event, true)" class="assignment__date-range">
      <troi-range-date
        [rangeFrom]="assignment.startDate ? assignment.startDate : dateNow"
        [rangeTo]="assignment.endDate ? assignment.endDate : dateNow"
        [minDate]="getMinDate()"
        [maxDate]="getMaxDate()"
        (rangeChanged)="updateAssignmentRange($event, assignment)"
        [settings]="{ format: settingsService.settings?.settings.dateFormat }"
        [placeholder]="'TT.MM.JJJJ'"
        [defaultAlignment]="false"
        [assignEmployeeAlignment]="true"
      ></troi-range-date>

      <div class="assign-hours-input-container">
        <input
          *ngIf="assignHours || isSubtask"
          class="assign-hours-input-container__input blue-background"
          type="number"
          [ngModel]="getAssignedHours(assignment)"
          (ngModelChange)="setAssignedHours($event, assignment)"
          (input)="calcTotalHoursOfAssignee()"
          (focusout)="updateAssignment(assignment)"
        />
        <span *ngIf="assignHours || isSubtask">h</span>
      </div>

      <troi-icon
        [small]="true"
        class="avatar-container__icon-container--icon"
        (click)="deleteAssignment(assignment)"
        icon="icon-close"
      ></troi-icon>
    </div>
  </div>
  <p
    (click)="addTimerange()"
    *ngIf="assignee.assignments.length > 0"
    class="assignment__more-time-ranges"
  >
    + weitere Zeiträume
  </p>
</div>
