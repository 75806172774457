import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import * as _ from 'lodash';
import { TroiDropDownCloseService } from '../../troi-dropdown-list/services/troi-dropdown-close.service';
import { FilterElementInterface } from '../filter-element.interface';
import { FilterTypeEnum } from '../filter-type.enum';
import { CurrencyMoneyInterface } from '../../troi-data-listing-filters/filters.interface';
import { CommonFiltersService } from '../../../modules/common/services/common-filters.service';

@Component({
  selector: 'troi-filter-modal',
  templateUrl: './troi-filter-modal.component.html',
  styleUrls: ['./troi-filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TroiFilterModalComponent implements OnInit, OnDestroy, OnChanges {
  private element: ElementRef;
  public openState = false;
  public _filters: FilterElementInterface[] = [];

  @Input() public forceOpen = false;
  @Input() filterData: FilterElementInterface[];
  @Input() currencyMoneyData: CurrencyMoneyInterface;
  @Input() year: number;
  @Input() limitDate = true;
  @Input() dateFormat = 'DD.MM.YYYY';
  @Input() lang: string;
  @Input() client: number;
  @Input() newDesign: boolean;

  @Output() openChange = new EventEmitter<boolean>();
  @Output() filtersReset = new EventEmitter<boolean>();
  @Output() filtersApplied = new EventEmitter<FilterElementInterface[]>();

  constructor(element: ElementRef, private troiDropDownCloseService: TroiDropDownCloseService) {
    this.element = element;
    this.troiDropDownCloseService.getEmitter().subscribe((event) => {
      const clickedElement = _.find(
        event.path || event.composedPath(),
        (path) => path === this.element.nativeElement || path === this.element.nativeElement.parentElement,
      );
      if (!clickedElement) {
        this.hideModal();
      }
      event.stopPropagation();
    });
  }

  ngOnInit() {
    this._filters = _.cloneDeep(this.enabledFilters());
  }

  hideModal() {
    this.openState = false;
    this.openChange.emit(this.openState);
  }

  ngOnDestroy(): void {
    this._filters = _.cloneDeep(this.enabledFilters());
  }

  ngOnChanges(changes) {
    if (changes.forceOpen) {
      this.openState = this.forceOpen;
    }
  }

  public enabledFilters(
    filterBy: (filter: FilterElementInterface) => boolean = CommonFiltersService.isFilterActive,
    filterData?: FilterElementInterface[],
  ): FilterElementInterface[] {
    const enabledParents = filterData ? filterData.filter(filterBy) : this.filterData.filter(filterBy);

    return enabledParents.map((filterElem: FilterElementInterface) => {
      return filterElem.type === FilterTypeEnum.GROUP
        ? {
            ...filterElem,
            value: this.enabledFilters(filterBy, filterElem.value),
          }
        : filterElem;
    });
  }

  public emitFiltersApplied(): void {
    this.filtersApplied.emit([...this.enabledFilters(CommonFiltersService.isFilterExplicit), ...this._filters]);

    this.hideModal();
  }

  emitFiltersReset() {
    this.filtersReset.emit(true);
    this.hideModal();
  }

  public updateDropdownData(item: FilterElementInterface, data: any): void {
    item.dropdownData = data;
  }

  public toggleItem(item: FilterElementInterface): void {
    item.value = !item.value;
  }

  isSwitch = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.SWITCH === type;
  };

  isInput = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.INPUT === type;
  };

  isRange = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.RANGE === type;
  };

  isDropdown = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.DROPDOWN === type;
  };

  isRangeDate = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.RANGE_DATE === type;
  };

  isRangeString = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.RANGE_STRING === type;
  };

  isDropdownLazy = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.DROPDOWN_LAZY === type;
  };

  isCheckbox = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.CHECKBOX === type;
  };

  isGroup = (type: FilterTypeEnum): boolean => {
    return FilterTypeEnum.GROUP === type;
  };
}
