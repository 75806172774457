import { HttpClient } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { tap, map } from 'rxjs/operators';
import { timePresentation } from '../../shared/time-switch/timePresentation';
import ErrorHandlerService from '../../shared/error-handling/ErrorHandlerService';
import { DeskSettingsService } from '../../services/desk-settings.service';
import { TaskModel } from '../../../tasks/models/task.model';
import { TranslateService } from '@ngx-translate/core';
import { SubtaskInterface } from '../../../tasks/interfaces/subtask.interface';

@Injectable()
export class StopwatchService {
  private readonly apiEndpoint: string = '/components/desktop/stopwatch';
  private readonly apiEndpointStart: string = '/components/desktop/stopwatch/start';
  private readonly apiEndpointStop: string = '/components/desktop/stopwatch/stop';
  private readonly apiEndpointPause: string = '/components/desktop/stopwatch/pause';

  private httpOptions = {};

  public constructor(
    private http: HttpClient,
    private errorHandler: ErrorHandlerService,
    private translate: TranslateService,
    private deskSettingsService: DeskSettingsService,
  ) {}

  private getDateParam(date: any) {
    if (date) {
      const param: string = date.format('YYYY-MM-DD');
      return `?date=${param}`;
    }
    return '';
  }

  listStopwatches(date?: any): Observable<any> {
    return this.http.get(this.apiEndpoint + this.getDateParam(date)).pipe(
      tap(
        (response: any) => {
          const entries: Array<any> = [];

          response.forEach((entry: any) => {
            entry.metaStatus = 'new';
            if (entry.running) {
              entry.metaStatus = 'running';
            }
            if (entry.paused) {
              entry.metaStatus = 'paused';
            }
            if (entry.done) {
              entry.metaStatus = 'stopped';
            }

            entry.metaElapsedTime = entry.elapsedTime;
            entry.metaPresentation = timePresentation(entry.elapsedTime);

            entries.push(entry);
          });
          const event = new CustomEvent('app:stopwatch:list', { detail: { entries } });
          window.top?.document.dispatchEvent(event);
          return entries;
        },
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }

  createStopwatch(form: any): Observable<any> {
    const value = { ...form };
    delete value['assignProject'];
    return this.http.post(this.apiEndpoint, value, this.httpOptions).pipe(
      tap(
        (response) => {
          const event = new CustomEvent('app:stopwatch:created', {});
          window.top?.document.dispatchEvent(event);
          return response;
        },
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }

  preparePayloadFromSubTask(task: SubtaskInterface) {
    const { id: projectSubTaskId, title: name, calculationPosition } = task;
    const date = new Date().toISOString()?.split('T')?.[0];
    const calculationPositionId = calculationPosition?.id;
    return {
      calculationPositionId,
      date,
      name,
      projectSubTaskId,
    };
  }

  preparePayloadFromTask(task: TaskModel) {
    const { id: projectTaskId, title: name, calculationPosition } = task;
    const date = new Date().toISOString()?.split('T')?.[0];
    const calculationPositionId = calculationPosition?.id;
    return {
      calculationPositionId,
      date,
      name,
      projectTaskId,
    };
  }

  createAndStart(form: any): Observable<any> {
    const value = { ...form };
    delete value['assignProject'];
    return this.http.post(this.apiEndpoint, value, this.httpOptions).pipe(
      tap(
        (response) => {
          this.startStopwatch(response.id).subscribe(() => {
            const el = window.top.document.getElementsByTagName('twc-stopwatches')[0];
            // @ts-ignore
            el.inputActive = true;
            this.updateI18nStopwatches(el);
            this.updateStopwatchSettings(el);
          });
        },
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }

  updateStopwatchSettings(el: any) {
    if (!this.deskSettingsService?.settings?.settings) {
      this.deskSettingsService.getSettings().subscribe();
    }
    el.settings = {
      isTimeRecordingExternalCommentRequired:
        this.deskSettingsService.settings.settings.isTimeRecordingExternalCommentRequired,
      isTimeRecordingInternalCommentRequired:
        this.deskSettingsService.settings.settings.isTimeRecordingInternalCommentRequired,
    };
  }

  updateI18nStopwatches(element: any) {
    element.i18n = {
      stopwatches: this.translate.instant('Stopwatches.stopwatches'),
      internalComment: this.translate.instant('Stopwatches.internalComment'),
      externalComment: this.translate.instant('Stopwatches.externalComment'),
      start: this.translate.instant('Stopwatches.start'),
      stop: this.translate.instant('Stopwatches.stop'),
      'Desktop.Widgets.Stopwatch.New.Title': this.translate.instant('Desktop.Widgets.Stopwatch.New.Title'),
      'Booking.error.receiptDateIsRequired': this.translate.instant('Booking.error.receiptDateIsRequired'),
      'Desktop.Widgets.Stopwatch.New.Name': this.translate.instant('Desktop.Widgets.Stopwatch.New.Name'),
      'Desktop.Widgets.Stopwatch.New.InternalComment': this.translate.instant(
        'Desktop.Widgets.Stopwatch.New.InternalComment',
      ),
      'Desktop.Widgets.Stopwatch.New.ExternalComment': this.translate.instant(
        'Desktop.Widgets.Stopwatch.New.ExternalComment',
      ),
      'Desktop.Widgets.Stopwatch.New.OnwardCalculation': this.translate.instant(
        'Desktop.Widgets.Stopwatch.New.OnwardCalculation',
      ),
      'Desktop.Widgets.Stopwatch.New.AssignProject': this.translate.instant(
        'Desktop.Widgets.Stopwatch.New.AssignProject',
      ),
      'Desktop.Widgets.Stopwatch.New.Reset': this.translate.instant('Desktop.Widgets.Stopwatch.New.Reset'),
      'Desktop.Widgets.Stopwatch.New.Create': this.translate.instant('Desktop.Widgets.Stopwatch.New.Create'),
      'Desktop.Widgets.Projecttimes.Title': this.translate.instant('Desktop.Widgets.Projecttimes.Title'),
      'Desktop.Widgets.Projecttimes.SearchCalcPosition.Client': this.translate.instant(
        'Desktop.Widgets.Projecttimes.SearchCalcPosition.Client',
      ),
      'Desktop.Widgets.Projecttimes.SearchCalcPosition.Customer': this.translate.instant(
        'Desktop.Widgets.Projecttimes.SearchCalcPosition.Customer',
      ),
      'Desktop.Widgets.Projecttimes.SearchCalcPosition.Project': this.translate.instant(
        'Desktop.Widgets.Projecttimes.SearchCalcPosition.Project',
      ),
      'Desktop.Widgets.Projecttimes.SearchCalcPosition.Title': this.translate.instant(
        'Desktop.Widgets.Projecttimes.SearchCalcPosition.Title',
      ),
      'Desktop.Widgets.Projecttimes.SearchCalcPosition.SearchButton': this.translate.instant(
        'Desktop.Widgets.Projecttimes.SearchCalcPosition.SearchButton',
      ),
      'Desktop.Widgets.Projecttimes.Date': this.translate.instant('Desktop.Widgets.Projecttimes.Date'),
      'Desktop.Widgets.Projecttimes.ProjectTime': this.translate.instant('Desktop.Widgets.Projecttimes.ProjectTime'),
      'Desktop.Widgets.Projecttimes.InternalComment': this.translate.instant(
        'Desktop.Widgets.Projecttimes.InternalComment',
      ),
      'Desktop.Widgets.Projecttimes.ExternalComment': this.translate.instant(
        'Desktop.Widgets.Projecttimes.ExternalComment',
      ),
      'Desktop.Widgets.Projecttimes.OnwardCalculation': this.translate.instant(
        'Desktop.Widgets.Projecttimes.OnwardCalculation',
      ),
      'Desktop.Widgets.Projecttimes.Cancel': this.translate.instant('Desktop.Widgets.Projecttimes.Cancel'),
      'Desktop.Widgets.Projecttimes.Record': this.translate.instant('Desktop.Widgets.Projecttimes.Record'),
      'Desktop.Widgets.Projecttimes.Error.NoCalculationPositionSelected': this.translate.instant(
        'Desktop.Widgets.Projecttimes.Error.NoCalculationPositionSelected',
      ),
      'Desktop.Widgets.Projecttimes.Error.RequiredFields': this.translate.instant(
        'Desktop.Widgets.Projecttimes.Error.RequiredFields',
      ),
      'Desktop.General.Messages.noResults': this.translate.instant('Desktop.General.Messages.noResults'),
    };
  }

  deleteStopwatch(id: string): Observable<any> {
    return this.http.delete(this.apiEndpoint + '?id=' + id).pipe(
      tap(
        (response) => {
          const event = new CustomEvent('app:stopwatch:deleted', { detail: id });
          window.top?.document.dispatchEvent(event);
          return response;
        },
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }

  startStopwatch(id: string): Observable<any> {
    return this.http.post(this.apiEndpointStart + '?id=' + id, [], this.httpOptions).pipe(
      tap(
        (response) => {
          const event = new CustomEvent('app:stopwatch:start', { detail: id });
          window.top?.document.dispatchEvent(event);
          return response;
        },
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }

  pauseStopwatch(id: string): Observable<any> {
    return this.http.post(this.apiEndpointPause + '?id=' + id, [], this.httpOptions).pipe(
      tap(
        (response) => {
          const event = new CustomEvent('app:stopwatch:pause', { detail: id });
          window.top?.document.dispatchEvent(event);
          return response;
        },
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }

  stopStopwatch(id: string): Observable<any> {
    return this.http.post(this.apiEndpointStop + '?id=' + id, [], this.httpOptions).pipe(
      tap(
        (response) => {
          const event = new CustomEvent('app:stopwatch:stop', { detail: id });
          window.top?.document.dispatchEvent(event);
          return response;
        },
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }
  updateStopwatch(id: string, payload: any): Observable<any> {
    return this.http.put(this.apiEndpoint + '/' + id, payload, this.httpOptions).pipe(
      tap(
        (response) => {
          const event = new CustomEvent('app:stopwatch:update', { detail: id });
          window.top?.document.dispatchEvent(event);
          return response;
        },
        (error) => {
          this.errorHandler.error(error);
        },
      ),
    );
  }
}
