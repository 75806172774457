import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { LanguageTranslationsBackend } from '../interfaces/languageTranslations.backend';
import { StorageService } from '../storage/storage.service';
import { environment } from '../../../environments/environment';
import { StorageKeys } from '../storage/storage.keys';
import { StorageListener } from '../storage/storage.listener';
import { TranslationMode } from '../enums/translationMode';

@Injectable()
export class LanguagesService {
  constructor(public storageService: StorageService, private strageListener: StorageListener) {}

  public updateLanguageValue(
    newValue: string,
    translatedData: LanguageTranslationsBackend,
  ): LanguageTranslationsBackend {
    translatedData[this.getDataLanguage()] = newValue;

    return translatedData;
  }

  public updateAllLanguagesWithValue(
    value: string,
    translatedData: LanguageTranslationsBackend,
  ): LanguageTranslationsBackend {
    _.forEach(Object.keys(translatedData), (language) => {
      translatedData[language] = value;
    });

    return translatedData;
  }

  public getLanguageValue(translatedData: LanguageTranslationsBackend, translationMode = TranslationMode.DATA): string {
    if (translationMode === TranslationMode.DATA) {
      return translatedData[this.getDataLanguage()];
    }

    if (translationMode === TranslationMode.NAVIGATION) {
      return translatedData[this.getLanguage()];
    }

    throw new Error('Unsupported translation mode');
  }

  public getNavigationLanguageValue(translatedData: LanguageTranslationsBackend): string {
    return translatedData[this.getLanguage()];
  }

  public getDataLanguage(): string {
    const lang = this.storageService.getItem(StorageKeys.DATA_LANG);
    return lang || environment.defaultLanguage;
  }

  public getLanguage(): string {
    const lang = this.storageService.getItem(StorageKeys.LANG);
    return lang || environment.defaultLanguage;
  }

  public setDataLanguage(value: string) {
    this.strageListener.store(StorageKeys.DATA_LANG, value);
  }

  public setLanguage(value: string) {
    this.strageListener.store(StorageKeys.LANG, value);
  }

  public getSystemLanguages() {
    return environment.languages;
  }
}
