import { Component, EventEmitter, Output, Input, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import { debounce } from 'lodash';

@Component({
  selector: 'tnd-select-box',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit, OnChanges {
  searchString = '';

  resultSelected = false;

  open = false;

  modifiedSearchString = false;

  @Output() valueSelected = new EventEmitter<any>();

  @Input() options: Array<any> = [];

  @Input() required = false;

  @Input() placeholder = 'Please choose an option';

  @Input() icon = '';

  @Input() initialId: string | null = null;

  @Input() forceDefault = false;

  @Input() valuefieldName = 'id';

  constructor() {
    this.onBlur = debounce(this.onBlur, 250);
  }

  ngOnInit() {
    if (null !== this.initialId) {
      const option: any = this.options.find((opt: any) => opt.id === this.initialId);

      if (option) {
        this.setValue(option);
        return;
      }
    }

    if (this.forceDefault && this.options.length) {
      const option: any = this.options[0];

      if (option) {
        this.setValue(option);
        return;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initialId) {
      const option: any = this.options.find((opt: any) => opt[this.valuefieldName] === this.initialId);

      if (option) {
        this.setValue(option);
        return;
      }
    }
  }

  onSearchStringChange() {
    this.modifiedSearchString = true;
  }

  toggle(): void {
    this.open = !this.open;
  }

  setValue(option: any) {
    this.valueSelected.emit(option);
    this.searchString = option.label;
    this.resultSelected = true;
    this.open = false;
  }

  close() {
    this.open = false;
  }

  onBlur() {
    this.open = false;
  }

  get filteredOptions(): any[] {
    if (!this.modifiedSearchString) {
      return this.options;
    }
    return this.options.filter((option) => option.label.toLowerCase().includes(this.searchString.toLowerCase()));
  }
  reset() {
    this.searchString = '';
    this.valueSelected.emit();
  }
}
