<div class="input-text" [ngClass]="{ error: !isValid() }">
  <label *ngIf="label">{{ label }}</label>
  <input
    class="input"
    [ngStyle]="{ 'font-size': size }"
    type="{{ type }}"
    [(ngModel)]="value"
    [readOnly]="readOnly"
    [ngClass]="{ 'has-value': value }"
  />
  <span class="bar"></span>
  <div *ngIf="!isValid() && !hideError">
    <div *ngFor="let error of getErrorMessage()">
      <div>{{ error }}</div>
    </div>
  </div>
</div>
