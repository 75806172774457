<div class="footer" [ngClass]="{ 'footer--hidden': hidden }">
  <div class="footer__toggle" (click)="toggleFooter()">
    <troi-icon [icon]="'icon-arrow-head-up'"></troi-icon>
  </div>
  <div class="footer__wrapper">
    <form class="footer__left" [formGroup]="footerForm">
      <div
        class="footer__color-switcher legacy-icon legacy-icon--icon-switch"
        (click)="changeColor()"
      ></div>
      <troi-dropdown-select
        class="footer__item"
        size="11px"
        *ngIf="settings"
        [fullWidth]="true"
        [top]="true"
        [noMinWith]="true"
        (selected)="changeCurrency($event)"
        [options]="currencyList"
        [disable]="disabledCurrency"
        formControlName="currency"
      >
      </troi-dropdown-select>
      <troi-dropdown-select
        class="footer__item"
        size="11px"
        [fullWidth]="true"
        [top]="true"
        (selected)="selectedLanguage($event)"
        [options]="languageList"
        formControlName="language"
      >
      </troi-dropdown-select>
      <troi-dropdown-select
        class="footer__item"
        size="11px"
        [fullWidth]="true"
        [top]="true"
        (selected)="selectedDataLanguage($event)"
        [options]="dataLanguageList"
        formControlName="dataLanguage"
      >
      </troi-dropdown-select>
    </form>
    <div class="footer__right">
      <div class="footer__sq-btn-wrapper" *ngIf="settingsEnabled">
        <div class="footer__sq-btn" (click)="openSettings()">
          <troi-icon icon="icon-setting" size="10px"></troi-icon>
        </div>
      </div>
      <div class="footer__sq-btn-wrapper" *ngIf="troiHelpButtonUrl">
        <a class="footer__sq-btn" [href]="troiHelpButtonUrl" target="_blank">
          <troi-icon icon="icon-question-mark" size="10px"></troi-icon>
        </a>
      </div>
      <div
        class="footer__button"
        *ngIf="footerService.timetracker && footerService.timetracker?.isAddWorkingTimeButtonEnabled"
        (click)="addWorkingTime()"
      >
        {{ 'Footer.AddWorkingTime' | translate }}
      </div>
      <div
        class="footer__button"
        *ngIf="
          footerService.timetracker &&
          footerService.timetracker.isActiveTimeTracking &&
          !footerService.timetracker.hasActiveBreak
        "
        (click)="startBreak()"
      >
        {{ 'Footer.StartBreak' | translate }}
      </div>
      <div
        class="footer__button footer__button--active"
        *ngIf="
          footerService.timetracker &&
          footerService.timetracker.isActiveTimeTracking &&
          footerService.timetracker.hasActiveBreak
        "
        (click)="stopBreak()"
      >
        {{ 'Footer.StopBreak' | translate }}
      </div>
      <div
        class="footer__button"
        *ngIf="footerService.timetracker && footerService.timetracker?.isEnabled && !footerService.timetracker.isActiveTimeTracking"
        (click)="checkIn()"
      >
        <span class="footer__highlight">{{ 'Footer.CheckIn' | translate }}</span>
      </div>
      <div
        class="footer__button footer__button--highlight"
        *ngIf="footerService.timetracker && footerService.timetracker?.isEnabled && footerService.timetracker.isActiveTimeTracking"
        (click)="checkOut()"
      >
        <span class="footer__highlight">{{ 'Footer.CheckOut' | translate }}</span>
      </div>
      <div class="footer__button footer__button--with-space" (click)="logout()">
        {{ 'Footer.Logout' | translate }}
      </div>
    </div>
  </div>
</div>
