<div class="avatar-image">
  <div
    [ngStyle]="{
      width: width,
      height: height,
      position: position
    }"
    [ngClass]="{ 'avatar-background': !employeeHasImage() }"
    (mouseenter)="showAddEmployeePopover = true"
    (mouseleave)="showAddEmployeePopover = false"
  >
    <div *ngIf="!employeeHasImage()" class="avatar-initials">
      {{ getInitials() }}
    </div>
    <img
      class="avatar-image-no-progress"
      *ngIf="employeeHasImage() && !showProgressCircle"
      src="{{ employee.image }}"
      alt=""
    />
    <div
      *ngIf="addEmployee && showAddEmployeePopover && !alreadyAssigned"
      class="add-employee"
      (click)="onSelectPersonClick(person)"
    >
      <troi-icon [large]="true" size="12px" [icon]="'icon-plus'"></troi-icon>
    </div>
  </div>
  <circle-progress
    class="progress-circle"
    *ngIf="showProgressCircle"
    [imageSrc]="employee.image"
    [maxPercent]="200"
    [outerStrokeWidth]="2"
    [outerStrokeColor]="outerColor"
    [innerStrokeColor]="innerColor"
    [innerStrokeWidth]="2"
    [animationDuration]="animationDuration"
    [showUnits]="false"
    [showBackground]="true"
    [showTitle]="false"
    [showSubtitle]="false"
    [showImage]="employeeHasImage()"
    [imageHeight]="34"
    [imageWidth]="34"
    [percent]="alreadyAssigned ? 100 : utilization"
    [radius]="20"
    [backgroundOpacity]="0"
    [backgroundPadding]="0"
    [space]="-2"
    [toFixed]="0"
  ></circle-progress>
  <div *ngIf="alreadyAssigned" class="avatar-image__assigned">
    <troi-icon icon="icon-circle-with-checkmark"></troi-icon>
  </div>
</div>
