import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressCircleComponent } from './components/progress-circle/progress-circle.component';
import { ConsumptionPopoverComponent } from './components/consumption-popover/consumption-popover.component';
import { ConsumptionPopoverService } from './network/consumption-popover.service';
import { SharedModule } from '../../shared/shared.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { EmployeeAvatarComponent } from './components/modals/create-task-modal/components/employee-avatar/employee-avatar.component';
import { SelectAssigneeComponent } from './components/modals/create-task-modal/components/resource-planning/components/select-assignee/select-assignee.component';
import { SelectAssigneeDropdownComponent } from './components/modals/create-task-modal/components/resource-planning/components/select-assignee-dropdown/select-assignee-dropdown.component';
import { AssignedEmployeeComponent } from './components/modals/create-task-modal/components/resource-planning/components/assigned-employee/assigned-employee.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImportCalcPosComponent } from './components/import-calc-pos/import-calc-pos.component';
import { ImportCalcPosModalComponent } from './components/modals/import-calc-pos-modal/import-calc-pos-modal.component';
import { SubprojectComponent } from './components/modals/import-calc-pos-modal/components/subproject.component';
import { ResourcesContentComponent } from './components/modals/create-task-modal/modules/resources-content/resources-content.component';

@NgModule({
  declarations: [
    ConsumptionPopoverComponent,
    ProgressCircleComponent,
    EmployeeAvatarComponent,
    SelectAssigneeComponent,
    SelectAssigneeDropdownComponent,
    ImportCalcPosComponent,
    ImportCalcPosModalComponent,
    SubprojectComponent,
    AssignedEmployeeComponent,
    ResourcesContentComponent,
  ],
  imports: [CommonModule, SharedModule, NgCircleProgressModule.forRoot(), NgbModule],
  providers: [ConsumptionPopoverService],
  exports: [
    ConsumptionPopoverComponent,
    ProgressCircleComponent,
    ResourcesContentComponent,
    EmployeeAvatarComponent,
    SelectAssigneeComponent,
    SelectAssigneeDropdownComponent,
    AssignedEmployeeComponent,
    ImportCalcPosComponent,
    ImportCalcPosModalComponent,
    SubprojectComponent,
    NgbModule,
  ],
})
export class SharedTaskModule {}
