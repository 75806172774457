import { FooterSettingsInterface } from '../../../core/interfaces/footer-settings.interface';
import { Currency } from '../../../shared/troi-money/currency';
import { SettingsInterface } from '../interfaces/settings.interface';

export class SettingsModel implements FooterSettingsInterface {
  private _availableLanguagesForNavigator: Array<string>;
  private _availableLanguagesForData: Array<string>;

  constructor(
    public client: number,
    public systemCurrency: Currency,
    public activeCurrency: Currency,
    public currencies: Currency[],
    public settings: SettingsInterface,
  ) {
    this.availableLanguagesForNavigator = settings.availableLanguagesForNavigator;
    this.availableLanguagesForData = settings.availableLanguagesForData;
  }

  get availableLanguagesForNavigator(): Array<string> {
    return this._availableLanguagesForNavigator;
  }

  set availableLanguagesForNavigator(value: Array<string>) {
    this._availableLanguagesForNavigator = value;
  }

  get availableLanguagesForData(): Array<string> {
    return this._availableLanguagesForData;
  }

  set availableLanguagesForData(value: Array<string>) {
    this._availableLanguagesForData = value;
  }
}
