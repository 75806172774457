import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { FiltersInterface } from '../../../shared/troi-data-listing-filters/filters.interface';
import { FilterElementInterface } from '../../../shared/troi-filter-with-modal/filter-element.interface';
import { Money } from '../../../shared/troi-money/money';
import { FilterTypeEnum } from '../../../shared/troi-filter-with-modal/filter-type.enum';

enum RangeSideEnum {
  FROM = 'From',
  TO = 'To',
}

@Injectable()
export class CommonFiltersFormService {
  private static rangeFilterName(element: FilterElementInterface, rangeSide: RangeSideEnum): string {
    const baseNameParts = [element.formName, rangeSide];
    const nameParts = element.formNamePrefix ? [element.formNamePrefix, ...baseNameParts] : baseNameParts;

    return element.formNameSeparator ? nameParts.join(element.formNameSeparator).toLowerCase() : nameParts.join('');
  }

  dynamicFiltersData(filters: FilterElementInterface[]) {
    let data = {};

    _.forEach(filters, (element: FilterElementInterface) => {
      if (!_.isUndefined(element.value)) {
        if (element.value instanceof Array) {
          if (element.type === FilterTypeEnum.RANGE) {
            data[CommonFiltersFormService.rangeFilterName(element, RangeSideEnum.FROM)] = this.prepareMoneyNullField(
              element.value[0],
            );
            data[CommonFiltersFormService.rangeFilterName(element, RangeSideEnum.TO)] = this.prepareMoneyNullField(
              element.value[1],
            );
          } else if (element.type === FilterTypeEnum.RANGE_DATE || element.type === FilterTypeEnum.RANGE_STRING) {
            data[CommonFiltersFormService.rangeFilterName(element, RangeSideEnum.FROM)] = !_.isNull(element.value[0])
              ? element.value[0]
              : '';
            data[CommonFiltersFormService.rangeFilterName(element, RangeSideEnum.TO)] = !_.isNull(element.value[1])
              ? element.value[1]
              : '';
          } else if (element.type === FilterTypeEnum.GROUP) {
            data = { ...data, ...this.dynamicFiltersData(element.value) };
          } else if (element.value[0] instanceof Money) {
            const arrayToFormat = [];
            _.forEach(element.value, (value) => {
              arrayToFormat.push(_.isNull(value) ? null : value.formattedValue);
            });
            data[element.formName] = arrayToFormat.join(';');
          } else {
            data[element.formName] = element.value.join(';');
          }
        } else {
          data[element.formName] = element.value;
        }
      } else {
        data[element.formName] = '';
      }
    });

    return data;
  }

  prepareMoneyNullField(value) {
    if (_.isNull(value)) {
      return '';
    }

    return value instanceof Money ? value.value : value;
  }

  commonFiltersData(filters: FiltersInterface) {
    return {
      searchPhrase: filters.search ?? '',
      client: filters.dropdownFirst,
      currentPage: filters.currentPage,
      pageSize: filters.pageSize,
      sortDir: filters.sortingDir,
      sortBy: filters.sortBy,
    };
  }

  prepareSearchRequestParams(filters: FiltersInterface) {
    return { ...this.commonFiltersData(filters), ...this.dynamicFiltersData(filters.filters) };
  }
}
