import { Component, Input } from '@angular/core';
import { CalcPositionService } from '../../../../../../network/calc-pos.service';
import { TaskInterface } from '../../../../../../interfaces/task.interface';
import { CalculationPositionInterface } from '../../../../../../../tasks/interfaces/calculationPosition.interface';

@Component({
  selector: 'troi-task-calc-pos',
  templateUrl: './calc-pos.component.html',
  styleUrls: ['./calc-pos.component.scss'],
})
export class CalculationPositionComponent {
  @Input() calcPosition: CalculationPositionInterface;
  @Input() selectedCalcualtionPosition: CalculationPositionInterface;
  @Input() task: TaskInterface;

  constructor(public calcPositionService: CalcPositionService) {}

  public onSelectPositionClick(calcPosition: CalculationPositionInterface): void {
    this.selectedCalcualtionPosition.serviceHeadline = calcPosition.serviceHeadline;
    this.selectedCalcualtionPosition.serviceName = calcPosition.serviceName;
    this.selectedCalcualtionPosition.id = calcPosition.id;
    this.selectedCalcualtionPosition.quantity = calcPosition.quantity;
    this.selectedCalcualtionPosition.project = calcPosition.project;

    this.calcPositionService.setSelectedCalcPosition(this.selectedCalcualtionPosition);
  }
}
