<form class="timeentry" (ngSubmit)="saveEntry()" [formGroup]="timeEntriesForm">
  <span *ngIf="!subtask" class="timeentry__subtitle">{{
    'Tasks.labels.createTaskModal.timeEntry.timeTracking' | translate
  }}</span>

  <div class="mb">
    <div class="timeentry__selection">
      <troi-person
        (click)="toggleDropdown()"
        [person]="convertAssigneeToPerson(selectedAssignee)"
        [large]="true"
      ></troi-person>

      <div class="flex-1 timeentry__datepicker">
        <tnd-datepicker
          (click)="helperService.getDatepickerPosition($event)"
          [fieldInvalid]="false"
          [validationEnabled]="true"
          [rangeFrom]="dateNow"
          [rangeTo]="dateNow"
          [minDate]="getMinDate()"
          [singleDatePicker]="true"
          [emitIfEmpty]="true"
          [disabled]="false"
          (rangeChanged)="dateChanged($event)"
          [requiredFieldErrorMessage]="'Booking.error.receiptDateIsRequired'"
          [hideRanges]="true"
          ngClass="tnd-create-event-datepicker"
          [paddingLeft]="'40px'"
          [disabled]="disabled"
        ></tnd-datepicker>
      </div>

      <div
        [ngStyle]="{ 'pointer-events': disabled ? 'none' : 'auto' }"
        class="tnd-text-input input-time flex-1 timeentry__clock"
      >
        <troi-icon [icon]="'icon-clock-open'"></troi-icon>

        <input
          onfocus="showPicker()"
          id="time-picker"
          #timePicker
          type="time"
          formControlName="time"
          ngbPopover="{{ 'Tasks.labels.createTaskModal.addTimeEntry' | translate }}"
          placement="bottom"
          #addTimePopover="ngbPopover"
          popoverClass="timeentries-tooltip-popover"
          triggers="manual"
        />
      </div>

      <div *ngIf="!singleComment && timeEntrySettings.internalCommentsVisible" class="flex-2">
        <troi-input-field
          type="text"
          class="comment"
          [placeholder]="'Interner Kommentar'"
          formControlName="internalComment"
          [ngbPopover]="'Internal comment required'"
          placement="bottom"
          #internalComment="ngbPopover"
          popoverClass="timeentries-tooltip-popover"
          triggers="manual"
        ></troi-input-field>
      </div>

      <div *ngIf="!singleComment" class="flex-2">
        <troi-input-field
          type="text"
          class="comment"
          [placeholder]="'Externer Kommentar'"
          formControlName="externalComment"
          [ngbPopover]="'External comment required'"
          placement="bottom"
          #externalComment="ngbPopover"
          popoverClass="timeentries-tooltip-popover"
          triggers="manual"
        ></troi-input-field>
      </div>

      <troi-input-field
        *ngIf="singleComment"
        [icon]="'icon-can-edit'"
        [iconSize]="'15px'"
        focus-directive
        cssSelector=".troi-input-field__input"
        [placeholder]="'Kommentar hinzufügen'"
        [fieldInvalid]="false"
        [disabled]="disabled"
        formControlName="internalComment"
        [ngbPopover]="'Add comment first'"
        placement="bottom"
        #externalComment="ngbPopover"
        popoverClass="timeentries-tooltip-popover"
        triggers="manual"
      ></troi-input-field>

      <troi-icon
        (click)="saveEntry()"
        class="timeentry__selection--icon-plus"
        [icon]="'icon-plus'"
      ></troi-icon>
    </div>
  </div>
  <div
    *ngIf="
      this.task.assignees && this.task.assignees.length > 0 && timeEntrySettings.timeRecordForOthers
    "
    class="timeentry__dropdown-container"
  >
    <troi-task-modal-select-assignee-dropdown
      [assignees]="task.assignees"
      [showDropdown]="showDropdown"
      [taskHasDate]="task.startDate && task.endDate"
      [showUtilization]="false"
      (assignedEmployee)="assignEmployee($event)"
    ></troi-task-modal-select-assignee-dropdown>
  </div>
  <div
    *ngIf="timeEntries.length > 0"
    class="timeentry__subtitle"
    [ngClass]="{ 'mr-45': !timeEntrySettings.internalCommentsVisible }"
  >
    <span>{{ 'Tasks.labels.createTaskModal.timeEntry.entries' | translate }}</span
    ><span class="timeentry__subtitle--sum"
      >{{ 'Tasks.labels.createTaskModal.timeEntry.total' | translate }} =
      {{ timeEntriesSum }}h</span
    >
  </div>

  <div class="timeentry__entries">
    <div [ngClass]="{ 'timeentry__entries--scrollable': isScrollable }">
      <div *ngFor="let entry of timeEntries">
        <troi-task-timeentry
          *ngIf="subtask || entry.subtask === 0"
          [timeEntry]="entry"
          [minDate]="getMinDate()"
          [currentUser]="currentUser"
          [singleComment]="subtask ? true : false"
          [internalCommentVisible]="timeEntrySettings.internalCommentsVisible"
          (entryUpdated)="onEntryUpdated($event)"
          (entryDeleted)="onEntryDeleted($event)"
          [timeRecordForOthers]="timeEntrySettings.timeRecordForOthers"
        ></troi-task-timeentry>
      </div>
      <div *ngIf="!subtask && subtasksHaveTimeEntries()" class="timeentry__subtasks">
        <div class="timeentry__subtasks--scrollable">
          <ng-container *ngFor="let subtask of task.subtasks">
            <div
              *ngIf="getSubtaskTimeentries(subtask.id).length > 0"
              class="timeentry__subtasks--subtask"
            >
              <span class="paragraph-title"
                >{{ 'Tasks.labels.createTaskModal.subtasks.subtask' | translate }}:
                {{ subtask.title }}</span
              >
              <troi-task-timeentry
                *ngFor="let entry of getSubtaskTimeentries(subtask.id)"
                [timeEntry]="entry"
                [minDate]="getMinDate()"
                [currentUser]="currentUser"
                [singleComment]="true"
                (entryUpdated)="onEntryUpdated($event, subtask.id)"
                (entryDeleted)="onEntryDeleted($event, subtask.id)"
                [timeRecordForOthers]="timeEntrySettings.timeRecordForOthers"
              ></troi-task-timeentry>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</form>
