import { Component, Input, AfterViewInit } from '@angular/core';
import { BreadcrumbInterface } from './breadcrumb.interface';

const LOGO_PATH = '/config/images/company_logo.png';
const PLACEHOLDER_PATH = '/site/resources5/images/company_placeholder.png';

@Component({
  selector: 'troi-breadcrumbs',
  templateUrl: './troi-breadcrumbs.component.html',
  styleUrls: ['./troi-breadcrumbs.component.scss'],
})
export class TroiBreadcrumbsComponent implements AfterViewInit {
  @Input() breadcrumbs: BreadcrumbInterface[] = [];
  url = window.location.origin;
  placeholder = false;
  loaded = false;

  ngAfterViewInit(): void {
    this.checkImage(this.logoUrl);
  }

  get logoUrl() {
    return this.url + LOGO_PATH;
  }

  get placeholderUrl() {
    return this.url + PLACEHOLDER_PATH;
  }

  setPlaceholder = () => {
    this.placeholder = true;
    this.setLoaded();
  };

  setLoaded = () => {
    this.loaded = true;
  };

  checkImage(imageSrc) {
    const img = new Image();
    img.onerror = this.setPlaceholder;
    img.onload = this.setLoaded;
    img.src = imageSrc;
  }
}
