import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription, first } from 'rxjs';
import { DomService } from '../../../../../../../../shared/troi-modals/dom.service';
import { ModalService } from '../../../../../../../../shared/troi-modals/modal.service';
import { TroiUserConfirmationComponent } from '../../../../../../../../shared/troi-user-confirmation/troi-user-confirmation.component';
import { UserConfirmationSubscriber } from '../../../../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { UserConfirmationEventEnum } from '../../../../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { TimeentryService } from '../../../network/timeentry.service';
import { TimeEntryOverviewInterface } from '../../../../../../interfaces/timeEntry.interface';
import { UserModel } from '../../../../../../../../core/user/user.model';

@Component({
  selector: 'troi-task-timeentry',
  templateUrl: './timeentry.component.html',
  styleUrls: ['./timeentry.component.scss'],
})
export class TimeentryComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('timePicker') timePicker: ElementRef;
  @Input() timeEntry: TimeEntryOverviewInterface;
  @Input() singleComment = false;
  @Input() internalCommentVisible = true;
  @Input() timeRecordForOthers = true;
  @Input() currentUser: UserModel;
  @Input() minDate;

  @Output() entryDeleted = new EventEmitter<TimeEntryOverviewInterface>();
  @Output() entryUpdated = new EventEmitter<TimeEntryOverviewInterface>();

  private subscriptions: Subscription = new Subscription();

  public loading = true;
  public showTrashIcon = false;
  public timeEntryForm: FormGroup;
  public fromDate = 0;
  public toDate = 0;

  constructor(
    private domService: DomService,
    private confirmationSubscriber: UserConfirmationSubscriber,
    private timeEntrySerice: TimeentryService,
    private renderer: Renderer2,
  ) {}

  ngOnInit() {
    this.timeEntryForm = new FormGroup({
      entryDate: new FormControl(),
      time: new FormControl('00:00', Validators.required),
      internalComment: new FormControl(),
      externalComment: new FormControl(),
    });
  }

  ngAfterViewInit() {
    this.loading = false;
  }

  public getEntryDate(): number {
    return this.timeEntry.billingDate.getTime();
  }

  public openTimePicker() {
    if (!this.isDisabled()) this.renderer.selectRootElement(this.timePicker.nativeElement).showPicker();
  }

  public onDeleteEntryClick() {
    const modalService = new ModalService(this.domService);
    const translations = {
      title: 'Tasks.labels.deleteTimeEntryModal.title',
      executeBtnTitle: 'Common.labels.continue',
      cancelBtnTitle: 'Common.labels.cancel',
      description: 'Tasks.labels.deleteTimeEntryModal.description',
    };

    modalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);

    this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
      if (result === UserConfirmationEventEnum.EXECUTE) {
        this.subscriptions.add(
          this.timeEntrySerice.deleteTimeentry(this.timeEntry).subscribe(() => {
            this.entryDeleted.emit(this.timeEntry);
          }),
        );
      }
    });
  }

  public isDisabled(): boolean {
    if (!this.timeRecordForOthers && this.timeEntry.employee.id !== this.currentUser.id) return true;
    else return false;
  }

  public updateTimeEntry(): void {
    if (!this.isDisabled())
      this.subscriptions.add(
        this.timeEntrySerice.updateTimeentry(this.timeEntry).subscribe((res) => {
          this.entryUpdated.emit(this.timeEntry);
        }),
      );
  }

  public dateChanged(dates): void {
    if (dates.date[0] && dates.date[0] !== this.getEntryDate()) {
      this.timeEntry.billingDate = new Date(dates.date[0]);
      this.updateTimeEntry();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
