import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { StopwatchService } from '../../../desk/widgets/stopwatch/stopwatch.service';
import { FooterService } from '../../../../shared/troi-footer/services/footer.service';
import { timePresentation } from '../../../desk/shared/time-switch/timePresentation';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DeskSettingsService } from '../../../desk/services/desk-settings.service';

@Component({
  selector: 'menu-stopwatch',
  templateUrl: './menu-stopwatch.component.html',
  styleUrls: ['./menu-stopwatch.component.scss'],
})
export class MenuStopwatchComponent implements OnInit {
  constructor(
    private stopwatchService: StopwatchService,
    private deskSettingsService: DeskSettingsService,
    public footerService: FooterService,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}
  public hasStartedStopwatches = false;
  public startedStopwatches: any = [];
  public latestStopwatch: any = {};
  public isPause = false;
  public el: any;
  public interval;
  private allEventsForListenerToFetchData = [
    'app:stopwatch:update',
    'app:stopwatch:deleted',
    'app:stopwatch:pause',
    'app:stopwatch:start',
    'app:stopwatch:created',
    'app:stopwatch:stop',
    'app:project-time:logged',
    'menu:stopwatch:created',
    'menu:project-time:logged',
  ];

  ngOnInit(): void {
    this.interval = setInterval(() => {
      this.onInterval(this.latestStopwatch);
    }, 1000);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.stopwatchService.updateI18nStopwatches(this.el);
    });
    this.el = window.top.document.getElementsByTagName('twc-stopwatches')[0];
    this.el?.addEventListener('startstop', ({ detail }) => {
      this.toggleActivityState(detail.id, detail.state === 4);
    });

    this.el?.addEventListener('stopwatchChange', ({ detail }) => {
      this.updateStopwatch(detail.id, detail.value);
    });
    this.el?.addEventListener('stopwatchDelete', ({ detail }) => {
      this.deleteStopwatch(detail);
    });
    this.el?.addEventListener('fetch', () => {
      this.fetchStopwatches();
    });

    this.allEventsForListenerToFetchData?.forEach((eventName) => {
      window.top.document.addEventListener(eventName, () => {
        this.fetchStopwatches();
      });
    });
    this.fetchStopwatches();
  }

  isPaused(stopwatch: any) {
    return stopwatch?.paused;
  }

  isNotStarted(stopwatch: any) {
    return !stopwatch?.paused && !stopwatch?.startDate;
  }

  getStopwatchProjectDescription(stopwatchData: any) {
    try {
      const { customerName, projectName, projectNumber, subProjectNames, calculationPositionName } = stopwatchData;
      if (!subProjectNames) {
        return '';
      }
      return [customerName, projectName, projectNumber, ...subProjectNames, calculationPositionName].join(' › ');
    } catch (e) {
      console.error(e);
      return '';
    }
  }

  getTime(elapsedTime: number) {
    return timePresentation(elapsedTime);
  }

  onInterval(stopwatch: any) {
    if (!stopwatch?.startDate) {
      return;
    }
    stopwatch.elapsedTime2 =
      stopwatch.elapsedTime + Math.floor((new Date().getTime() - new Date(stopwatch.startDate).getTime()) / 1000);
  }

  fetchStopwatches() {
    this.stopwatchService.listStopwatches().subscribe((response) => {
      this.startedStopwatches = response;
      this.latestStopwatch = this.startedStopwatches
        .slice()
        .sort((a, b) => new Date(b.modifiedDate).getTime() - new Date(a.modifiedDate).getTime())
        ?.filter((stopwatch) => !stopwatch.done)?.[0];
      const latestStopwatch = this.startedStopwatches.find((stopwatch) => stopwatch.id === this.latestStopwatch?.id);
      if (latestStopwatch) {
        latestStopwatch.isLastActive = true;
      }

      if (this.el?.inputStopwatches) {
        this.el.inputStopwatches = this.startedStopwatches;
      }
      this.cd.detectChanges();
    });
  }

  getLatestStopwatch() {
    return this.latestStopwatch;
  }
  clickStopwatch() {
    this.el.inputToggle = !Boolean(this.el.inputToggle);
    this.el.inputStopwatches = this.startedStopwatches;
    this.stopwatchService.updateI18nStopwatches(this.el);
    this.stopwatchService.updateStopwatchSettings(this.el);
  }

  updateStopwatch(id, payload) {
    this.stopwatchService.updateStopwatch(id, payload).subscribe((result) => {
      const event = new CustomEvent('menu:stopwatch:refresh');
      window.top.document.dispatchEvent(event);
    });
  }
  deleteStopwatch(id) {
    this.stopwatchService.deleteStopwatch(id).subscribe((result) => {
      const event = new CustomEvent('menu:stopwatch:refresh');
      window.top.document.dispatchEvent(event);
    });
  }
  toggleActivityState(_id?, stop?) {
    const id = _id ?? this.latestStopwatch.id;
    const stopwatch = this.startedStopwatches.find(({ id: internalId }) => internalId === id);
    if (stop) {
      this.stopwatchService.stopStopwatch(id).subscribe((result) => {
        const eventStart = new CustomEvent('menu:stopwatch:stop');
        window.top.document.dispatchEvent(eventStart);
      });
      this.cd.detectChanges();
      return;
    }
    if (this.isPaused(stopwatch) || this.isNotStarted(stopwatch)) {
      this.stopwatchService.startStopwatch(id).subscribe((result) => {
        const eventStart = new CustomEvent('menu:stopwatch:start');
        window.top.document.dispatchEvent(eventStart);
      });
      this.cd.detectChanges();
      return;
    } else {
      this.stopwatchService.pauseStopwatch(id).subscribe((result) => {
        const eventStop = new CustomEvent('menu:stopwatch:pause');
        window.top.document.dispatchEvent(eventStop);
      });
    }
  }

  handleTimeTrackingResponse(response) {
    if (response.success) {
      this.refreshTrackingSettings();
    }
  }

  refreshTrackingSettings() {
    this.footerService.getTimetrackerSetting().subscribe(() => {
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
}
