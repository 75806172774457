<div
  class="tnd-datepicker"
  [ngClass]="{
    'tnd-datepicker--disabled': disabled,
    'default-alignment': defaultAlignment,
    'create-task-alignment': createTaskAlignment
  }"
>
  <troi-icon *ngIf="showIcon" icon="icon-list-points" class="tnd-icon-calendar"></troi-icon>

  <input
    [formControl]="datePicker"
    type="text"
    class="tnd-datepicker__input"
    [placeholder]="placeholder"
    ngxDaterangepickerMd
    [(ngModel)]="selected"
    [showCustomRangeLabel]="false"
    (click)="onPickerShown()"
    [alwaysShowCalendars]="true"
    [locale]="settings"
    [ranges]="[]"
    [autoApply]="singleDatePicker"
    [linkedCalendars]="true"
    [align]="'right'"
    [singleDatePicker]="singleDatePicker"
    (ngModelChange)="dateChanged($event, true)"
    (startDateChanged)="dateChanged($event, true, true)"
    [showClearButton]="showClearButton"
    [showCancel]="showCancelButton"
    [maxDate]="maxDate"
    [minDate]="minDate"
    [ngClass]="{
      'tnd-datepicker--invalid': (fieldInvalid && validationEnabled) || !validDate
    }"
    [ngStyle]="{ 'padding-left': paddingLeft }"
    (input)="detectIfEmpty($event.target.value)"
  />
</div>
<div class="tnd-datepicker__error" *ngIf="validationEnabled || !validDate">
  <span
    *ngIf="(fieldInvalid && validDate) || showError"
    title="{{ requiredFieldErrorMessage | translate }}"
    >{{ requiredFieldErrorMessage | translate }}</span
  >
  <span *ngIf="!validDate" title="{{ 'Common.error.incorrectDate' | translate }}">{{
    'Common.error.incorrectDate' | translate
  }}</span>
</div>
