<div class="resources">
  <div class="rescources__headline" *ngIf="!isResourcesContext">
    <span class="resources__headline--subtitle">{{
      'Tasks.labels.createTaskModal.resources.subtitle' | translate
    }}</span>
  </div>

  <div class="resources__filters">
    <div
      class="resources__filters--select resources__filters--team-options"
      *ngIf="!isResourcesContext"
    >
      <troi-dropdown-select
        class="resources__filters--team-select"
        placeholder="{{ 'Tasks.labels.createTaskModal.resources.projectteam' | translate }}"
        [options]="generateTeamOptions()"
        size="11px"
        textAlignment="center"
        [noBorder]="true"
        (selected)="onTeamChange($event)"
        [top]="true"
        [labelIcon]="'icon-persons-with-my'"
      ></troi-dropdown-select>
    </div>

    <div
      class="resources__filters--select resources__filters--range-date"
      *ngIf="!isResourcesContext"
    >
      <troi-icon class="resources__filters--icon" icon="icon-list-points" size="14px"></troi-icon>
      <troi-range-date
        class="resources__filters--date-select"
        [rangeFrom]="task?.startDate ? task.startDate : dateNow"
        [minDate]="getMinDate()"
        (rangeChanged)="updateCalendarRange($event)"
        [settings]="{ format: settingsService.settings?.settings.dateFormat }"
        [placeholder]="'TT.MM.JJJJ'"
        [defaultAlignment]="false"
        [assignEmployeeAlignment]="true"
        [rangesInPast]="false"
      ></troi-range-date>
    </div>

    <div
      class="resources__filters--select resources__filters--settings"
      [ngClass]="{ 'd-none': isResourcesContext }"
    >
      <div
        (mouseenter)="showDropdown = true"
        (mouseleave)="showDropdown = false"
        class="resources__filters--dropdown-container"
      >
        <troi-icon class="resources__filters--icon" icon="icon-setting" size="14px"></troi-icon>
        <div *ngIf="showDropdown" class="resources__filters--dropdown">
          <div class="resources__filters--dropdown--view">
            <div
              *ngFor="let view of resourcesService.viewSelectionDropdown[0].rangeSelect"
              class="resources__filters--option-container"
              (click)="onSelectViewClick(view.value)"
            >
              <div class="resources__filters--option-container--radio">
                <div
                  *ngIf="selectedDateView && selectedDateView === view.value"
                  class="resources__filters--option-container--radio--clicked"
                ></div>
              </div>
              <span>{{ view.option | translate }}</span>
            </div>
          </div>
          <div class="resources__filters--dropdown--section">
            <div
              *ngFor="let view of resourcesService.viewSelectionDropdown[1].utilSelect"
              class="resources__filters--option-container"
              (click)="onSelectUtilizationClick(view.value)"
            >
              <div class="resources__filters--option-container--radio">
                <div
                  *ngIf="selectedUtilization === view.value"
                  class="resources__filters--option-container--radio--clicked"
                ></div>
              </div>
              <span>{{ view.option | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="selection-header">
    <span class="selection-header__current-view">{{ getCurViewTitle() | translate }}</span>
  </div>

  <div *ngIf="isLoading" class="loading-spinnner-container">
    <troi-loading-spinner class="loading-spinner"></troi-loading-spinner>
  </div>
  <div class="container-fixer">
    <div *ngIf="!isLoading" #container class="calendar-container">
      <div class="calendar-container__team" *ngFor="let team of forecastValues">
        <div class="calendar-container__heading">
          <div class="calendar-container__heading-title">
            <span class="calendar-container__team-heading">{{ team.title || 'All teams' }}</span>
          </div>
          <div class="calendar-container__heading-average">
            <span class="calendar-container__team-heading">
              ø {{ team.averageUtil !== null ? team.averageUtil.toFixed(2) : 'unknown' }}
            </span>
          </div>
        </div>
        <div
          [ngStyle]="{ 'max-height': isResourcesContext ? '' : projectTeamId === 0 ? '17rem' : '' }"
          class="calendar"
        >
          <div class="calendar__header">
            <div class="calendar__header-column">
              <span
                class="calendar__header__title"
                [ngClass]="{ 'stretch-to-180px': isResourcesContext }"
                >Verfügbare Zeit gesamt</span
              >
            </div>
            <div class="calendar__column">
              <div class="calendar__column-title" *ngFor="let colTitle of team.values.columns">
                <span *ngIf="selectedDateView !== 'day'">{{ getColTitle(colTitle) }}</span>
                <span *ngIf="selectedDateView === 'day'">{{
                  removeYear(
                    getColTitle(colTitle) | troiDate : settingsService.settings.settings.dateFormat
                  )
                }}</span>
              </div>
            </div>
          </div>
          <div class="calendar__row" *ngFor="let member of team.values.members; let rowIdx = index">
            <div
              class="calendar__person flex-wrap"
              [ngClass]="{
                assigned: member.alreadyAssigned,
                'stretch-to-180px': isResourcesContext
              }"
            >
              <div
                class="d-flex align-items-center w-100"
                (click)="isResourcesContext ? seeTaskDetails(rowIdx, team.title) : null"
              >
                <troi-task-modal-employee-avatar
                  [employee]="member.employee"
                  [position]="'absolute'"
                  [addEmployee]="!isResourcesContext"
                  [utilization]="member.employee.utilization"
                  [alreadyAssigned]="member.alreadyAssigned"
                  [animationDuration]="0"
                  (selectEmployee)="onAssignEmployeeClick(member.employee)"
                  triggers="mouseenter:mouseleave"
                  [ngbPopover]="assigneeNamePopover"
                  placement="right"
                  #assigneePopover="ngbPopover"
                ></troi-task-modal-employee-avatar>
                <ng-template #assigneeNamePopover>
                  <span>{{ member.employee.firstName }} {{ member.employee.lastName }}</span>
                </ng-template>
                <troi-icon
                  class="calendar__person-icon"
                  [medium]="true"
                  icon="icon-clock-with-minus"
                ></troi-icon>
                <span>{{ member.freeTime.toFixed(2) }}</span>
              </div>
              <ng-container *ngIf="visibleRows[team.title]?.has(rowIdx)">
                <div class="task-details" *ngFor="let task of team.values.valuesProject[rowIdx]">
                  {{ task.projectTaskName }}
                </div>
              </ng-container>
              <ng-container *ngIf="visibleRows[team.title]?.has(rowIdx)">
                <div class="task-details">
                  {{ 'Tasks.labels.createTaskModal.resources.leaveTime' | translate }}
                </div>
              </ng-container>
            </div>

            <div class="calendar__column" [ngClass]="{ assigned: member.alreadyAssigned }">
              <div
                class="utilizaion-field"
                *ngFor="let utilVal of team.values.values[rowIdx]; let cellIdx = index"
              >
                <div
                  [ngClass]="{
                    'red-mark': utilVal >= 80,
                    'orange-mark': utilVal >= 50 && utilVal < 80,
                    'border-left': checkBorder(utilVal, team.values.values[rowIdx][cellIdx - 1]),
                    'border-right': checkBorder(utilVal, team.values.values[rowIdx][cellIdx + 1])
                  }"
                  class="column-mark"
                >
                  {{ utilVal.toFixed(1) | number }}
                  <span *ngIf="selectedUtilization">%</span>
                  <span *ngIf="!selectedUtilization">h</span>
                </div>
                <ng-container *ngIf="visibleRows[team.title]?.has(rowIdx)">
                  <div class="task-details" *ngFor="let task of team.values.valuesProject[rowIdx]">
                    <div
                      class="bar-pill"
                      [ngClass]="{
                        'red-mark': task.values[cellIdx] >= 80,
                        'orange-mark': task.values[cellIdx] >= 50 && task.values[cellIdx] < 80,
                        'border-left': checkBorder(task.values[cellIdx], task.values[cellIdx - 1]),
                        'border-right': checkBorder(task.values[cellIdx], task.values[cellIdx + 1])
                      }"
                    >
                      {{ task.values[cellIdx].toFixed(1) | number }}
                      <span *ngIf="selectedUtilization">%</span>
                      <span *ngIf="!selectedUtilization">h</span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="visibleRows[team.title]?.has(rowIdx)">
                  <div class="task-details">
                    <div
                      class="bar-pill"
                      [ngClass]="{
                        'red-mark': team.values.valuesWorkTime[rowIdx][cellIdx]?.leaveTime >= 80,
                        'orange-mark':
                          team.values.valuesWorkTime[rowIdx][cellIdx]?.leaveTime >= 50 &&
                          task.values[cellIdx] < 80,
                        'border-left': checkBorder(
                          team.values.valuesWorkTime[rowIdx][cellIdx]?.leaveTime,
                          team.values.valuesWorkTime[rowIdx][cellIdx - 1]?.leaveTime
                        ),
                        'border-right': checkBorder(
                          team.values.valuesWorkTime[rowIdx][cellIdx]?.leaveTime,
                          team.values.valuesWorkTime[rowIdx][cellIdx + 1]?.leaveTime
                        )
                      }"
                    >
                      {{ team.values.valuesWorkTime[rowIdx][cellIdx].leaveTime }}
                      <span *ngIf="selectedUtilization">%</span>
                      <span *ngIf="!selectedUtilization">h</span>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
