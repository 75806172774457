import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { TaskModel } from '../../../../../models/task.model';
import { ResourcesService } from '../../../../../network/resources.service';
import { Subject, Subscription } from 'rxjs';
import { TroiDropdownListModel } from '../../../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TeamOptionInterface } from '../../../../../interfaces/teamOption.interface';
import { ForecastResponseInterface, TeamResponseInterface } from '../../../../../interfaces/responses.interface';
import { ForecastViewEnum } from '../../../../../enum/forecast-view';
import { ForecastMemberInterface, ForecastTeamInterface } from '../../../../../interfaces/forecast.interface';
import { PersonInterface } from '../../../../../../../shared/troi-person/interfaces/person.interface';
import { RangeDateChangedInterface } from '../../../../../../../shared/troi-range-date/troi-range-date.component';
import { TasksSettingsService } from '../../../../../services/tasks-settings.service';
import { AssigneeInterface } from '../../../../../interfaces/assignee.interface';
import { TasksService } from '../../../../../network/tasks.service';
import { TasksHelperService } from '../../../../../services/helper.service';

@Component({
  selector: 'troi-task-modal-resources',
  templateUrl: './resources-content.component.html',
  styleUrls: ['./resources-content.component.scss'],
})
export class ResourcesContentComponent implements OnDestroy, OnInit {
  @ViewChild('container') containerRef: ElementRef<HTMLElement>;
  @Input() isResourcesContext = false;
  @Input() task: TaskModel; // Used for Project List and Kanban view.
  @Input() resourceTasks: ForecastTeamInterface[]; // Used for Resources View.

  @Input() resetThirdDropdown: Subject<boolean>;
  @Input() enableTeamFilter = true;

  private subscriptions: Subscription = new Subscription();

  public isLoading = false;
  public showDropdown = false;
  public dateNow = new Date();
  public dateNowMilliseconds = this.dateNow.setHours(0, 0, 0, 0);
  public thirdDaysAheadFromDateNowMilliseconds = new Date(
    this.dateNowMilliseconds + 30 * 24 * 60 * 60 * 1000,
  ).getTime();

  private projectTeamId = 0;
  public timerangeFrom: number;
  public timerangeTo: number;
  public selectedDateView = ForecastViewEnum.DAY;
  public selectedUtilization = true;

  public projectTeams: TeamOptionInterface[] = [];
  public forecastValues: ForecastTeamInterface[];
  public visibleRows: { [teamTitle: string]: Set<number> } = {};

  public mockData: any[] = [
    { projectId: 'P1', taskId: 'T1', values: [0, 0, 0, 0, 100] },
    { projectId: 'P2', taskId: 'T2', values: [60, 40, 80, 79, 0] },
    { projectId: 'P3', taskId: 'T3', values: [40, 60, 20, 21, 0] },
  ];

  constructor(
    private resourcesService: ResourcesService,
    public settingsService: TasksSettingsService,
    public tasksService: TasksService,
    private helperService: TasksHelperService,
  ) {
    this.subscriptions.add(
      this.resourcesService.getProjectGroups().subscribe((res: TeamResponseInterface) => {
        this.projectTeams = res.data;
      }),
    );
  }

  ngOnInit(): void {
    if (this.isResourcesContext) this.forecastValues = this.resourceTasks;

    this.getSelectedUtilization();
    this.getSelectedDateView();
  }

  private getSelectedUtilization() {
    this.subscriptions.add(
      this.helperService.selectedUtilization.subscribe((value) => {
        this.selectedUtilization = value;
      }),
    );
  }

  private getSelectedDateView() {
    this.subscriptions.add(
      this.helperService.selectedDateView.subscribe((value) => {
        this.selectedDateView = value;
      }),
    );
  }

  private getForecastValues() {
    this.isLoading = true;

    this.subscriptions.add(
      this.resourcesService
        .getForecastValues(
          this.resourcesService.buildForecastUrl(
            this.timerangeFrom,
            this.timerangeTo,
            this.selectedUtilization,
            this.selectedDateView,
            this.task?.project?.id,
            this.projectTeamId,
          ),
        )
        .subscribe((res: ForecastResponseInterface) => {
          this.forecastValues = res.data;
          // check which employee is already task assignee
          this.forecastValues.forEach((team: ForecastTeamInterface) => {
            const combinedArray = team.values.members.map((member, index) => ({
              member,
              value: team.values.values[index],
            }));

            combinedArray.sort((a, b) => {
              return a.member.employee.lastName.toLowerCase().localeCompare(b.member.employee.lastName.toLowerCase());
            });

            team.values.members = combinedArray.map((item) => item.member);
            team.values.values = combinedArray.map((item) => item.value);

            team.values.members.map((member: ForecastMemberInterface) => {
              member.alreadyAssigned = this.task?.assignees.filter((x) => +x.user.id === member.employee.id).length > 0;
            });
          });

          this.isLoading = false;
        }),
    );
  }
  public generateTeamOptions(): TroiDropdownListModel[] {
    return [
      {
        active: true,
        label: 'Tasks.labels.all',
        value: 0,
      },
      ...this.projectTeams.map((item: TeamOptionInterface) => ({
        active: true,
        label: item.description,
        value: item.id.toString(),
      })),
    ];
  }

  public onSelectViewClick(selectedView: ForecastViewEnum): void {
    this.selectedDateView = selectedView;
    this.getForecastValues();
  }

  public onSelectUtilizationClick(selectedUtil: boolean): void {
    this.selectedUtilization = selectedUtil;
    this.getForecastValues();
  }

  public onAssignEmployeeClick(employee: PersonInterface): void {
    this.tasksService
      .getAssigneeOptions(
        this.task.project?.id,
        this.task.calculationPosition?.client.mandant.id,
        this.getTimestamp('start'),
        this.getTimestamp('end'),
      )
      .subscribe((res) => {
        const assignableEmployees = res.data;
        if (assignableEmployees && assignableEmployees.some((e) => e.id === employee.id)) {
          this.task.assignees.push({
            id: employee.id.toString(),
            user: employee,
            projectTask: +this.task.id,
            assignments: [],
            utilization: 0,
          } as AssigneeInterface);
          // update team member
          this.forecastValues.forEach((team: ForecastTeamInterface) => {
            team.values.members.map((m: ForecastMemberInterface) => {
              if (!m.alreadyAssigned) m.alreadyAssigned = m.employee.id === employee.id;
            });
          });
        }
      });
  }

  private getTimestamp(type: string): number {
    if (type === 'start' && this.task.startDate) return new Date(this.task.startDate).getTime();
    if (type === 'end' && this.task.endDate) return new Date(this.task.endDate).getTime();

    return undefined;
  }

  public onTeamChange(teamId: number): void {
    this.projectTeamId = teamId;
    this.getForecastValues();
  }

  public updateCalendarRange(newTimerange: RangeDateChangedInterface): void {
    // if timestamp is null, we use current timestamp without millisecond (required by backend)
    this.timerangeFrom =
      newTimerange.date[0] !== null ? newTimerange.date[0] : Math.floor(new Date().getTime() / 1000) * 1000;
    this.timerangeTo =
      newTimerange.date[1] !== null && newTimerange.date[1] > this.timerangeFrom
        ? newTimerange.date[1]
        : this.timerangeFrom;

    this.getForecastValues();
  }

  public getMinDate() {
    if (this.task?.startDate) {
      return moment(this.task.startDate);
    } else {
      return moment(this.dateNow);
    }
  }

  public checkBorder(current: number, neighbour: number): boolean {
    if (!neighbour) return true;
    else if (current >= 80 && neighbour < 80) return true;
    else if (current >= 50 && current < 80 && (neighbour < 50 || neighbour >= 80)) return true;
    return false;
  }

  public getColTitle(title: string): string {
    const lang = JSON.parse(localStorage.getItem('lang'));
    if (this.selectedDateView === ForecastViewEnum.DAY) {
      return title;
    } else if (this.selectedDateView === ForecastViewEnum.MONTH) {
      const d = new Date();
      d.setMonth(+title - 1);
      return d.toLocaleDateString(lang, { month: 'short' });
    } else {
      return title;
    }
  }

  public removeYear(date: string): string {
    const parts = date.split('.');
    const filteredParts = parts.filter((part) => !/^\d{4}$/.test(part));
    return filteredParts.join('.');
  }

  /* TODO: To be removed if not longer necessary, because response with backend alredy comes with CW/KW */
  public getCalWeekAbbreviation(): string {
    const lang = JSON.parse(localStorage.getItem('lang'));
    if (lang === 'en') return 'CW ';
    else if (lang === 'de') return 'KW ';
    else return 'SC ';
  }

  public getCurViewTitle(): string {
    if (this.selectedDateView === ForecastViewEnum.DAY) return 'Tasks.labels.createTaskModal.resources.filter.dayView';
    else if (this.selectedDateView === ForecastViewEnum.WEEK)
      return 'Tasks.labels.createTaskModal.resources.filter.weekView';
    else return 'Tasks.labels.createTaskModal.resources.filter.monthView';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  seeTaskDetails(rowIdx: number, teamTitle: string) {
    if (!this.visibleRows[teamTitle]) {
      this.visibleRows[teamTitle] = new Set<number>();
    }

    if (this.visibleRows[teamTitle].has(rowIdx)) {
      this.visibleRows[teamTitle].delete(rowIdx);
    } else {
      this.visibleRows[teamTitle].add(rowIdx);
    }
  }
}
