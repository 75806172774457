import { Injectable } from '@angular/core';
import { CommonFiltersFormService } from '../../common/services/common-filters-form.service';
import { FiltersInterface } from '../../../shared/troi-data-listing-filters/filters.interface';
import { FilterElementInterface } from '../../../shared/troi-filter-with-modal/filter-element.interface';
import { FilterTypeEnum } from '../../../shared/troi-filter-with-modal/filter-type.enum';

@Injectable()
export class FiltersFormService extends CommonFiltersFormService {
  private static buildFilterWithMultipleAllSelect(filterElem: FilterElementInterface): string {
    return Array.isArray(filterElem.value) ? filterElem.value.join(';') : filterElem.value;
  }

  prepareSearchRequestParams(filters: FiltersInterface) {
    const data = {
      project: filters.dropdownSecond
        ? FiltersFormService.buildFilterWithMultipleAllSelect({
            value: filters.dropdownSecond,
            type: FilterTypeEnum.DROPDOWN,
            label: 'Tasks.labels.filters.project',
          })
        : '',
      assignee: filters.dropdownThird
        ? FiltersFormService.buildFilterWithMultipleAllSelect({
            value: filters.dropdownThird,
            type: FilterTypeEnum.DROPDOWN,
            label: 'Tasks.labels.filters.employee.label',
          })
        : '',
    };

    return {
      ...super.prepareSearchRequestParams(filters),
      ...data,
    };
  }
}
