import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { TaskActionInterface } from '../interfaces/task-action.interface';
import { SubtaskInterface } from '../interfaces/subtask.interface';
import { TimeEntryOverviewInterface } from '../interfaces/timeEntry.interface';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TranslateService } from '@ngx-translate/core';
import { TaskActionsEnum } from '../enum/task-actions';
import { TasksService } from '../network/tasks.service';
import { TaskModel } from '../models/task.model';
import { ForecastViewEnum } from '../enum/forecast-view';

@Injectable()
export class TasksHelperService {
  public taskActionSub = new Subject<TaskActionInterface>();
  private _assignHoursSub = new BehaviorSubject<boolean>(false);
  private _timeEntrySum = new BehaviorSubject<number>(0);
  private _selectedSubtask: BehaviorSubject<SubtaskInterface> = new BehaviorSubject<SubtaskInterface>(null);
  private _deleteSubtask: BehaviorSubject<SubtaskInterface> = new BehaviorSubject<SubtaskInterface>(null);
  private _assignedEmployeesChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _taskHoursChanged: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private _timelineTimerangeChanged: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(null);
  private _isProjectContext: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _isResourcesContext: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _emptyBoardCreated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _taskChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  private _selectedUtilization: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private _selectedDateView: BehaviorSubject<ForecastViewEnum> = new BehaviorSubject<ForecastViewEnum>(
    ForecastViewEnum.DAY,
  );

  private _projectId: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private _timeEntriesChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public selectedSubtask = this._selectedSubtask.asObservable();
  public deleteSubtask = this._deleteSubtask.asObservable();
  public assignHoursSub = this._assignHoursSub.asObservable();
  public timeEntrySumSub = this._timeEntrySum.asObservable();
  public assignedEmployeesChanged = this._assignedEmployeesChanged.asObservable();
  public taskHoursChangedSub = this._taskHoursChanged.asObservable();
  public timelineTimerangeChanged = this._timelineTimerangeChanged.asObservable();
  public isProjectContext = this._isProjectContext.asObservable();
  public isResourcesContext = this._isResourcesContext.asObservable();
  public selectedUtilization = this._selectedUtilization.asObservable();
  public selectedDateView = this._selectedDateView.asObservable();
  public emptyBoardCreated = this._emptyBoardCreated.asObservable();
  public taskChanged = this._taskChanged.asObservable();
  public projectId = this._projectId.asObservable();
  public timeEntriesChanged = this._timeEntriesChanged.asObservable();

  private datepickerTopValue = 0;

  public getMoreActionsOptions(task?: TaskModel): TroiDropdownListModel[] {
    return [
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.reminder'),
        value: TaskActionsEnum.REMINDER,
        disabled: task?.startDate ? false : true,
        icon: 'icon-number-calendar',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.edit'),
        value: TaskActionsEnum.EDIT,
        disabled: false,
        icon: 'icon-can-edit',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.copy'),
        value: TaskActionsEnum.COPY,
        disabled: false,
        icon: 'icon-files',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.move'),
        value: TaskActionsEnum.MOVE_TASK,
        disabled: false,
        icon: 'icon-export',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.delete'),
        value: TaskActionsEnum.DELETE_TASK,
        disabled: !task?.hoursSpent ? false : true,
        icon: 'icon-trash-can',
      },
    ];
  }

  quillConfig = {
    toolbar: {
      container: [
        ['bold', 'italic', 'underline', 'strike'],
        ['code-block'],
        [{ header: 1 }, { header: 2 }],
        [{ list: 'ordered' }, { list: 'bullet' }],

        ['link'],
      ],
    },
  };

  constructor(private translationService: TranslateService) {}

  public setSubtask(value: SubtaskInterface): void {
    this._selectedSubtask.next(value);
  }

  public setSubtaskToDelete(value: SubtaskInterface): void {
    this._deleteSubtask.next(value);
  }

  public setAssignedHoursState(state: boolean): void {
    this._assignHoursSub.next(state);
  }

  public setTimeEntrySum(sum: number): void {
    this._timeEntrySum.next(sum);
  }

  public onAssignedEmployeeChange(state: boolean): void {
    this._assignedEmployeesChanged.next(state);
  }

  public setTaskHoursChanged(hours: number): void {
    this._taskHoursChanged.next(hours);
  }

  public setTimelineRangeChanged(date: number[]): void {
    this._timelineTimerangeChanged.next(date);
  }

  public setProjectContext(isProjectContext: boolean): void {
    this._isProjectContext.next(isProjectContext);
  }

  public setResourcesContext(isResourcesContext: boolean): void {
    this._isResourcesContext.next(isResourcesContext);
  }

  public setSelectedUtilization(selectedUtilization: boolean): void {
    this._selectedUtilization.next(selectedUtilization);
  }

  public setSelectedDateView(selectedDateView: ForecastViewEnum): void {
    this._selectedDateView.next(selectedDateView);
  }

  public setEmptyBoardCreated(boardCreated: boolean): void {
    this._emptyBoardCreated.next(boardCreated);
  }

  public setProjectId(projectId: string): void {
    this._projectId.next(projectId);
  }

  public onTimeEntriesChanged(changed: boolean): void {
    this._timeEntriesChanged.next(changed);
  }

  public setTaskChanged(changed: boolean) {
    this._taskChanged.next(changed);
  }

  // gets exact position of input and adjusts its datepicker position based on that
  public getDatepickerPosition(event: MouseEvent, top?: boolean) {
    const elementPos = (event.target as HTMLElement).getBoundingClientRect();

    this.datepickerTopValue = top ? elementPos.top - 320 : elementPos.top + 20;

    document.documentElement.style.setProperty('--datepicker-top-value', `${this.datepickerTopValue}px`);
  }

  public formatTimeEntries(entries: TimeEntryOverviewInterface[]): TimeEntryOverviewInterface[] {
    entries.map((t: TimeEntryOverviewInterface) => {
      t.billingDate = new Date(t.billingDate);
      const hours = Math.floor(+t.quantity);
      const minutes = (+t.quantity - hours) * 60;
      t.quantity = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    });

    return entries;
  }

  public addHTTPSToURL(editorContent: string) {
    const tempElement = document.createElement('div');
    tempElement.innerHTML = editorContent;

    const anchorTags = tempElement.querySelectorAll('a');

    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*)*\/?$/i;
    anchorTags.forEach((anchorTag: HTMLAnchorElement) => {
      const href = anchorTag.getAttribute('href');

      if (href && urlPattern.test(href)) {
        const hasHTTP = /^https?:\/\//i.test(href);
        if (!hasHTTP) anchorTag.setAttribute('href', 'https://' + href);
      }
    });

    return tempElement.innerHTML;
  }
}
