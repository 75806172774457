import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { NgProgressRouterModule } from 'ngx-progressbar/router';
import 'rxjs';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './not-found.component';
import { AppRoutingModule } from './app-routing.component';
import { HeaderNotificationModule } from './modules/header-notification/headerNotification.module';
import { TroiHttpLoaderFactory } from './core/language/troi.translate.loader';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { StorageListener } from './core/storage/storage.listener';
import { ParentWindowRef } from './core/services/parent-window-ref.service';
import { AccountsModule } from './modules/accounts/accounts.module';
import { BookingsModule } from './modules/bookings/bookings.module';
import { ProjectListModule } from './modules/project-list/project-list.module';
import { MenuModule } from './modules/menu/menu.module';
import { NgEventBus } from 'ng-event-bus';
import { TasksModule } from './modules/tasks/tasks.module';
import { SettingsModule } from './modules/settings/settings.module';
import '../../../troi-components/dist/component-lib';

@NgModule({
  imports: [
    NgbModule,
    TranslateModule.forRoot(TroiHttpLoaderFactory),
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    HeaderNotificationModule,
    FormsModule,
    AccountsModule,
    ReactiveFormsModule,
    CommonModule,
    CoreModule,
    BookingsModule,
    ProjectListModule,
    TasksModule,
    MenuModule,
    NgProgressModule,
    NgProgressHttpModule,
    NgProgressRouterModule,
    SettingsModule,
  ],
  declarations: [AppComponent, PageNotFoundComponent],
  bootstrap: [AppComponent],
  providers: [StorageListener, ParentWindowRef, NgEventBus],
  exports: [BrowserModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
