import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaskModel } from '../../models/task.model';
import { TasksHelperService } from '../../services/helper.service';
import { TasksService } from '../../network/tasks.service';
import { TaskFiltersService } from '../../services/filters.service';
import { FiltersInterface } from '../../../../shared/troi-data-listing-filters/filters.interface';
import { ForecastTeamInterface } from '../../interfaces/forecast.interface';

@Component({
  selector: 'troi-ganttchartview',
  templateUrl: './ganttchartview.component.html',
  styleUrls: ['./ganttchartview.component.scss'],
})
export class GanttchartviewComponent {
  private subscriptions: Subscription = new Subscription();

  public isProjectContext = false;
  public resourceTasks: ForecastTeamInterface[] = [];
  public filters: FiltersInterface = {
    search: '',
    dropdownOptional: null,
    dropdownFirst: null,
    dropdownSecond: null,
    dropdownThird: null,
    filters: null,
    currentPage: 1,
    pageSize: 0,
  };
  constructor(
    private helperService: TasksHelperService,
    public tasksService: TasksService,
    public filterService: TaskFiltersService,
  ) {
    this.getProjectContext();
    this.resourceTasks = this.tasksService.resourceTasks;
  }

  private getProjectContext() {
    this.subscriptions.add(
      this.helperService.isProjectContext.subscribe((value) => {
        if (value) this.isProjectContext = true;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
