<div
  (click)="onClickAvatar()"
  class="menu-user"
  *ngIf="user"
  [ngClass]="{ 'menu-user--no-avatar': !avatar }"
  [ngStyle]="getStyles()"
>
  <div class="menu-user__initials" *ngIf="!avatar">
    {{ initials }}
  </div>
</div>
