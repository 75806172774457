<div class="filters-section" [ngClass]="{ 'filters-section--with-header': header }">
  <ng-container *ngIf="header; else filtersRow">
    <div class="filters-section__header-row">
      <div class="filters-section__header">
        <span class="filters-section__title" *ngIf="showTitle && header && header.length">{{
          header | translate
        }}</span>
        <troi-filter-sets
          *ngIf="filterSets?.length > 0"
          class="filters-section__filter-sets"
          [selectedFilterSetId]="selectedFilterSetId"
          [filterSets]="filterSets"
          [areFiltersEdited]="areFiltersEdited"
          (filterSetSelected)="emitFilterSetSelected($event)"
          (filterSetDeleted)="emitFilterSetDeleted($event)"
          (filterSetAsDefault)="emitFilterSetAsDefault($event)"
          data-test="projects-visibility-dropdown"
        ></troi-filter-sets>
      </div>
      <div
        class="filters-section__quick-filters"
        [ngClass]="{ 'col-gap-filter': isResourceContext, 'min-width-auto': isResourceContext }"
      >
        <ng-container *ngTemplateOutlet="quickFilters"></ng-container>
      </div>
    </div>

    <div class="filters-section__main-filters-row">
      <ng-container *ngTemplateOutlet="mainFilters"></ng-container>
      <div class="filters-section__options">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-container>

  <ng-template #filtersRow>
    <ng-container *ngTemplateOutlet="mainFilters"></ng-container>
    <ng-container *ngTemplateOutlet="quickFilters"></ng-container>
  </ng-template>
</div>

<ng-template #mainFilters>
  <div
    *ngIf="!this.isResourceContext"
    class="filters-section__search filters-section__item"
    [ngClass]="{ 'filters-section__search--active': searchActive() }"
  >
    <troi-filter class="filters__filter filters__filter--search">
      <troi-icon icon="icon-loop-with-arrow-down"></troi-icon>
      <input
        type="text"
        name="search"
        (keyup)="searchInput.next($event)"
        [(ngModel)]="filters.search"
        placeholder="{{ 'Booking.labels.search' | translate }}"
      />
    </troi-filter>
  </div>
  <div class="filters-section__filters filters-section__item">
    <troi-filter-with-modal
      [filterData]="filters?.filters"
      [currencyMoneyData]="currencyMoneyData"
      (filtersApplied)="filtersApplied($event)"
      (filtersReset)="emitFiltersReset()"
      [year]="year"
      [client]="client"
      [lang]="lang"
      [limitDate]="limitDate"
      [dateFormat]="dateFormat"
    ></troi-filter-with-modal>
  </div>
  <div
    (click)="toggleViewSettings()"
    (mouseleave)="showDropdown = false"
    class="filters-section__filters filters-section__item position-relative"
    *ngIf="this.isResourceContext"
  >
    <troi-icon class="resources__filters--icon" icon="icon-setting" size="14px"></troi-icon>
    <span class="ps-2" role="button">{{ 'Booking.labels.settings' | translate }}</span>
    <div *ngIf="showDropdown" class="resources__filters--dropdown">
      <div class="resources__filters--dropdown--view">
        <div
          *ngFor="let view of viewSelectionOptionsList"
          class="resources__filters--option-container"
          (click)="onSelectViewClick(view.value)"
        >
          <div class="resources__filters--option-container--radio">
            <div
              *ngIf="selectedDateView && selectedDateView === view.value"
              class="resources__filters--option-container--radio--clicked"
            ></div>
          </div>
          <span>{{ view.option | translate }}</span>
        </div>
      </div>
      <div class="resources__filters--dropdown--section">
        <div
          *ngFor="let view of resourcesService.viewSelectionDropdown[1].utilSelect"
          class="resources__filters--option-container"
          (click)="onSelectUtilizationClick(view.value)"
        >
          <div class="resources__filters--option-container--radio">
            <div
              *ngIf="selectedUtilization === view.value"
              class="resources__filters--option-container--radio--clicked"
            ></div>
          </div>
          <span>{{ view.option | translate }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="filterSets?.length > 0"
    class="filters-section__item filters-section__save-filter-set"
  >
    <div *ngIf="areFiltersEdited" (click)="filterSetOpenSave.emit()">
      <troi-icon icon="icon-save"></troi-icon>
      <span>{{ 'FilterSets.saveFilterSet' | translate }}</span>
    </div>
  </div>
  <div class="filters-section__filter-tabs filters-section__item" #filterChips>
    <troi-filter-chips
      [filters]="filters.filters"
      [reloadFilterChips]="reloadFilterChips"
      (filterClosed)="emitFiltersChanged()"
      [dateFormat]="dateFormat"
      [width]="filterChipsWidth"
    ></troi-filter-chips>
  </div>
</ng-template>

<ng-template #quickFilters>
  <div
    *ngIf="optionalDropdown && year && client && lang"
    class="filters-section__optional-dropdown filters-section__item"
  >
    <troi-dropdown-select-lazy
      [noBorder]="true"
      [filterObject]="client"
      [year]="year"
      [lang]="lang"
      [reloadInitData]="shouldReloadOptionalDropdownOptions()"
      [dataType]="optionalDropdown?.dataType"
      [initValue]="optionalDropdown?.value"
      [defaultEmptyValue]="optionalDropdown.defaultValue"
      (selected)="emitOptionalDropdownChanged($event)"
      (optionsLoaded)="updateOptionalDropdown($event)"
      [refreshPreloadedOptionsOnChange]="true"
      [predefinedOptions]="optionalDropdown?.preloadedOptions"
      [withSearch]="optionalDropdown.withSearch"
      [initLazyOptionOnInit]="optionalDropdown.loadOptionOnInit"
    ></troi-dropdown-select-lazy>
  </div>
  <div
    *ngIf="!enableDaterangePicker || enableClientDropDown"
    class="filters-section__dropdown filters-section__dropdown--first filters-section__item"
  >
    <troi-dropdown-select
      [noBorder]="true"
      size="12px"
      row-value
      (selected)="emitFirstDropdownChanged()"
      [(ngModel)]="filters.dropdownFirst"
      name="dropdownFirst"
      data-test="client-dropdown"
      [options]="firstDropdown"
    >
    </troi-dropdown-select>
  </div>
  <div
    *ngIf="!isResourceContext"
    class="filters-section__dropdown filters-section__dropdown--second filters-section__item"
    [ngClass]="{ 'col-gap-filter': isResourceContext }"
  >
    <troi-dropdown-select
      [noBorder]="true"
      [search]="secondDropdownWithConfig.search"
      [multipleSelect]="secondDropdownWithConfig.multiple"
      [disable]="secondDropdownWithConfig.disable"
      [selectAllOption]="secondDropdownWithConfig.selectAllOption"
      [selectAllOptionLabel]="secondDropdownWithConfig?.selectAllOptionLabel"
      size="12px"
      row-value
      (selected)="emitSecondDropdownChanged()"
      [initValue]="filters.dropdownSecond"
      [(ngModel)]="filters.dropdownSecond"
      name="dropdownSecond"
      data-test="customer-dropdown"
      [options]="
        !enableDaterangePicker
          ? sortAlphabetic(secondDropdownWithConfig.values, false)
          : secondDropdownWithConfig.values
      "
      [reloadPreloadedOptions]="reloadSecondDropdownOptions"
      [resetMultipleSelect]="resetSecondDropdown"
      [placeholder]="secondDropdownWithConfig?.placeholder | translate"
    >
    </troi-dropdown-select>
  </div>
  <div
    *ngIf="thirdDropdownWithConfig"
    class="filters-section__dropdown filters-section__dropdown--third filters-section__item"
  >
    <troi-dropdown-select
      [noBorder]="true"
      [search]="thirdDropdownWithConfig.search"
      [multipleSelect]="thirdDropdownWithConfig.multiple"
      [disable]="thirdDropdownWithConfig.disable"
      [selectAllOption]="thirdDropdownWithConfig.selectAllOption"
      [selectAllOptionLabel]="thirdDropdownWithConfig?.selectAllOptionLabel"
      size="12px"
      row-value
      (selected)="emitThirdDropdownChanged()"
      [initValue]="filters.dropdownThird"
      [(ngModel)]="filters.dropdownThird"
      name="dropdownThird"
      data-test="project-status-dropdown"
      [options]="sortAlphabetic(thirdDropdownWithConfig.values, true)"
      [reloadPreloadedOptions]="reloadThirdDropdownOptions"
      [resetMultipleSelect]="resetThirdDropdown"
      [placeholder]="thirdDropdownWithConfig?.placeholder | translate"
    >
    </troi-dropdown-select>
  </div>
  <div *ngIf="enableDaterangePicker" class="filters-section__item filters-section__dropdown--date">
    <troi-range-date
      [rangeFrom]="filters.filters[1].value[0]"
      [rangeTo]="filters.filters[1].value[1]"
      [year]="year"
      [limitDate]="limitDate"
      [dateFormat]="dateFormat"
      (rangeChanged)="rangeChanged($event)"
      [placeholder]="'Booking.labels.dateRange' | translate"
      [assignEmployeeAlignment]="isResourceContext"
    ></troi-range-date>
  </div>
  <div class="filters-section__columns filters-section__item">
    <troi-btn class="reset-btn" (click)="emitFiltersReset()">{{
      'Main.Reset' | translate
    }}</troi-btn>
  </div>
  <div *ngIf="showColumnSelection" class="filters-section__columns filters-section__item">
    <troi-columns-selector
      [disabled]="columnDisabled()"
      [columnList]="columnList"
      (columnsSaved)="onColumnChanged($event)"
    ></troi-columns-selector>
  </div>
  <div class="filters-section__navigation">
    <ng-content select="[navBar]"></ng-content>
  </div>
</ng-template>
