import * as _ from 'lodash';
import { StorageService } from '../../core/storage/storage.service';
import { ColumnSelectInterface } from './column-select.interface';
import { UserSettings } from '../../core/services/user-settings';

export class ColumnsSelectService extends UserSettings {
  columnStoreMainKey = 'columns';

  fetchedColumns: ColumnSelectInterface[];

  constructor(public storage: StorageService) {
    super(storage);
    this.initLSUserData(this.columnStoreMainKey);
  }

  saveColumns(key: string, columns: ColumnSelectInterface[]) {
    const columnsSaved = this.getAllUsersSettings();
    columnsSaved[this.userId][this.columnStoreMainKey][key] = columns;
    this.saveUserSetting(columnsSaved);
  }

  protected isColumnVisible(columnList: ColumnSelectInterface[], columnName, defaultVisibleState = true): boolean {
    const columnData = _.find(columnList, ['id', columnName]);
    return columnData ? columnData.active : defaultVisibleState;
  }

  protected getColumnList(defaultColumnList: ColumnSelectInterface[], key: string): ColumnSelectInterface[] {
    if (this.fetchedColumns) {
      return this.fetchedColumns;
    }

    const columnsSaved = this.getSettingForUser(this.columnStoreMainKey)[key];

    if (!columnsSaved) {
      this.saveColumnList(defaultColumnList, key);
    } else {
      this.saveColumnList(columnsSaved, key);
    }
    this.fetchedColumns = columnsSaved;

    return columnsSaved;
  }

  protected saveColumnList(columns: ColumnSelectInterface[], key: string) {
    this.fetchedColumns = columns;
    this.saveColumns(key, columns);
  }
}
