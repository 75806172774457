import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { Moment } from 'moment';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'tnd-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
})
export class DatepickerComponent implements OnInit, OnChanges {
  datePicker: FormControl;

  selected: { startDate: Moment; endDate: Moment } = null;
  ranges: any;
  settings = {
    format: 'DD.MM.YYYY',
    applyLabel: this.translationService.instant('Desktop.General.Actions.Apply'),
    clearLabel: this.translationService.instant('Desktop.General.Actions.Cancel'),
    cancelLabel: this.translationService.instant('Desktop.General.Actions.Cancel'),
    customRangeLabel: 'Custom range',
    daysOfWeek: moment.weekdaysMin(),
    monthNames: moment.monthsShort(),
    firstDay: 1,
  };
  @Input() public maxDate;
  @Input() public minDate;
  validDate = true;
  public showError = false;

  @Input() public defaultAlignment = true;
  @Input() public createTaskAlignment = false;
  @Input() singleDatePicker = false;
  @Input() disabled = false;
  @Input() public showIcon = true;
  @Input() public rangeFrom: string;
  @Input() public rangeTo: string;
  @Input() public year: string;
  @Input() public limitDate = false;
  @Input() public backwardDays: number;
  @Input() public fieldInvalid = false;
  @Input() public validationEnabled = false;
  @Input() public emitIfEmpty = false;
  @Input() public requiredFieldErrorMessage = 'Form.Error.Required';
  @Input() public hideRanges = false;
  @Input() public placeholder = '';
  @Input() public showClearButton = true;
  @Input() public showCancelButton = false;
  @Input() public paddingLeft = '';

  @Input() public set isEmpty(empty: boolean) {
    if (empty) {
      this.selected = null;
    }
  }

  @Output() public rangeChanged = new EventEmitter();
  @Output() public startDateChanged = new EventEmitter();
  @Output() public inputEmtied = new EventEmitter();

  constructor(private translationService: TranslateService) {}

  ngOnInit() {
    this.translationService.get('Booking').subscribe((result) => {
      this.settings.daysOfWeek = _.values(result.daysShort);
      this.settings.monthNames = _.values(result.months);
    });

    if (_.isNull(this.rangeFrom) && _.isNull(this.rangeTo)) {
      this.selected = null;
    } else {
      this.selected = {
        startDate: this.prepareDateValue(this.rangeFrom),
        endDate: this.prepareDateValue(this.rangeTo),
      };
    }
    if (this.limitDate) {
      this.setMinMaxDate();
    }
    if (this.backwardDays) {
      this.setBackwardDays(this.backwardDays);
    }

    this.datePicker = new FormControl();
  }

  setBackwardDays(backwardDays) {
    this.maxDate = moment();
    this.minDate = moment().subtract(backwardDays, 'days');
  }

  setMinMaxDate() {
    if (!this.year) {
      this.maxDate = moment().endOf('year');
      this.minDate = moment().startOf('year');
    } else {
      const lastYearDay = this.year + '-12-31';
      const firstYearDay = this.year + '-01-01';
      this.maxDate = moment(lastYearDay);
      this.minDate = moment(firstYearDay);
    }
  }

  onPickerShown() {
    // 24.07.2023: This is a workaround to establish the initial displayed month on the calendar component.
    // Due to the lack of built-in functionality from the ngx-daterangepicker-material library, we've implemented this custom solution.
    // This script is triggered upon calendar opening, and it navigates to the month of the maxDate by default.
    // According to business logic, the maxDate is set to the current date.
    const numMonths = Math.ceil(this.maxDate.diff(this.minDate, 'months', true));
    const calendarNextBtn = document.querySelector('th.next.available') as HTMLElement;

    for (let i = 0; i < numMonths; i++) {
      calendarNextBtn?.click();
    }
  }

  prepareRanges(translationData) {
    this.ranges = {
      [translationData.today]: [moment(), moment()],
      [translationData.yesterday]: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      [translationData.last7days]: [moment().subtract(6, 'days'), moment()],
      [translationData.last30days]: [moment().subtract(29, 'days'), moment()],
      [translationData.thisMonth]: [moment().startOf('month'), moment().endOf('month')],
      [translationData.lastMonth]: [
        moment().subtract(1, 'month').startOf('month'),
        moment().subtract(1, 'month').endOf('month'),
      ],
    };
  }

  dateChanged(event, valid = true, onlyStart = false) {
    const eventBody = {
      isValid: valid,
      date: [
        _.isNull(event) || _.isNull(event.startDate) ? null : event.startDate.startOf('day').unix() * 1000,
        _.isNull(event) || _.isNull(event.endDate) || onlyStart ? null : event.endDate.endOf('day').unix() * 1000,
      ],
    };

    if (onlyStart) {
      this.startDateChanged.emit(eventBody);
    } else {
      this.rangeChanged.emit(eventBody);
    }

    this.validDate = true;
  }

  detectIfEmpty(value: string) {
    if (value === '') this.inputEmtied.emit(true);
  }

  prepareDateValue(value): Moment {
    return _.isNull(value) ? value : moment(parseInt(value));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.rangeFrom && changes.rangeFrom.currentValue) {
      this.selected = {
        startDate: this.prepareDateValue(changes.rangeFrom.currentValue),
        endDate: this.selected ? this.selected.endDate : null,
      };
    }
    if (changes.rangeTo && changes.rangeTo.currentValue) {
      this.selected.endDate = this.prepareDateValue(changes.rangeTo.currentValue);
    }
  }
}
