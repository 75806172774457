import { Injectable } from '@angular/core';
import { StorageService } from '../../../core/storage/storage.service';
import { StorageKeys } from '../../../core/storage/storage.keys';
import { BasicSettingsNetwork } from '../../../core/network/basic-settings.network';
import { SettingsEmitter } from '../../../core/emitters/settings.emitter';
import { BaseSettingsModel } from '../../common/classes/base-settings.model';
import { SettingsResponseInterface } from '../interfaces/settings.interface';
import { Currency, CurrencyStorage } from '../../../shared/troi-money/currency';
import { SettingsModel } from '../models/settings.model';
import { Observable, Subject } from 'rxjs';
import { Routes } from '../enum/routes';

@Injectable()
export class TasksSettingsService extends BaseSettingsModel<SettingsModel> {
  public storageKey = StorageKeys.TASKS_SETTINGS;
  private currencyChanged = new Subject<Currency>();
  private settingsChanged = new Subject<SettingsModel>();
  public _settings: any;

  constructor(
    protected storageService: StorageService,
    public basicSettingsNetwork: BasicSettingsNetwork,
    protected settingsEmitter: SettingsEmitter,
  ) {
    super(storageService, basicSettingsNetwork, settingsEmitter);
  }

  public getSettingsRoute(): string {
    return Routes.SETTINGS;
  }

  public buildSettings(settingsData: SettingsResponseInterface): SettingsModel {
    this._settings = settingsData;
    settingsData.settings.timerecord = settingsData.timerecord;

    const systemCurrency: Currency = this.buildCurrency(settingsData.dropdowns.systemCurrency, settingsData.client);
    let activeCurrency: Currency = { ...systemCurrency };
    const activeCurrencyStorage: CurrencyStorage = this.storageService.getItem(StorageKeys.ACTIVE_CURRENCY);
    const activeCurrencyId = activeCurrencyStorage ? parseInt(activeCurrencyStorage.currency) : 0;
    const currencies = this.buildCurrencies(settingsData.dropdowns.currencies, settingsData.client);
    const findActiveCurrency = currencies.find((currency: Currency) => currency.id === activeCurrencyId);
    activeCurrency = findActiveCurrency ? { ...findActiveCurrency } : activeCurrency;
    this.saveActiveCurrency(activeCurrency);

    const newSettings = new SettingsModel(
      settingsData.client,
      systemCurrency,
      activeCurrency,
      currencies,
      settingsData.settings,
    );

    this.settingsChanged.next(newSettings);

    return newSettings;
  }

  public saveActiveCurrency(activeCurrency: Currency): void {
    super.saveActiveCurrency(activeCurrency);

    if (this.settings) {
      this.settings.activeCurrency = activeCurrency;
    }

    this.currencyChanged.next(activeCurrency);
  }

  public get currencyChangedEmitter(): Observable<Currency> {
    return this.currencyChanged;
  }

  public get settingsChangedEmitter(): Observable<SettingsModel> {
    return this.settingsChanged;
  }
}
