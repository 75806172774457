import {
  Component,
  Input,
  OnInit,
  OnChanges,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
  forwardRef,
  HostListener,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'troi-input-field',
  templateUrl: './troi-input-field.component.html',
  styleUrls: ['./troi-input-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TroiInputFieldComponent),
      multi: true,
    },
  ],
})
export class TroiInputFieldComponent implements OnChanges, OnInit, ControlValueAccessor {
  @ViewChild(DefaultValueAccessor, { static: true }) private valueAccessor: DefaultValueAccessor;
  @ViewChild('input', { static: false, read: ElementRef }) inputElement: ElementRef;

  @Output() enterPressed: EventEmitter<KeyboardEvent> = new EventEmitter();

  @Input() public value: any;

  @Input() public enabled = true;

  @Input() public fieldInvalid = false;

  @Input() public validationEnabled = false;

  @Input() public numbersOnly = false;

  @Input() public placeholder = '';

  @Input() public integersOnly = false;

  @Input() public type = 'text';

  @Input() public height = '40px';

  @Input() public align = 'left';

  @Input() public outline = false;

  @Input() public requiredFieldErrorMessage = 'Form.Error.Required';

  @Input() public rounded = false;

  @Input() public icon: string;

  @Input() public iconSize = '20px';

  @Input() public showClearButton: boolean;

  @Input() public disabled = false;

  @Output() public focusOut = new EventEmitter<boolean>();

  public innerValue: string;

  onChange: any = () => {};
  onTouch: any = () => {};

  constructor(private cdRef: ChangeDetectorRef, public element: ElementRef) {}

  writeValue(obj: any) {
    this.valueAccessor.writeValue(obj);
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
    this.valueAccessor.registerOnChange(fn);
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
    this.valueAccessor.registerOnTouched(fn);
  }

  setDisabledState(isDisabled: boolean) {
    this.valueAccessor.setDisabledState(isDisabled);
  }

  ngOnInit() {
    if (this.value) {
      this.valueAccessor.writeValue(this.value);
    }
    this.innerValue = this.value;
  }

  ngOnChanges(changes) {
    if (changes.value) {
      this.innerValue = this.value;
      this.writeValue(this.value);
      this.cdRef.detectChanges();
    }
  }

  @HostListener('focusout')
  focusout() {
    this.focusOut.emit(true);

    if (this.value) {
      this.valueAccessor.writeValue(this.value);
    }
  }

  filterContent(event) {
    const charCode = event.which ? event.which : event.keyCode;
    const enterKeyCode = 13;
    if (charCode === enterKeyCode) {
      this.enterPressed.emit();
    }
    if (this.integersOnly) {
      return !(this.numbersOnly && charCode > 31 && (charCode < 48 || charCode > 57));
    }
    return (
      charCode === 44 ||
      charCode === 46 ||
      charCode === 45 ||
      !(this.numbersOnly && charCode > 31 && (charCode < 48 || charCode > 57))
    );
  }

  clearValue() {
    this.innerValue = '';
    this.writeValue('');
    this.onChange('');
    this.onTouch('');
    setTimeout(() => {
      this.element.nativeElement.querySelector('input').focus();
    }, 0);
  }

  setFocusOnInput() {
    this.inputElement.nativeElement.focus();
  }
}
