<div class="calc-pos-content">
  <div class="calc-pos-content__header">
    <div class="calc-pos-content__header--first-row">
      <troi-btn [outline]="true" (click)="onBackClick()"> {{ 'Main.Back' | translate }} </troi-btn>
      <span class="calc-pos-content__header--subtitle"
        >{{
          'Tasks.labels.createTaskModal.calcPosition.subtitles.addCalcPosition' | translate
        }}:</span
      >
    </div>

    <troi-filter *ngIf="calcPositions" class="calc-pos-content__header--search">
      <troi-icon icon="icon-loop-with-arrow-down"></troi-icon>
      <input
        type="text"
        name="search"
        [(ngModel)]="calcPosSearch"
        placeholder="{{ 'Booking.labels.search' | translate }}"
        (onInput)="searchCalcPos()"
      />
    </troi-filter>
  </div>
  <div class="calc-pos-content__content">
    <div class="calc-pos-content__content--selection">
      <div class="calc-pos-content__content--assignment">
        <span class="calc-pos-content__content--subtitle">{{
          'Tasks.labels.createTaskModal.calcPosition.subtitles.assignProject' | translate
        }}</span>

        <tnd-search-select
          #clientSelect
          [loading]="!loadedClients"
          [transparentBackground]="true"
          class="calc-pos-content__search-select"
          (searchStringChanged)="onClientSearchStringChanged($event)"
          [searchResult]="resultClients"
          labelFieldName1="name"
          valueFieldName="id"
          [placeholder]="'Common.labels.client' | translate"
          [icon]="'icon-persons-with-enter'"
          [hideCloseIcon]="isProjectContext"
          (valueSelected)="onClientSelected($event)"
        ></tnd-search-select>

        <tnd-search-select
          #customerSelect
          [transparentBackground]="true"
          class="calc-pos-content__search-select"
          (searchStringChanged)="onCustomerSearchStringChanged($event)"
          [searchResult]="resultCustomers"
          [placeholder]="'Common.labels.customer' | translate"
          placeholder="{{ 'Common.labels.customer' | translate }}"
          labelFieldName1="name"
          valueFieldName="id"
          [icon]="'icon-person-with-list'"
          (valueSelected)="onCustomerSelected($event)"
          [disabled]="!calculationPosition.client.mandant || isProjectContext"
          [hideCloseIcon]="!calculationPosition.client.mandant || isProjectContext"
          [loading]="!loadedCustomers"
        ></tnd-search-select>

        <tnd-search-select
          #projectSelect
          [loading]="!loadedProjects"
          [transparentBackground]="true"
          class="calc-pos-content__search-select"
          (searchStringChanged)="onProjectSearchStringChanged($event)"
          [searchResult]="resultProjects"
          [placeholder]="'Common.labels.project' | translate"
          labelFieldName1="name"
          valueFieldName="id"
          [icon]="'icon-folder'"
          (valueSelected)="onProjectSelected($event)"
          [disabled]="!calculationPosition.client.mandant || !calculationPosition.client"
          [hideCloseIcon]="
            !calculationPosition.client.mandant || !calculationPosition.client || isProjectContext
          "
        ></tnd-search-select>
      </div>
      <div class="calc-pos-content__subprojects">
        <span *ngIf="subprojects" class="calc-pos-content__content--subtitle">{{
          'Tasks.labels.createTaskModal.calcPosition.subtitles.subprojects' | translate
        }}</span>
        <span class="calc-pos-content__not-found" *ngIf="subprojects && subprojects.length === 0">{{
          'Tasks.labels.createTaskModal.calcPosition.subtitles.noSubProject' | translate
        }}</span>

        <div
          *ngIf="subprojects && subprojects.length > 0"
          class="calc-pos-content__subprojects--scrollable"
        >
          <div
            [ngClass]="{
              'calc-pos-content__subprojects--selected':
                calculationPosition.subproject.id === subproject.id
            }"
            class="calc-pos-content__subprojects--subproject"
            (click)="onSubprojectSelect(subproject)"
            *ngFor="let subproject of subprojects"
          >
            {{ subproject.name.en }}
            <troi-icon
              *ngIf="task.subproject === subproject"
              [icon]="'icon-arrow-head-right'"
            ></troi-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="calc-pos-content__content--result">
      <span class="calc-pos-content__content--subtitle">{{
        'Tasks.labels.createTaskModal.calcPosition.subtitles.calcPositions' | translate
      }}</span>

      <span
        class="calc-pos-content__not-found"
        *ngIf="calcPositions && calcPositions.length === 0"
        >{{
          'Tasks.labels.createTaskModal.calcPosition.subtitles.noCalcPosition' | translate
        }}</span
      >
      <div class="calc-pos-content__content--scrollable">
        <ng-container
          *ngIf="
            calculationPosition.client &&
            calculationPosition.client.mandant &&
            calculationPosition.project
          "
        >
          <troi-task-calc-pos
            *ngFor="let calcPosition of searchCalcPos()"
            [selectedCalcualtionPosition]="calculationPosition"
            [calcPosition]="calcPosition"
            [task]="task"
          ></troi-task-calc-pos>
        </ng-container>
      </div>
    </div>
  </div>
</div>
