import { Injectable, OnDestroy } from '@angular/core';
import { FilterElementInterface } from '../../../shared/troi-filter-with-modal/filter-element.interface';
import { FilterTypeEnum } from '../../../shared/troi-filter-with-modal/filter-type.enum';
import { FiltersServiceInterface } from '../../../core/interfaces/filters-service.interface';
import { BasicFiltersService } from '../../../core/services/basic-filters.service';
import { FooterSettingsService } from '../../../core/services/footer-settings.service';
import { ParentWindowRef } from '../../../core/services/parent-window-ref.service';
import { StorageService } from '../../../core/storage/storage.service';
import { CommonFiltersService } from '../../../modules/common/services/common-filters.service';
import { FiltersInterface } from '../../../shared/troi-data-listing-filters/filters.interface';
import { TabInterface } from '../../../shared/troi-data-listing-tabs/tab.interface';
import { TroiDropdownListModel } from '../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TroiFilterSetsNetworkService } from '../../../shared/troi-filter-sets/network/troi-filter-sets.network';
import { TasksSettingsService } from './tasks-settings.service';
import { DataTypeEnum } from '../../../core/enums/data-type.enum';
import { TroiDropdownSelectConfigInterface } from '../../../shared/troi-dropdown-select/interfaces/troi-dropdown-select-config.interface';
import { Subject, Subscription } from 'rxjs';
import { Priority } from '../enum/priority';
import { TasksService } from '../network/tasks.service';

import { FilterSetModel } from '../../../shared/troi-filter-sets/models/filter-set.model';
import { FilterSetTypeEnum } from '../../../shared/troi-filter-sets/enums/filter-set-type.enum';
import { Routes } from '../enum/routes';
import { HttpHeaders } from '@angular/common/http';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { FilterSetLoadedInterface } from '../../../shared/troi-filter-sets/interfaces/filter-set-loaded.interface';
import { FilterElementDefaultStateInterface } from '../../../shared/troi-filter-with-modal/filter-element-default-state.interface';
import { DropdownsService } from '../../common/services/dropdowns.service';
import { TasksHelperService } from './helper.service';

@Injectable()
export class TaskFiltersService extends CommonFiltersService implements FiltersServiceInterface, OnDestroy {
  public haveFiltersChanged = new Subject<FiltersInterface>();
  public areFiltersEdited = false;
  public reloadFilterChips: Subject<FilterElementInterface[]> = new Subject<FilterElementInterface[]>();
  public resetQuickFilters: Subject<boolean> = new Subject<boolean>();
  public customersDropdown: TroiDropdownSelectConfigInterface;
  public secondDropdown: TroiDropdownSelectConfigInterface;
  public thirdDropdown: TroiDropdownSelectConfigInterface;
  public reloadEmployeeData: number;
  public reloadProjectData: number;
  private clientsLoadedSubscription: Subscription;

  private subscriptions: Subscription = new Subscription();
  public isProjectContext = false;
  public isResourcesContext = false;
  private projectId: string;
  public projecttaskview: string;

  constructor(
    protected localStorage: StorageService,
    public basicFiltersService: BasicFiltersService,
    public settings: TasksSettingsService,
    public parentWindowRef: ParentWindowRef,
    public footerSettingsService: FooterSettingsService,
    protected filterSetsNetworkService: TroiFilterSetsNetworkService,
    private taskService: TasksService,
    private dropdownService: DropdownsService,
    private helperService: TasksHelperService,
  ) {
    super(basicFiltersService, parentWindowRef, footerSettingsService, localStorage, filterSetsNetworkService);

    localStorage.removeItem('actualFilters');
    this.getProjectContext();
    this.getResourcesContext();

    const queryParams = new URLSearchParams(window.parent.location.search);
    const projectId = queryParams.get('projectId');
    if (projectId) this.projectId = projectId;

    const projecttaskview = queryParams.get('projecttaskview');
    if (projecttaskview) this.projecttaskview = projecttaskview;

    this.actualFilters = this.defaultFilterValues();
    this.pageUrl = this.parentWindowRef.nativeWindow.location;
    this.basicFiltersService.clientsLoaded.subscribe(() => {
      this.actualFilters.dropdownFirst = this.getDefaultClientId();
      this.defaultClientAssigned.next(true);
    });

    this.generateSecondDropdown();
    this.generateThirdDropdown();

    this.filterSetsNetworkService.route = Routes.FILTER_SETS;
    this.filterSetsNetworkService.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');

    this.loadFilterSets();
    this.subscribeToClientsLoaded();
    this.subscribeToFilterSetsLoaded();
    this.subscribeToFilterSetSelected();
  }

  private subscribeToClientsLoaded(): void {
    this.clientsLoadedSubscription = this.basicFiltersService.clientsLoaded.subscribe(() => {
      this.actualFilters.dropdownFirst = this.getDefaultClientId();
      this.loadFilterSets();
    });
  }

  private getResourcesContext() {
    this.subscriptions.add(
      this.helperService.isResourcesContext.subscribe((value) => {
        if (value) {
          this.isResourcesContext = value;
          this.actualFilters = this.defaultFilterValues();
        }
      }),
    );
  }

  private getProjectContext() {
    this.subscriptions.add(
      this.helperService.isProjectContext.subscribe((value) => {
        if (value) {
          this.isProjectContext = true;
          this.actualFilters = this.defaultFilterValues();
        }
      }),
    );
  }

  get predefinedFilterSets(): FilterSetModel[] {
    return [
      {
        id: 'predefined-all',
        name: 'Tasks.labels.filterSets.default',
        value: `currentPage=1&pageSize=50&client=${this.getDefaultClientId()}`,
        type: FilterSetTypeEnum.ALL,
        isDefault: true,
        filters: new FiltersInterface(),
      },
    ];
  }

  private subscribeToFilterSetsLoaded(): void {
    this.subscriptions.add(
      this.filterSetsLoaded.subscribe((loadedFilterState: FilterSetLoadedInterface) => {
        if (loadedFilterState.initialLoad) {
          this.applyFiltersFromUrl(this.mergeFilterSetWithCurUrlParams(loadedFilterState.filterSet.value));
        } else {
          this.applyFiltersFromUrl(loadedFilterState.filterSet.value);
        }
        this.assignActualFiltersToFilterSet(loadedFilterState.filterSet.id);
        this.defaultClientAssigned.next(true);
      }),
    );
  }

  private subscribeToFilterSetSelected(): void {
    this.subscriptions.add(
      this.filterSetSelected.subscribe((filterSet: FilterSetModel) => {
        // empty previous filter params
        this.actualFilters = this.defaultFilterValues();
        this.applyFiltersFromUrl(filterSet.value);
        this.reloadProjectData = Math.random();
        this.reloadEmployeeData = Math.random();
        this.assignActualFiltersToFilterSet(filterSet.id);
      }),
    );
  }

  private mergeFilterSetWithCurUrlParams(filterSetValue: string): string {
    const currentUrlSearchParams = new URL(this.pageUrl.href).searchParams;

    // ToDo: replace URL after linking to correct menu page
    const loadedFilterSetUrlSearchParams = new URL(
      this.pageUrl.href.split('?')[0] + '?page=projecttask_listview&' + filterSetValue,
    ).searchParams;

    // create a copy of URL-params for merging them later
    const mergedUrlSearchParams = new URL(
      this.pageUrl.href.split('?')[0] + '?page=projecttask_listview&' + filterSetValue,
    ).searchParams;

    // creating all filter form ids in one default array (within main view projectId is already a header dropdown filter)
    const defaultFiltersState = this.getDefaultFiltersState(this.defaultFilters());

    if (!defaultFiltersState.find((x) => x.formName === 'searchPhrase')) {
      defaultFiltersState.push({
        formName: 'searchPhrase',
        defaultValue: '',
      });
    }
    if (!defaultFiltersState.find((x) => x.formName === 'client')) {
      defaultFiltersState.push({
        formName: 'client',
        defaultValue: '',
      });
    }
    if (!defaultFiltersState.find((x) => x.formName === 'projectId')) {
      defaultFiltersState.push({
        formName: 'projectId',
        defaultValue: this.isProjectContext ? this.projectId : '',
      });
    }
    if (!defaultFiltersState.find((x) => x.formName === 'assignee')) {
      defaultFiltersState.push({
        formName: 'assignee',
        defaultValue: '',
      });
    }

    defaultFiltersState.forEach((filterElemDefaultState: FilterElementDefaultStateInterface) => {
      // if default filter key is in current url and its value is not the same as the loaded filter set value (same key)
      // and also not the default value, it gets added to the merged param array (URL params are dominant)
      if (
        currentUrlSearchParams.has(filterElemDefaultState.formName) &&
        currentUrlSearchParams.get(filterElemDefaultState.formName) !==
          loadedFilterSetUrlSearchParams.get(filterElemDefaultState.formName) &&
        currentUrlSearchParams.get(filterElemDefaultState.formName) !== filterElemDefaultState.defaultValue
      ) {
        mergedUrlSearchParams.set(
          filterElemDefaultState.formName,
          currentUrlSearchParams.get(filterElemDefaultState.formName),
        );
      }
    });

    // if url contains other GET-params as the loaded filter set, the current filters can be stored by the user

    if (mergedUrlSearchParams.toString() !== loadedFilterSetUrlSearchParams.toString()) {
      this.areFiltersEdited = true;
    }

    return mergedUrlSearchParams.toString();
  }

  private applyFiltersFromUrl(explicitParams?: string): void {
    this.presetActualFilters(explicitParams);
    this.fetchSelectedData(explicitParams);
  }

  private presetActualFilters(explicitParams?: string): void {
    const tempUrl = explicitParams
      ? new URL(this.pageUrl.href.split('?')[0] + '?' + explicitParams)
      : new URL(this.pageUrl.href);

    this.actualFilters = this.applyCommonUrlFilters(tempUrl, this.actualFilters, true, true);

    // initializing dropdown first filter value
    const client = tempUrl.searchParams.get('client');
    if (client) {
      this.actualFilters.dropdownFirst = +client;
    } else {
      this.actualFilters.dropdownFirst = 1;
    }

    // initializing dropdown second filter value
    const projectId = tempUrl.searchParams.get('projectId');
    const priority = tempUrl.searchParams.get('priority');
    if (projectId && !this.isProjectContext) {
      this.actualFilters.dropdownSecond = +projectId;
    } else if (priority && this.isProjectContext) {
      this.actualFilters.dropdownSecond = +priority;
    } else {
      this.actualFilters.dropdownSecond = [];
    }

    // initializing dropdown third filter value
    const assignee = tempUrl.searchParams.get('assignee');
    if (assignee)
      this.actualFilters.dropdownThird =
        assignee === '*' ? '' : assignee.split(',').map((assigneeId: string) => parseInt(assigneeId, 10));
    else this.actualFilters.dropdownThird = [];

    // project id has to be synced with filter set option
    if (!this.isProjectContext && this.actualFilters.filters[this.getFilterParamIndex('projectId')]?.value) {
      this.actualFilters.filters[this.getFilterParamIndex('projectId')].value = this.actualFilters.dropdownSecond;
    } else if (this.actualFilters.filters[this.getFilterParamIndex('priority')]?.value) {
      this.actualFilters.filters[this.getFilterParamIndex('priority')].value = this.actualFilters.dropdownSecond;
    }
    // assignee value has to be synced with filter set option
    if (this.actualFilters.filters[this.getFilterParamIndex('assignee')]?.value) {
      this.actualFilters.filters[this.getFilterParamIndex('assignee')].value = this.actualFilters.dropdownThird;
    }
    // if status is comma-separated, it is just the default setting and can be ignored
    if (tempUrl.searchParams.has('status') && !tempUrl.searchParams.get('status').includes(','))
      this.setFilterFromUrl(tempUrl, 'status', false, true);
    // set remaining filter set dropdown values

    this.setDateFilterFromUrl(tempUrl, 'timerange');
    this.setDateFilterFromUrl(tempUrl, 'creationDate');
    this.setFilterFromUrl(tempUrl, 'creator');
    this.setFilterFromUrl(tempUrl, 'priority', false, true);
    this.setFilterFromUrl(tempUrl, 'tag');
    this.setFilterFromUrl(tempUrl, 'projectId');
  }

  private fetchSelectedData(explicitParams?: string): void {
    this.restoreActualFiltersFromLS('actualFilters');

    const tempUrl = explicitParams
      ? new URL(this.pageUrl.href.split('?')[0] + '?' + explicitParams)
      : new URL(this.pageUrl.href);

    const selectedStateValue =
      this.getFilterValueByDataType('TASK_STATES') || tempUrl.searchParams.get('status') || undefined;

    const selectedProjectValue =
      this.getFilterValueByDataType('TASK_PROJECTS') || tempUrl.searchParams.get('projectId') || undefined;

    const selectedAssigneeValue =
      this.getFilterValueByDataType('EMPLOYEES') || tempUrl.searchParams.get('assignee') || undefined;

    const selectedTagValue = this.getFilterValueByDataType('TASK_TAGS') || tempUrl.searchParams.get('tag') || undefined;

    const selectedCreatorValue = tempUrl.searchParams.get('creator') || undefined;

    const stateOptions = this.dropdownService.buildOptionList(
      this.taskService.statusOptions,
      DataTypeEnum.TASK_STATES,
      true,
    );

    const projectOptions = this.dropdownService.buildOptionList(
      this.taskService.projectOptions,
      DataTypeEnum.TASK_PROJECTS,
    );

    const assigneeOptions = this.dropdownService.buildOptionList(
      this.taskService.employeeOptions,
      DataTypeEnum.EMPLOYEES,
    );

    const tagOptions = this.dropdownService.buildOptionList(this.taskService.tagOptions, DataTypeEnum.TASK_TAGS);

    /*     this.actualFilters.filters[this.getFilterParamIndex('tag')].preloadedOptions = tagOptions; */

    if (selectedTagValue !== undefined)
      this.setDropdownFilterValue(
        'tag',
        tagOptions,
        selectedTagValue.split(',').map((x) => parseInt(x, 10)),
      );

    // comma-separated values indicate default values (in this case only the preloaded options need to be assigned for translations)
    if (selectedStateValue !== undefined && !selectedStateValue.includes(','))
      this.setDropdownFilterValue('status', stateOptions, +selectedStateValue);
    else this.actualFilters.filters[this.getFilterParamIndex('status')].preloadedOptions = stateOptions;

    if (!this.isProjectContext && selectedProjectValue !== undefined)
      this.setDropdownFilterValue('projectId', projectOptions, +selectedProjectValue);

    if (selectedAssigneeValue !== undefined)
      this.setDropdownFilterValue(
        'assignee',
        assigneeOptions,
        selectedAssigneeValue.split(',').map((x) => parseInt(x, 10)),
      );

    if (selectedCreatorValue !== undefined)
      this.setDropdownFilterValue('creator', assigneeOptions, +selectedCreatorValue);

    this.haveFiltersChanged.next(this.actualFilters);
    this.reloadFilterChips.next(this.actualFilters.filters);
  }

  private getFilterValueByDataType(dataType: string) {
    const filterValue = this.actualFilters.filters.find((filter) => filter.dataType === dataType)?.value;
    if (filterValue && typeof filterValue === 'object') {
      return filterValue.map((value) => value.value || value).join(',');
    }
    if (filterValue && typeof filterValue === 'string') {
      return filterValue;
    }
    return '';
  }

  private setDropdownFilterValue(
    filterName: string,
    options: TroiDropdownListModel[],
    selectedValue: number | number[],
  ): void {
    const filter = this.actualFilters.filters[this.getFilterParamIndex(filterName)];

    if (filter?.value && filter?.preloadedOptions) {
      filter.value = selectedValue;
      filter.preloadedOptions = options;
    }
  }

  private restoreActualFiltersFromLS(key: string): void {
    const returnFromTaskBaseDataFilters = localStorage.getItem(key);

    // ensure that stored item is deleted after it was read (clean it for other modules)
    if (returnFromTaskBaseDataFilters) {
      this.actualFilters = JSON.parse(returnFromTaskBaseDataFilters);
    }
    localStorage.removeItem(key);
  }

  resetFilters(clientId?: number) {
    this.actualFilters.filters = this.defaultFilterValues().filters;
    this.actualFilters.search = this.defaultFilterValues().search;
    this.actualFilters.dropdownFirst = clientId || this.getDefaultClientId();
    this.actualFilters.dropdownSecond = this.defaultFilterValues().dropdownSecond;
    this.actualFilters.dropdownThird = this.defaultFilterValues().dropdownThird;
    this.resetQuickFilters.next(true);
    this.resetPagination();
    this.areFiltersEdited = false;
  }

  defaultFilterValues(): FiltersInterface {
    return {
      filters: this.defaultFilters(),
      search: '',
      dropdownFirst: null,
      dropdownSecond: '',
      dropdownThird: [],
      currentPage: 1,
      pageSize: this.getPageSize(),
    };
  }

  private defaultFilters(): FilterElementInterface[] {
    let defaultFilters = [
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Tasks.labels.filters.status.label',
        formName: 'status',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Tasks.labels.filters.status.label',
            value: undefined,
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.TASK_STATES,
        dropdownData: [],
        preloadedOptions: [
          {
            label: 'Tasks.labels.filters.status.allSelected',
            value: '',
            active: true,
          },
        ],
        withSearch: false,
      },
      {
        type: FilterTypeEnum.RANGE_DATE,
        label: 'Tasks.labels.filters.timerange.label',
        formName: 'timerange',
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'Tasks.labels.filters.timerange.chips.from',
            value: undefined,
          },
          {
            label: 'Tasks.labels.filters.timerange.chips.to',
            value: undefined,
          },
        ],
      },
      {
        type: FilterTypeEnum.RANGE_DATE,
        label: 'Tasks.labels.filters.creationDate.label',
        formName: 'creationDate',
        value: [null, null],
        defaultValue: [null, null],
        chips: [
          {
            label: 'Tasks.labels.filters.creationDate.chips.createFrom',
            value: undefined,
          },
          {
            label: 'Tasks.labels.filters.creationDate.chips.createTo',
            value: undefined,
          },
        ],
      },

      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Tasks.labels.filters.creator.label',
        formName: 'creator',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Tasks.labels.filters.creator.chips.creator',
            value: '',
          },
        ],
        dataType: DataTypeEnum.EMPLOYEES,
        dropdownData: [],
        preloadedOptions: [
          {
            label: 'Tasks.labels.anyCreator',
            value: '',
            active: true,
          },
        ],
      },
      {
        type: FilterTypeEnum.DROPDOWN,
        label: 'Tasks.labels.filters.priority.label',
        formName: 'priority',
        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Tasks.labels.filters.priority.label',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dropdownData: this.generatePrioOptions(true),
        preloadedOptions: [],
      },
      {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Tasks.labels.filters.tags.label',
        formName: 'tag',
        value: '',
        defaultValue: '',
        dataType: DataTypeEnum.TASK_TAGS,
        chips: [
          {
            label: 'Tasks.labels.filters.tags.label',
            value: '',
          },
        ],
        dropdownData: [],
        preloadedOptions: [],
        withSearch: false,
        dropdownMultipleSelect: true,
        dropdownReturnSelectedObject: false,
      },
    ];
    if (!this.isProjectContext && !this.isResourcesContext) {
      const index = defaultFilters.length - 1;
      const projectFilter = {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Tasks.labels.filters.project.label',
        formName: 'projectId',

        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Tasks.labels.filters.project.label',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.TASK_PROJECTS,
        dropdownData: [],
        preloadedOptions: [
          {
            label: 'Tasks.labels.filters.project.allSelected',
            value: '',
            active: true,
          },
        ],
      };
      defaultFilters.splice(index, 0, projectFilter);
    }

    if (this.isResourcesContext) {
      const index = defaultFilters.length - 1;
      const customerFilter = {
        type: FilterTypeEnum.DROPDOWN_LAZY,
        label: 'Customer-I18n',
        formName: 'customer',

        value: '',
        defaultValue: '',
        chips: [
          {
            label: 'Chip-Customer-I18n',
            value: '',
            valueIsTranslatable: true,
          },
        ],
        dataType: DataTypeEnum.CUSTOMERS,
        dropdownData: [],
        preloadedOptions: [
          {
            label: 'All-Customer-I18n',
            value: '',
            active: true,
          },
        ],
      };

      defaultFilters.splice(index, 0, customerFilter);

      defaultFilters = defaultFilters.filter(
        (el) =>
          el.formName !== 'creator' &&
          el.formName !== 'creationDate' &&
          el.formName !== 'priority' &&
          el.formName !== 'customer' &&
          el.formName !== 'assignee' &&
          el.formName !== 'tag',
      );
    }

    return defaultFilters;
  }

  putFiltersInUrl(apiUrl?: string): string {
    let filterUrl = `client=${this.actualFilters.dropdownFirst}&`;

    if (this.isResourcesContext && typeof this.actualFilters.dropdownSecond === 'number') {
      filterUrl += `projectId=${this.actualFilters.dropdownSecond}&`;
    } else if (this.isResourcesContext && (this.actualFilters.dropdownSecond as number[]).length > 0) {
      filterUrl += `projectId=${(this.actualFilters.dropdownSecond as number[]).join(',')}&`;
    }
    if (
      this.actualFilters.dropdownSecond !== null &&
      typeof this.actualFilters.dropdownSecond === 'number' &&
      !this.isResourcesContext
    ) {
      filterUrl += this.isProjectContext
        ? `priority=${this.actualFilters.dropdownSecond}&`
        : `projectId=${this.actualFilters.dropdownSecond}&`;
    }
    if (this.actualFilters.dropdownThird !== null && (this.actualFilters.dropdownThird as number[]).length > 0) {
      filterUrl += `assignee=${this.actualFilters.dropdownThird}&`;
    }
    if (this.actualFilters.search && this.actualFilters.search !== '') {
      filterUrl += `searchPhrase=${this.actualFilters.search}&`;
    }

    this.actualFilters.filters.forEach((filter) => {
      const { formName, value } = filter;
      switch (formName) {
        case 'status':
          filterUrl += value !== '' && !isNaN(value) ? `status=${value}&` : this.isResourcesContext ? `status=*&` : '';
          break;
        case 'timerange':
          if (value[0]) {
            filterUrl += `timerangeFrom=${value[0]}&`;
          }
          if (value[1]) {
            filterUrl += `timerangeTo=${value[1]}&`;
          }
          break;

        case 'creationDate':
          if (value[0]) {
            filterUrl += `creationDateFrom=${value[0]}&`;
          }
          if (value[1]) {
            filterUrl += `creationDateTo=${value[1]}&`;
          }
          break;

        case 'creator':
          if (value !== '') {
            filterUrl += `creator=${value}&`;
          }
          break;

        case 'priority':
          if (value !== '') {
            filterUrl += `priority=${value}&`;
          }
          break;

        case 'tag':
          if (value !== '') {
            filterUrl += `tag=${value}&`;
          }
          break;

        case 'customer':
          if (value !== '' && this.isResourcesContext) {
            filterUrl += `customer=${value}&`;
          }
          break;

        default:
          break;
      }
    });

    if (this.isProjectContext) {
      filterUrl += `projectContext=${this.isProjectContext}&`;
      filterUrl += `projectId=${this.projectId}&`;
      filterUrl += `project=${this.projectId}&`;
      if (this.projecttaskview) {
        filterUrl += `projecttaskview=${this.projecttaskview}&`;
      }
    }

    if (this.isResourcesContext) {
      const calendarScaleUnit = this.actualFilters.calendarScaleUnit;
      filterUrl += `view=${calendarScaleUnit ? calendarScaleUnit : 'day'}&`;

      let isWorkLoadInPercent = this.actualFilters.isWorkLoadInPercent;
      if (isWorkLoadInPercent === undefined) {
        isWorkLoadInPercent = true;
      }
      filterUrl += `percent=${isWorkLoadInPercent}`;
    }

    return apiUrl + '?' + this.putSortingPaginationInUrl(filterUrl);
  }

  private putSortingPaginationInUrl(filterUrl: string): string {
    if (!this.isResourcesContext) {
      // page size and curPage is always set
      filterUrl += `pageSize=${this.getPageSize()}&currentPage=${this.actualFilters.currentPage}&`;
      // sorting is optional
      if (this.actualFilters.sortBy !== 'undefined') {
        filterUrl += `column=${this.actualFilters.sortBy}&dir=${this.actualFilters.sortingDir}`;
      }
    }

    this.updateBrowserUrl(filterUrl);
    return filterUrl;
  }

  private updateBrowserUrl(filterUrl: string) {
    // updates php url based on set filters
    this.parentWindowRef.nativeHistory.pushState('', '', this.pageUrl.href.split('&')[0] + '&' + filterUrl);
  }

  /* TODO: This function should be refactored for better readability and scalability */
  public syncDoubleFilterOptions(filterKey: string): boolean {
    const tempUrl = new URL(this.pageUrl.href);
    const oldValue = tempUrl.searchParams.get(filterKey)
      ? tempUrl.searchParams
          .get(filterKey)
          .split(',')
          .map((x) => parseInt(x, 10))
      : [];

    if (!this.isResourcesContext) {
      if (filterKey === 'projectId' && !this.isProjectContext) {
        if (this.actualFilters.dropdownSecond.toString() === this.actualFilters.filters[5].value.toString())
          return false;
        else if (!this.isProjectContext && this.actualFilters.dropdownSecond.toString() !== oldValue.toString())
          this.actualFilters.filters[4].value = this.actualFilters.dropdownSecond;
        else this.actualFilters.dropdownSecond = this.actualFilters.filters[5].value;
      } else if (filterKey === 'priority' && this.isProjectContext) {
        if (this.actualFilters.dropdownSecond.toString() === this.actualFilters.filters[4].value.toString())
          return false;
        else if (this.actualFilters.dropdownSecond.toString() !== oldValue.toString()) {
          this.actualFilters.filters[4].value = this.actualFilters.dropdownSecond;
        } else this.actualFilters.dropdownSecond = this.actualFilters.filters[4].value;
      }
    }

    return true;
  }

  generateSecondDropdown() {
    this.secondDropdown = {
      ...this.secondDropdown,
      values: !this.isProjectContext
        ? [...this.dropdownService.buildOptionList(this.taskService.projectOptions, DataTypeEnum.TASK_PROJECTS)]
        : this.generatePrioOptions(),
      search: !this.isProjectContext ? true : false,
      multiple: this.isResourcesContext,
      placeholder: !this.isProjectContext
        ? 'Tasks.labels.filters.project.allSelected'
        : 'Tasks.labels.filters.priority.label',
      disable: false,
    };
  }

  generateThirdDropdown() {
    this.thirdDropdown = {
      ...this.thirdDropdown,
      values: [...this.dropdownService.buildOptionList(this.taskService.employeeOptions, DataTypeEnum.EMPLOYEES)],
      search: true,
      multiple: true,
      selectAllOption: true,
      selectAllOptionLabel: 'Tasks.labels.filters.employee.allSelected',
      placeholder: 'Tasks.labels.filters.employee.label',
      disable: false,
    };

    this.reloadEmployeeData = Math.random();
  }
  public generatePrioOptions(selectAll?: boolean): TroiDropdownListModel[] {
    const priorities = [
      {
        active: true,
        label: 'Tasks.priorities.all',
        value: '',
      },
      {
        active: true,
        label: 'Tasks.priorities.low',
        value: Priority.LOW,
      },
      {
        active: true,
        label: 'Tasks.priorities.medium',
        value: Priority.MEDIUM,
      },
      {
        active: true,
        label: 'Tasks.priorities.high',
        value: Priority.HIGH,
      },
    ];

    return selectAll ? priorities : priorities.slice(1);
  }

  generateTabs(): TabInterface[] {
    return undefined;
  }

  getSelectedYear(): string {
    return this.actualFilters.dropdownSecond.toString();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
