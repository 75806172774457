import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { FilterChipsInterface } from './filter-chips.interface';
import { FilterElementInterface } from '../troi-filter-with-modal/filter-element.interface';
import { FilterTypeEnum } from '../troi-filter-with-modal/filter-type.enum';
import { Money } from '../troi-money/money';
import * as moment from 'moment';
import { TroiDropdownListModel } from '../troi-dropdown-list/models/troi-dropdown-list.model';
import { CommonFiltersService } from '../../modules/common/services/common-filters.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'troi-filter-chips',
  templateUrl: './troi-filter-chips.component.html',
  styleUrls: ['./troi-filter-chips.component.scss'],
})
export class TroiFilterChipsComponent implements OnInit, OnChanges, OnDestroy {
  private _filters: FilterElementInterface[];
  public chips: FilterChipsInterface[] = [];
  public areFilterChipsUnfolded = false;
  public areChipsContainerExtendable = false;
  private chipsWidth = 0;
  private _dateFormat: string;
  private reloadFilterChipsSubscription: Subscription;

  @Input() filters: FilterElementInterface[] = [];
  @Input() width = 'initial';
  @Input() reloadFilterChips: Subject<FilterElementInterface[]>;

  @Input() set dateFormat(dateFormat: string) {
    this._dateFormat = dateFormat || 'DD-MM-YYYY';
  }

  get dateFormat(): string {
    return this._dateFormat;
  }

  @Output() filterClosed = new EventEmitter();

  @ViewChild('mainContainer') mainContainer: ElementRef;
  @ViewChild('chipsContainer') chipsContainer: ElementRef;

  private static isSingleFilterChipDate(filter: FilterElementInterface): boolean {
    return [
      filter.type === FilterTypeEnum.RANGE_DATE,
      filter.value.length === 2,
      filter.chips.length === 1,
      !_.isNull(filter.value[0]),
      !_.isNull(filter.value[1]),
    ].every((condition: boolean) => condition);
  }

  @HostListener('window:resize', ['$event'])
  private onWindowResize(): void {
    this.checkFoldStatus(this.chipsWidth);
  }

  @HostListener('document:click', ['$event'])
  private onDocumentClick(event): void {
    if (
      !this.componentRef.nativeElement.contains(event.target) &&
      event.target.className !== 'icon-close' &&
      event.target.className !== 'chips__item__close'
    ) {
      this.areFilterChipsUnfolded = false;
    }
  }

  constructor(private componentRef: ElementRef) {}

  ngOnInit() {
    this.updateFilters(this.filters);

    this.reloadFilterChipsSubscription = this.reloadFilterChips?.subscribe((filters: FilterElementInterface[]) => {
      this.updateFilters(filters);
    });
  }

  emitFilterClosed(filterChip: FilterChipsInterface) {
    this.filterClosed.emit(filterChip);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters) {
      if (JSON.stringify(changes.filters.currentValue) !== JSON.stringify(this._filters)) {
        this.updateFilters(changes.filters.currentValue);
      }
    }
  }

  private updateFilters(filters: FilterElementInterface[]) {
    this._filters = [...filters];
    this.chips = this.prepareFilterChips(this._filters);

    setTimeout(() => {
      this.checkFoldStatus();
    }, 0);
  }

  private checkFoldStatus(chipsWidth?: number): void {
    if (!this.mainContainer || !this.chipsContainer) {
      return;
    }

    chipsWidth =
      chipsWidth !== undefined
        ? chipsWidth
        : [...this.chipsContainer?.nativeElement.children].reduce(
            (acc: number, childElem: HTMLElement) => acc + childElem.getBoundingClientRect().width + 10,
            0,
          );

    this.chipsWidth = chipsWidth;
    this.areChipsContainerExtendable =
      chipsWidth >= this.mainContainer?.nativeElement.getBoundingClientRect().width - 90;

    if (!this.areChipsContainerExtendable) {
      this.areFilterChipsUnfolded = false;
    }
  }

  get mainContainerWidth(): string {
    return this.areFilterChipsUnfolded ? this.width : 'initial';
  }

  prepareFilterChips(filters: FilterElementInterface[]): FilterChipsInterface[] {
    let chips: FilterChipsInterface[] = [];

    _.forEach(filters, (singleFilter: FilterElementInterface) => {
      if (singleFilter.value instanceof Array) {
        if (singleFilter.type === FilterTypeEnum.GROUP) {
          chips = [...chips, ...this.prepareFilterChips(singleFilter.value)];
        } else if (TroiFilterChipsComponent.isSingleFilterChipDate(singleFilter)) {
          const periodFrom = moment(singleFilter.value[0]).format(this.dateFormat);
          const periodTo = moment(singleFilter.value[1]).format(this.dateFormat);

          chips.push({
            id: singleFilter.formName,
            label: singleFilter.chips[0].label,
            value: periodFrom === periodTo ? periodFrom : `${periodFrom} - ${periodTo}`,
            disabled: !CommonFiltersService.isFilterActive(singleFilter),
          });
        } else {
          _.forEach(singleFilter.value, (value, index) => {
            if (!_.isNull(value)) {
              if (singleFilter.type === FilterTypeEnum.RANGE) {
                if (
                  _.isNull(singleFilter.defaultValue[index]) ||
                  (value instanceof Money &&
                    value.formattedValue !== singleFilter.defaultValue[index].formattedValue) ||
                  (!(value instanceof Money) && value !== singleFilter.defaultValue[index])
                ) {
                  chips.push({
                    id: singleFilter.formName,
                    label: singleFilter.chips[index].label,
                    value: value instanceof Money ? value.formattedValue : value,
                    index,
                    disabled: !CommonFiltersService.isFilterActive(singleFilter),
                  });
                }
              } else if (singleFilter.type === FilterTypeEnum.RANGE_DATE) {
                chips.push({
                  id: singleFilter.formName,
                  label: singleFilter.chips[index].label,
                  value: moment(value).format(this.dateFormat),
                  index,
                  disabled: !CommonFiltersService.isFilterActive(singleFilter),
                });
              } else if (
                singleFilter.type === FilterTypeEnum.RANGE_STRING &&
                value !== singleFilter.defaultValue[index]
              ) {
                chips.push({
                  id: singleFilter.formName,
                  label: singleFilter.chips[index].label,
                  value,
                  index,
                  disabled: !CommonFiltersService.isFilterActive(singleFilter),
                });
              } else if (singleFilter.type === FilterTypeEnum.DROPDOWN_LAZY && singleFilter.dropdownMultipleSelect) {
                if (singleFilter.dropdownReturnSelectedObject && value.groupValues?.length === 0) {
                  chips.push({
                    id: singleFilter.formName,
                    label: singleFilter.label,
                    value: value.label,
                    index: value.value,
                    parent: value.parent,
                    disabled: !CommonFiltersService.isFilterActive(singleFilter),
                  });
                } else if (!value.groupValues) {
                  chips.push({
                    id: singleFilter.formName,
                    label: singleFilter.label,
                    value: this.getValueLabelForChips(singleFilter, value),
                    index: value,
                    disabled: !CommonFiltersService.isFilterActive(singleFilter),
                  });
                }
              }
            }
          });
        }
      } else {
        if (singleFilter.defaultValue !== singleFilter.value && !isNaN(singleFilter.value)) {
          chips.push({
            id: singleFilter.formName,
            label: singleFilter.chips[0].label,
            value: this.getValueLabelForChips(singleFilter),
            disabled: !CommonFiltersService.isFilterActive(singleFilter),
          });
        }
      }
    });

    return chips;
  }

  private getValueLabelForChips(filter: FilterElementInterface, specificValue?: any): string {
    switch (filter.type) {
      case FilterTypeEnum.RANGE:
        return filter.value;
      case FilterTypeEnum.SWITCH:
        return filter.chips[0].value;
      case FilterTypeEnum.CHECKBOX:
        return filter.chips[0].value;
      case FilterTypeEnum.INPUT:
        return filter.value;
      case FilterTypeEnum.DROPDOWN:
      case FilterTypeEnum.DROPDOWN_LAZY:
        return this.getLabelForSelectedDropdown(filter, specificValue);
    }
  }

  private getLabelForSelectedDropdown(filter: FilterElementInterface, specificValue?: any): string {
    let label = '';
    _.forEach([...filter.preloadedOptions, ...filter.dropdownData], (item: TroiDropdownListModel) => {
      if (filter.value === item.value || specificValue === item.value) {
        label = item.label;
      }
    });

    return label;
  }

  onFilterChipsClosed(chip: FilterChipsInterface) {
    const singleFilter = _.find(this.flattenedFilters(), ['formName', chip.id]);

    if (singleFilter) {
      if (!_.isUndefined(chip.index)) {
        if (singleFilter.type === FilterTypeEnum.RANGE_DATE) {
          if (
            moment(singleFilter.value[0]).format(this.dateFormat) ===
            moment(singleFilter.value[1]).format(this.dateFormat)
          ) {
            singleFilter.value[0] = singleFilter.defaultValue[0];
            singleFilter.value[1] = singleFilter.defaultValue[1];
          } else {
            singleFilter.value[chip.index] = singleFilter.value[chip.index === 0 ? 1 : 0];
            singleFilter.value[0] = moment(singleFilter.value[0]).startOf('day').unix() * 1000;
            singleFilter.value[1] = moment(singleFilter.value[0]).endOf('day').unix() * 1000;
          }
        } else if (singleFilter.type === FilterTypeEnum.DROPDOWN_LAZY && Array.isArray(singleFilter.value)) {
          singleFilter.value = singleFilter.value.filter((option: TroiDropdownListModel) => {
            const value = singleFilter.dropdownReturnSelectedObject ? option.value : option;
            return ![chip.index, chip.parent].includes(value);
          });
        } else {
          singleFilter.value[chip.index] = singleFilter.defaultValue[chip.index];
        }
      } else {
        singleFilter.value = singleFilter.defaultValue;
      }

      this.filterClosed.emit();
    }

    this.updateFilters(this.filters);
  }

  private flattenedFilters(filters?: FilterElementInterface[]): FilterElementInterface[] {
    const actualFilters = filters ? filters : this.filters;
    return actualFilters.reduce((acc: FilterElementInterface[], filter: FilterElementInterface) => {
      const actualFilter = filter.type === FilterTypeEnum.GROUP ? this.flattenedFilters(filter.value) : [filter];
      return [...acc, ...actualFilter];
    }, []);
  }

  public toggleFoldState(): void {
    this.areFilterChipsUnfolded = !this.areFilterChipsUnfolded;
  }

  ngOnDestroy(): void {
    this.reloadFilterChipsSubscription?.unsubscribe();
  }
}
