import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './not-found.component';
import { HeaderNotificationComponent } from './modules/header-notification/headerNotification.component';
import { AccountsComponent } from './modules/accounts/accounts.component';
import { BookingsComponent } from './modules/bookings/bookings.component';
import { ProjectListComponent } from './modules/project-list/project-list.component';
import { TasksComponent } from './modules/tasks/tasks.component';

const appRoutes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'pricelist',
    loadChildren: () => import('./modules/price-list/priceList.module').then((m) => m.PriceListModule),
  },
  {
    path: 'billings',
    loadChildren: () => import('./modules/billings/billings.module').then((m) => m.BillingsModule),
  },
  {
    path: 'project-list',
    component: ProjectListComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'tree-view',
      },
      {
        path: 'tree-view',
        loadChildren: () =>
          import('./modules/project-list/modules/tree-view/tree-view-list.module').then((m) => m.TreeViewListModule),
      },
    ],
  },
  {
    path: 'notification',
    component: HeaderNotificationComponent,
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'logout',
    redirectTo: '/login/logout',
    pathMatch: 'full',
  },
  {
    path: 'accounting-administration',
    component: AccountsComponent,
    children: [
      {
        path: 'groups',
        loadChildren: () =>
          import('./modules/accounts/accounting-administration/account-group/account-group.module').then(
            (m) => m.AccountGroupModule,
          ),
      },
      {
        path: '',
        loadChildren: () =>
          import('./modules/accounts/accounting-administration/account-list/account-list.module').then(
            (m) => m.AccountListModule,
          ),
      },
      {
        path: 'costcenters',
        loadChildren: () =>
          import('./modules/accounts/accounting-administration/cost-center/cost-center.module').then(
            (m) => m.CostCenterModule,
          ),
      },
    ],
  },
  {
    path: 'bookings',
    component: BookingsComponent,
    children: [
      {
        path: 'uploaded-files',
        loadChildren: () =>
          import('./modules/bookings/uploaded-files/uploaded-files.module').then((m) => m.UploadedFilesModule),
      },
      {
        path: 'import-export',
        loadChildren: () =>
          import('./modules/bookings/import-export/import-export.module').then((m) => m.ImportExportModule),
      },
      {
        path: 'recorded',
        loadChildren: () => import('./modules/bookings/recorded/recorded.module').then((m) => m.RecordedModule),
      },
      {
        path: 'protocol',
        loadChildren: () => import('./modules/bookings/protocol/protocol.module').then((m) => m.ProtocolModule),
      },
    ],
  },
  {
    path: 'tasks',
    component: TasksComponent,
    children: [
      {
        path: 'listview',
        loadChildren: () => import('./modules/tasks/modules/listview/listview.module').then((m) => m.ListviewModule),
      },
      {
        path: 'listview/:taskId',
        loadChildren: () => import('./modules/tasks/modules/listview/listview.module').then((m) => m.ListviewModule),
      },
      {
        path: 'kanbanview',
        loadChildren: () =>
          import('./modules/tasks/modules/kanbanview/kanbanview.module').then((m) => m.KanbanviewModule),
      },
      {
        path: 'resources',
        loadChildren: () =>
          import('./modules/tasks/modules/ganttchartview/ganttchartview.module').then((m) => m.GanttchartviewModule),
      },
      {
        path: 'kanbanview/:taskId',
        loadChildren: () =>
          import('./modules/tasks/modules/kanbanview/kanbanview.module').then((m) => m.KanbanviewModule),
      },
      {
        path: 'timelineview',
        loadChildren: () =>
          import('./modules/tasks/modules/timelineview/timelineview.module').then((m) => m.TimelineviewModule),
      },
      {
        path: 'timelineview/:taskId',
        loadChildren: () =>
          import('./modules/tasks/modules/timelineview/timelineview.module').then((m) => m.TimelineviewModule),
      },
    ],
  },
  {
    path: 'desk',
    loadChildren: () => import('./modules/desk/desk.module').then((m) => m.DeskModule),
  },
  {
    path: 'status-manager',
    loadChildren: () =>
      import('./modules/settings/task-status-management/status-manager.module').then((m) => m.StatusManagerModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
  },
  {
    path: 'menu',
    loadChildren: () => import('./modules/menu/menu.module').then((m) => m.MenuModule),
  },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
