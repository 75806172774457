import { Injectable } from '@angular/core';
import { TreeViewListNetworkService } from '../network/tree-view-list.network';
import { BackendResponseInterface } from '../../../../../core/interfaces/backend.response.interface';
import { TreeViewListFactory } from '../factories/tree-view-list.factory';
import { FolderModel } from '../components/folder/models/folder.model';
import {
  ProjectListBackendResponseInterface,
  TreeListItemResponseInterface,
} from '../../../../../shared/troi-tree-list/interfaces/tree-list-item-response.interface';
import { ProjectTypeEnum } from '../enums/project-type.enum';
import { ProjectPropertiesInterface } from '../interfaces/project-properties.interface';
import { TreeListItemModel } from '../../../../../shared/troi-tree-list/models/tree-list-item.model';
import { FiltersInterface } from '../../../../../shared/troi-data-listing-filters/filters.interface';
import { FiltersFormService } from '../../../services/filters-form.service';
import { FiltersService } from '../../../services/filters.service';
import { SettingsModel } from '../../../models/settings.model';
import { ProjectListSettingsService } from '../../../services/project-list-settings.service';
import { FolderUnfoldedStateService } from '../components/services/folder-unfolded-state.service';
import { Observable, of, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { StorageNotificationService } from '../../../../../core/notifications/storageNotification.service';
import { LanguagesService } from '../../../../../core/services/languages.service';
import { environment } from '../../../../../../environments/environment';
import { ProjectModel } from '../models/project.model';

@Injectable()
export class TreeViewListService {
  private listReady = true;
  public count = 0;
  public totalCustomersCount = 0;
  private unfoldPages = [
    'project_base_data',
    'project_calculation',
    'project_offer',
    'project_order',
    'project_archive',
    'assignment_list',
    'project_report',
    'project_documents',
    'project_sales_stage',
    'project_invoice_forecast',
    'projectplan_diagram',
    'projectplan_base_data',
    'project_rest_expense_estimation',
    'jira_integration',
    'file_share',
  ];

  // TODO: TROR-18921 type should extend DragNDropElementInterface, but it has to be more generic
  public projectTreeViewList: TreeListItemModel<ProjectTypeEnum>[] = [];
  public activeProjectFolder = new Subject<{ projectFolder: FolderModel; hasChildrenAlreadyLoaded: boolean } | Error>();

  constructor(
    private treeViewListNetworkService: TreeViewListNetworkService,
    private treeViewListFactory: TreeViewListFactory,
    private filtersService: FiltersService,
    private filtersFormService: FiltersFormService,
    public projectListSettingsService: ProjectListSettingsService,
    private unfoldedStateService: FolderUnfoldedStateService,
    private notificationService: StorageNotificationService,
    private languagesService: LanguagesService,
  ) {}

  public isContentReady(): boolean {
    return this.listReady;
  }

  public getProjectList(filters: FiltersInterface, settings: SettingsModel): void {
    this.listReady = false;
    this.projectTreeViewList = [];
    this.treeViewListNetworkService
      .getTreeStructure(
        this.filtersFormService.prepareSearchRequestParams(filters),
        this.languagesService.getDataLanguage(),
      )
      .subscribe(
        (
          response: ProjectListBackendResponseInterface<
            TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]
          >,
        ) => {
          this.count = response.count || 0;
          this.totalCustomersCount = response.totalCount || 0;
          // TODO: forceExpanded = filters.searchEnabled, is needed? TROR-18897, DragNDropElementInterface?
          if (response.directProjectUrl) {
            let baseUrl = window.location.origin;
            if (!environment.production) {
              baseUrl = environment.url;
            }

            window.parent.window.location.href = baseUrl + response.directProjectUrl;
            return;
          }
          this.projectTreeViewList = response.data.map(
            (
              item: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>,
            ): TreeListItemModel<ProjectTypeEnum> => this.treeViewListFactory.parseElement(item, false, settings, null),
          );
          this.listReady = true;
        },
      );

    this.filtersService.putFiltersInUrl(this.filtersFormService.prepareSearchRequestParams(filters));
  }

  public getProjectChildren(
    folder: FolderModel,
    filters: FiltersInterface,
    settings: SettingsModel,
    forceLoad = false,
  ): Observable<boolean> {
    if (!folder.areChildrenLoaded || forceLoad) {
      return this.treeViewListNetworkService
        .getChildrenTreeStructure(folder, this.filtersFormService.prepareSearchRequestParams(filters))
        .pipe(
          map(
            (
              response: BackendResponseInterface<
                TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>[]
              >,
            ) => {
              folder.nodes = response.data.map(
                (
                  item: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>,
                ): TreeListItemModel<ProjectTypeEnum> =>
                  this.treeViewListFactory.parseElement(item, false, settings, folder),
              );
              const hasChildren = folder._nodes.length > 0;
              folder._hasChildren = hasChildren;
              folder.expanded = hasChildren;
              return false;
            },
          ),
        );
    } else {
      return of(true);
    }
  }

  public findItemById(id: string | number, projectTree = this.projectTreeViewList): TreeListItemModel<ProjectTypeEnum> {
    for (const node of projectTree) {
      if (String(node._id) === String(id)) return node;
      if (node._nodes && node._nodes.length) {
        const child = this.findItemById(id, node._nodes);
        if (child) return child;
      }
    }
  }

  public moveToFolder(source: string, target: string, type: string): Observable<Record<string, string>> {
    return this.treeViewListNetworkService.moveToFolder(source, target, type);
  }

  public findProjectFolder(
    projectFolder: FolderModel | string | number,
    itemsInCurrentDepth?: TreeListItemModel<ProjectTypeEnum>[],
  ): FolderModel {
    const items = itemsInCurrentDepth || this.projectTreeViewList;
    return projectFolder instanceof FolderModel
      ? projectFolder
      : (items.find((item: TreeListItemModel<ProjectTypeEnum>) => item.id === String(projectFolder)) as FolderModel);
  }

  public expandProjectFolder(
    item: FolderModel | FolderModel[] | string | string[] | number | number[],
    parent?: FolderModel,
    withoutSaving = false,
  ): void {
    let projectFolder;

    if (Array.isArray(item) && item.length > 0) {
      projectFolder = this.findProjectFolder(item[0], parent && parent.nodes);
    } else {
      // @ts-ignore
      projectFolder = this.findProjectFolder(item, parent && parent.nodes);
    }
    if (projectFolder) {
      projectFolder._expanded = true;

      if (!withoutSaving) {
        this.unfoldedStateService.addSavedId(projectFolder.id);
      }
      this.fetchProjectFolder(item, projectFolder);
    } else {
      this.notificationService.showError('ProjectList.error.projectFolderExpandError');
      this.activeProjectFolder.next(new Error('ProjectList.error.projectFolderExpandError'));
    }
  }

  public fetchProjectFolder(
    item: FolderModel | FolderModel[] | string | string[] | number | number[],
    projectFolder: FolderModel,
    forceLoad: boolean = false,
  ) {
    this.getProjectChildren(
      projectFolder,
      this.filtersService.actualFilters,
      this.projectListSettingsService.settings,
      forceLoad,
    ).subscribe((hasChildrenAlreadyLoaded: boolean) => {
      if (Array.isArray(item) && item.slice(1).length > 0) {
        this.expandProjectFolder(item.slice(1), projectFolder);
      } else {
        this.activeProjectFolder.next({ projectFolder, hasChildrenAlreadyLoaded });
      }
    });
  }

  public collapseProjectFolder(item: FolderModel): void {
    item._expanded = false;

    this.unfoldedStateService.removeSavedId(item.id);
  }

  public getDepth(item: TreeListItemModel<ProjectTypeEnum>): number {
    if (!item._parent) {
      return 1;
    }

    const parentDepth = this.getDepth(item._parent);
    return parentDepth < 13 ? parentDepth + 1 : parentDepth;
  }

  public isReferrerToUnfold(): boolean {
    const { referrer } = window.top.document;
    if (!referrer) return false;
    const page = new URL(referrer).searchParams.get('page');
    return this.unfoldPages.includes(page);
  }
}
