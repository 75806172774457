<div class="tasks-page">
  <div *ngIf="!isProjectContext" class="tasks-page__wrapper">
    <troi-breadcrumbs [breadcrumbs]="[{ name: getHeadlineTitle() }]"></troi-breadcrumbs>
  </div>
  <header-notification></header-notification>
  <div class="taskview">
    <div class="taskview__header" *ngIf="this.settingsService._settings">
      <troi-data-listing-filters
        [firstDropdown]="filtersService.generateFirstDropdown()"
        (firstDropdownChanged)="clientChanged($event)"
        [secondDropdown]="filtersService.secondDropdown"
        (secondDropdownChanged)="filterChanged($event)"
        [reloadSecondDropdownOptions]="filtersService.reloadProjectData"
        [thirdDropdown]="filtersService.thirdDropdown"
        (thirdDropdownChanged)="filterChanged($event)"
        [reloadThirdDropdownOptions]="filtersService.reloadEmployeeData"
        [resetThirdDropdown]="filtersService.resetQuickFilters"
        [filters]="filtersService.actualFilters"
        [enableDaterangePicker]="isProjectContext || isResourcesContext"
        [enableClientDropDown]="isResourcesContext"
        (filtersChanged)="filterChanged($event)"
        (filtersReset)="filterReset()"
        [columnList]="columnsService.getColumnList()"
        (columnsChanged)="columnsService.saveColumnList($event)"
        [client]="getClient()"
        [lang]="languagesService.getLanguage()"
        [dateFormat]="settingsService.settings?.settings.dateFormat"
        [limitDate]="false"
        header="Tasks.listview.labels.header"
        [showTitle]="false"
        [showNavIcons]="true"
        [selectedFilterSetId]="filtersService.selectedFilterSetId"
        [filterSets]="filtersService.filterSets"
        [areFiltersEdited]="filtersService.areFiltersEdited"
        [showColumnSelection]="router.url.includes('listview')"
        (filterSetSelected)="filterSetSelected($event)"
        (filterSetDeleted)="filterSetDeleted($event)"
        (filterSetAsDefault)="filterSetAsDefault($event)"
        (filterSetOpenSave)="openFilterSetSaveModal()"
        [reloadFilterChips]="filtersService.reloadFilterChips"
        [isResourceContext]="isResourcesContext"
      >
        <div
          *ngIf="router.url.includes('/tasks/timelineview')"
          class="taskview__header--date-picker"
        >
          <tnd-datepicker
            (rangeChanged)="updateTimelineRange($event)"
            [settings]="{ format: settingsService.settings?.settings.dateFormat }"
            [placeholder]="'TT.MM.JJJJ'"
            [rangeFrom]="startDate"
            [rangeTo]="endDate"
          ></tnd-datepicker>
        </div>

        <div class="taskview__header--btn-navigation" *ngIf="!isResourcesContext">
          <troi-top-bar-btns
            class="body__actions"
            [firstBtnValue]="btnNewTask"
            [secondBtnValue]="btnExportTasks"
            (btnNewClick)="openNewTaskModal()"
            (btnExportClick)="openExportModal()"
          >
          </troi-top-bar-btns>
        </div>
        <ng-container *ngIf="!isResourcesContext">
          <troi-view-switch navBar></troi-view-switch>
        </ng-container>
      </troi-data-listing-filters>
    </div>
    <div class="taskview__body">
      <router-outlet
        *ngIf="this.settingsService.settings && !tasksService.isLoading"
      ></router-outlet>
      <div
        class="body__loading-wrapper"
        *ngIf="!this.settingsService.settings && tasksService.isLoading"
      >
        <div class="body__loading">
          <troi-loading
            [rows]="3"
            [columns]="[
              '25px',
              '20%',
              '20px',
              '5%',
              '20px',
              '5%',
              '20px',
              '5%',
              '20px',
              '5%',
              '20px',
              '5%',
              '20px',
              '5%',
              '20px'
            ]"
          >
          </troi-loading>
        </div>
      </div>
    </div>
  </div>
  <div id="overlay" class="hidden">
    <div id="modal-container" class="hidden x"></div>
  </div>
  <div id="confirm-overlay" class="hidden">
    <div id="confirm" class="hidden"></div>
  </div>

  <div class="taskview__footer">
    <troi-footer
      *ngIf="!isProjectContext"
      [settings]="settingsService.settings"
      [loggingWorkingTimeEnabled]="settingsService.settings?.settings.isLoggingWorkingTimeEnabled"
      [disabledCurrency]="true"
      [settingsEnabled]="true"
    ></troi-footer>
  </div>
</div>
