import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ModuleInterceptor } from '../../../core/enums/module-interceptor';
import { Routes } from '../enum/routes';

import {
  AssignmentResponseInterface,
  SubtaskResponseInterface,
  TasksResponseInterface,
} from '../interfaces/responses.interface';
import { TaskModel } from '../models/task.model';
import { SubtaskInterface } from '../interfaces/subtask.interface';
import { AssigneeInterface } from '../interfaces/assignee.interface';

import { TaskInterface } from '../interfaces/task.interface';
import { TasksService } from './tasks.service';

@Injectable()
export class SubtaskService {
  private headers: HttpHeaders;

  public constructor(private http: HttpClient, private taskService: TasksService) {
    this.headers = new HttpHeaders().set(ModuleInterceptor.TASKPLANNING, '1');
  }

  public getSubtasks(taskId: string): Observable<TasksResponseInterface> {
    return this.http.get<TasksResponseInterface>(environment.url + Routes.TASKS + `/getOne/${taskId}`, {
      headers: this.headers,
    });
  }

  public createSubtask(
    title: string,
    task: TaskInterface,
    subtask?: SubtaskInterface,
  ): Observable<SubtaskResponseInterface> {
    const newSubtask = {
      parentId: task.id,
      assignees: subtask?.assignees ?? [],
      title: subtask?.title ?? title,
      calculationPosition: task.calculationPosition,
      description: subtask?.description ?? '',
      status: subtask?.status ?? this.taskService.statusOptions[0],
      statusGroup: task.status.statusGroup,
      projecttask: task.id,
      startDate: subtask?.startDate ?? null,
      endDate: subtask?.endDate ?? null,
    };

    return this.http.post<SubtaskResponseInterface>(environment.url + Routes.SUBTASKS, newSubtask, {
      headers: this.headers,
    });
  }

  public updateSubtask(subtask: SubtaskInterface, taskId: string): Observable<SubtaskResponseInterface> {
    subtask.projecttask = {
      id: taskId,
    };
    if (!subtask.assignees || !subtask.assignees.length) subtask.assignees = null;
    return this.http.put<SubtaskResponseInterface>(environment.url + Routes.SUBTASKS + `/${subtask.id}`, subtask, {
      headers: this.headers,
    });
  }

  public deleteSubtask(subtaskId: string): Observable<SubtaskResponseInterface> {
    return this.http.delete<SubtaskResponseInterface>(environment.url + Routes.SUBTASKS + `/${subtaskId}`);
  }

  public addSubtaskAssignee(subtaskId: string, assignee: AssigneeInterface): Observable<SubtaskResponseInterface> {
    return this.http.post<SubtaskResponseInterface>(
      environment.url + Routes.SUBTASKS + `/${subtaskId}/assignee`,
      assignee,
      {
        headers: this.headers,
      },
    );
  }

  public deleteSubtaskAssignee(
    subtask: SubtaskInterface,
    assignee: AssigneeInterface,
  ): Observable<SubtaskResponseInterface> {
    return this.http.delete<SubtaskResponseInterface>(
      environment.url + Routes.SUBTASKS + `/${subtask.id}/assignee/${assignee.id}`,
    );
  }
}
