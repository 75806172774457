import { ProjectModel } from '../models/project.model';
import { TreeListItemResponseInterface } from '../../../../../shared/troi-tree-list/interfaces/tree-list-item-response.interface';
import { ProjectPricesInterface, ProjectPropertiesInterface } from '../interfaces/project-properties.interface';
import { ProjectPrice, ProjectPrices, ProjectPropertiesModel } from '../models/project-properties.model';
import { Injectable } from '@angular/core';
import { ProjectTypeEnum } from '../enums/project-type.enum';
import { SettingsModel } from '../../../models/settings.model';
import { FolderModel } from '../components/folder/models/folder.model';
import { ProjectListFactory } from '../../../factories/project-list.factory';
import { ProjectListSettingsService } from '../../../services/project-list-settings.service';

@Injectable()
export class ProjectFactory extends ProjectListFactory {
  constructor(protected projectListSettingsService: ProjectListSettingsService) {
    super(projectListSettingsService);
  }
  public static createEmptyProject(parent?: FolderModel): ProjectModel {
    const project = new ProjectModel(
      '0',
      0,
      new ProjectPropertiesModel(
        { en: '', de: '', fr: '' },
        false,
        '',
        { en: '', de: '', fr: '' },
        '',
        null,
        null,
        null,
        Date.now(),
        Date.now(),
        { approved: 0, notApproved: 0, total: 0 },
        null,
        false,
        Date.now(),
        [null, null],
        null,
        true,
        false,
        { debit: 0, actual: 0, percentage: 0 },
        { debit: 0, actual: 0, percentage: 0 },
        { debit: 0, actual: 0, percentage: 0 },
        { debit: 0, actual: 0, percentage: 0 },
        false,
      ),
      parent ? (parent.isCustomer() ? parent.id : parent.customer) : null,
    );

    project.parent = parent ?? project.parent;

    return project;
  }

  public createProject(
    data: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>,
    settings: SettingsModel,
  ): ProjectModel {
    return new ProjectModel(
      data.id.id,
      data.order,
      new ProjectPropertiesModel(
        data.fields.name,
        data.fields.inFavorites,
        data.fields.projectNumber,
        data.fields.customer,
        data.fields.customerContact,
        data.fields.projectLeader,
        data.fields.projectType,
        Number(data.fields.projectStatus),
        data.fields.reportingDate,
        data.fields.orderingDate,
        data.fields.orderingStatusCounts,
        data.fields.orderingStatus,
        data.fields.approvalStatus,
        data.fields.approvalDate,
        data.fields.projectStartEndDate,
        this.parsePrices(data.fields.prices, settings),
        data.fields.active,
        data.fields.hasProjectPlan,
        data.fields.actualToDebitAmount,
        data.fields.actualToDebitQuantity,
        data.fields.actualToDebitInternalAmount,
        data.fields.actualToDebitInternalQuantity,
        data.fields.projectIsBlocked,
      ),
      data.id.customer,
    );
  }

  public updateProject(
    project: ProjectModel,
    data: TreeListItemResponseInterface<ProjectTypeEnum, ProjectPropertiesInterface>,
    settings: SettingsModel,
  ): ProjectModel {
    if (data.id) {
      project.id = data.id.id;
      project.customer = data.id.customer;
    }

    project.order = data.order;
    project.properties.name = data.fields.name;
    project.properties.inFavorites = data.fields.inFavorites;
    project.properties.projectNumber = data.fields.projectNumber;
    project.properties.customer = data.fields.customer;
    project.properties.customerContact = data.fields.customerContact;
    project.properties.projectLeader = data.fields.projectLeader;
    project.properties.projectType = data.fields.projectType;
    project.properties.projectStatus = Number(data.fields.projectStatus);
    project.properties.reportingDate = data.fields.reportingDate;
    project.properties.orderingDate = data.fields.orderingDate;
    project.properties.orderingStatus = data.fields.orderingStatus;
    project.properties.approvalStatus = data.fields.approvalStatus;
    project.properties.approvalDate = data.fields.approvalDate;
    project.properties.projectStartEndDate = data.fields.projectStartEndDate;
    project.properties.prices = this.parsePrices(data.fields.prices, settings);
    project.properties.active = data.fields.active;
    project.properties.hasProjectPlan = data.fields.hasProjectPlan;

    return project;
  }

  private parsePrices(prices: ProjectPricesInterface, settings: SettingsModel): ProjectPrices {
    return new ProjectPrices(
      settings.systemCurrency,
      new ProjectPrice(
        this.parsePrice(prices.totalCalculation, settings.systemCurrency, settings),
        this.parsePrice(prices.totalSumClosedInvoices, settings.systemCurrency, settings),
      ),
    );
  }
}
