<span
  class="troi-dropdown-list"
  [ngClass]="{ 'troi-dropdown-list--static': static, 'troi-dropdown-list--disabled': disable }"
>
  <span
    #TroiDropdownList="ngbPopover"
    [ngbPopover]="popContent"
    [popperOptions]="popperOptions"
    container="body"
    autoClose="outside"
    triggers="manual"
    popoverClass="troi-dropdown-list-popover"
    [placement]="placement"
    (hidden)="onClickOutside()"
    class="troi-dropdown-list__select"
    (click)="onClickSelect(TroiDropdownList, $event)"
    #troiDropDownListSelect
  >
    <ng-content> </ng-content>
  </span>
</span>
<ng-template #popContent>
  <span
    class="troi-dropdown-list__list-wrapper"
    [ngClass]="{
      'troi-dropdown-list__list-wrapper--top': top,
      'troi-dropdown-list__list-wrapper--no-min-width': noMinWith
    }"
    [ngStyle]="{ width: width }"
    *ngIf="options && options.length"
  >
    <troi-loading-spinner *ngIf="isLoading"></troi-loading-spinner>
    <span *ngIf="search" class="troi-dropdown-list__search">
      <span *ngIf="searchPrefix" class="troi-dropdown-list__search-prefix">{{ searchPrefix }}</span>
      <input
        [type]="searchInputType"
        (click)="searchClicked($event)"
        (input)="filterOptions(searchPhrase)"
        [(ngModel)]="searchPhrase"
        placeholder="{{ 'Booking.labels.search' | translate }}..."
        class="troi-dropdown-list__search-input"
      />
    </span>

    <span
      (scroll)="calculateScrollPosition($event)"
      class="troi-dropdown-list__list"
      (focusout)="!search && hideList()"
      tabindex="0"
    >
      <div id="optionList">
        <ng-container *ngFor="let option of filteredOptions">
          <troi-dropdown-option
            [ngStyle]="{ 'text-align': textAlignment }"
            [disabled]="option?.disabled"
            [selected]="isOptionSelected(option)"
            [icon]="option?.icon"
            [label]="option?.label"
            [labelInfo]="option?.labelInfo"
            [value]="option?.value"
            [actions]="actions"
            [active]="option?.active"
            [count]="option?.count"
            [loading]="option?.loading"
            [group]="option?.group"
            [parent]="option?.group && option?.groupValues?.length > 0"
            [isRequired]="option?.group && option?.groupValues?.length > 0 && option?.isRequired"
            [hasOverride]="option?.hasOverride"
            [showCheckbox]="
              multipleSelect ||
              (treeMode && option?.multipleChoice && isGroupOptionSelectable(option))
            "
            [leftPadding]="
              (multipleSelect || (treeMode && option?.multipleChoice)) && option?.disabled
            "
            [colorizeBackground]="option?.colorizeBackground"
            (click)="!option?.disabled && onClickItem(TroiDropdownList, option, $event)"
          >
          </troi-dropdown-option>
          <div *ngIf="option?.group && option?.groupValues?.length > 0">
            <ng-container *ngFor="let subOption of option?.groupValues">
              <troi-dropdown-option
                [disabled]="isSubOptionDisabled(option, subOption)"
                [selected]="isOptionSelected(subOption)"
                [icon]="subOption.icon"
                [label]="subOption.label"
                [labelInfo]="subOption.labelInfo"
                [value]="subOption.value"
                [actions]="actions"
                [active]="subOption.active"
                [count]="subOption.count"
                [loading]="subOption.loading"
                [group]="false"
                [parent]="false"
                [child]="true"
                [hasOverride]="subOption.hasOverride"
                [showCheckbox]="
                  (multipleSelect || (treeMode && subOption.multipleChoiceGroup)) &&
                  !subOption.disabled
                "
                [showRadio]="treeMode && !subOption.multipleChoiceGroup && !subOption.disabled"
                [leftPadding]="
                  (multipleSelect || (treeMode && subOption.multipleChoiceGroup)) &&
                  subOption.disabled
                "
                [colorizeBackground]="subOption.colorizeBackground"
                (click)="
                  !isSubOptionDisabled(option, subOption) &&
                    onClickItem(TroiDropdownList, subOption, $event)
                "
              >
              </troi-dropdown-option>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </span>
  </span>
  <span
    class="troi-dropdown-list__list-wrapper"
    [ngStyle]="{ width: width }"
    *ngIf="(options && !options.length) || !options"
  >
    <troi-loading-spinner *ngIf="isLoading"></troi-loading-spinner>
    <span *ngIf="search" class="troi-dropdown-list__search">
      <span *ngIf="searchPrefix" class="troi-dropdown-list__search-prefix">{{ searchPrefix }}</span>
      <input
        [type]="searchInputType"
        *ngIf="search"
        (click)="searchClicked($event)"
        (keyup)="filterOptions(searchPhrase)"
        [(ngModel)]="searchPhrase"
        class="troi-dropdown-list__search-input"
      />
    </span>
    <span
      (scroll)="calculateScrollPosition($event)"
      class="troi-dropdown-list__list"
      [ngClass]="{
        top: top,
        noMinWith: noMinWith
      }"
    >
      <div id="optionList">
        <ng-content select="[options]"></ng-content>
      </div>
    </span>
  </span>
</ng-template>
