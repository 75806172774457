import { TasksService } from './../../../network/tasks.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { TaskFormService } from './services/task-form.service';
import { Subscription, map, Observable, first } from 'rxjs';
import {
  CommentResponseInterface,
  ConsumptionBookedResponseInterface,
  EmployeeResponseInterface,
  TasksResponseInterface,
  TimeEntryHelperResponseInterface,
} from '../../../interfaces/responses.interface';
import { StatusInterface } from '../../../interfaces/status.interface';
import { TroiDropdownListModel } from '../../../../../shared/troi-dropdown-list/models/troi-dropdown-list.model';
import { TaskFiltersService } from '../../../services/filters.service';
import { Priority } from '../../../enum/priority';
import { TranslateService } from '@ngx-translate/core';
import { TaskActionsEnum } from '../../../enum/task-actions';
import { TaskModel } from '../../../models/task.model';
import { TaskModalPagesEnum } from '../../../enum/task-modal-pages';
import { Clipboard } from '@angular/cdk/clipboard';
import { StorageNotificationService } from '../../../../../core/notifications/storageNotification.service';
import { TasksHelperService } from '../../../services/helper.service';
import { LanguagesService } from '../../../../../core/services/languages.service';
import { CalcPositionService } from '../../../network/calc-pos.service';
import { TimeentryService } from './network/timeentry.service';
import { CalculationPositionInterface } from '../../../interfaces/calculationPosition.interface';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { ConsumptionPopoverService } from '../../../network/consumption-popover.service';
import { SubtaskService } from '../../../network/subtask.service';
import { SubtaskInterface } from '../../../interfaces/subtask.interface';
import { TimeEntryOverviewInterface } from '../../../interfaces/timeEntry.interface';
import { CommentsService } from '../../../network/comments.service';
import { AssigneeInterface } from '../../../interfaces/assignee.interface';
import { UserService } from '../../../../../core/user/user.service';
import { CommentInterface } from '../../../interfaces/comment.interface';
import { UserConfirmationEventEnum } from '../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import { TroiUserConfirmationComponent } from '../../../../../shared/troi-user-confirmation/troi-user-confirmation.component';
import { DomService } from '../../../../../shared/troi-modals/dom.service';
import { UserConfirmationSubscriber } from '../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { NotAllowedAssigneesModalComponent } from '../not-allowed-assignees-modal/not-allowed-assignees-modal.component';
import { StopwatchService } from '../../../../desk/widgets/stopwatch/stopwatch.service';

@Component({
  selector: 'troi-create-task-modal',
  templateUrl: './create-task-modal.component.html',
  styleUrls: ['./create-task-modal.component.scss'],
})
export class CreateTaskModalComponent extends BaseModalDirective implements OnInit, OnDestroy {
  @ViewChild('deleteTimeetriesPopover') deleteTimeetriesPopover: NgbPopover;
  @ViewChild('deletePopover') deletePopover: NgbPopover;
  public taskForm = new FormGroup({
    title: new FormControl('', Validators.required),
  });

  public loaded = false;
  public editModeOn = false;
  public isEditMenuOpen = false;

  public pageType = TaskModalPagesEnum;

  public task: TaskModel = {} as TaskModel;

  private subscriptions: Subscription = new Subscription();
  public timeEntries: TimeEntryOverviewInterface[] = [];
  private timeEntriesSum = 0;
  public doneSubtasks = 0;
  public bookedAssignments = 0;
  public amountOfSubtasks = 0;
  public amountOfDoneSubtasks = 0;
  public amountOfComments = 0;
  public amountOfUnreadComments = 0;
  public currentAssignee: AssigneeInterface;
  public selectedCalculationPosition: CalculationPositionInterface;
  private selectedSubtask: SubtaskInterface;
  public showCalcPosContent = false;
  public showSubtaskContent = false;
  public submitted = false;
  public disableMenu = false;
  public assignedEmployees: AssigneeInterface[] = [];

  @Input() taskTitle: string;
  @Input() taskId: number;

  @Input() clientId: number;
  @Input() editableTask: TaskModel;
  @Input() curPage = TaskModalPagesEnum.GENERAL;
  @Output() taskCreated = new EventEmitter<string>();
  @Output() taskDeleted = new EventEmitter();

  private header: HTMLElement | null = window.top.document.querySelector('#appHeader');
  private subheader: HTMLElement | null = window.top.document.querySelector('#projectNaviPanel');

  public get moreActionsOptions(): TroiDropdownListModel[] {
    return [
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.reminder'),
        value: TaskActionsEnum.REMINDER,
        disabled: this.task.startDate ? false : true,
        icon: 'icon-number-calendar',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.copy'),
        value: TaskActionsEnum.COPY,
        disabled: false,
        icon: 'icon-files',
      },
      {
        active: true,
        label: this.translationService.instant('Tasks.labels.contextMenu.move'),
        value: TaskActionsEnum.MOVE_TASK,
        disabled: false,
        icon: 'icon-export',
      },
    ];
  }

  constructor(
    public taskHelperService: TasksHelperService,
    public modalService: ModalService,
    private taskFormService: TaskFormService,
    private taskService: TasksService,
    public filterService: TaskFiltersService,
    private translationService: TranslateService,
    private clipboard: Clipboard,
    public tasksService: TasksService,
    private languageService: LanguagesService,
    private notificationService: StorageNotificationService,
    public calcPositionService: CalcPositionService,
    private stopwatchService: StopwatchService,
    private translate: TranslateService,
    private timeentryService: TimeentryService,
    private consumptionPopoverService: ConsumptionPopoverService,
    private subtaskService: SubtaskService,
    private commentService: CommentsService,
    private userService: UserService,
    private domService: DomService,
    private confirmationSubscriber: UserConfirmationSubscriber,
  ) {
    super(modalService);
  }

  ngOnInit() {
    if (this.editableTask) {
      this.editModeOn = true;
      this.task = this.editableTask;
      this.taskId = Number(this.task.id);
      this.assignedEmployees = [...this.task.assignees];

      // new tasks have no timeentries
      this.getTimeEntries();
      this.getAllComments(true);
    } else {
      this.task.title = this.taskTitle ? this.taskTitle : '';
      this.initBasicTask();
    }

    this.initForm(false);

    this.loaded = true;

    this.getSelectedCalcPos();
    this.getSelectedSubtask();
    this.getTimeEntrySum();

    if (this.task.calculationPosition) this.getBookedAssignments();
    if (this.task.subtasks) {
      this.amountOfSubtasks = this.task.subtasks.length;
    }
    // disables header and subheader to let modal take whole page
    if (this.filterService.isProjectContext) {
      this.header.style.display = 'none';
      this.subheader.style.display = 'none';
    }
    console.log(this.task);
  }

  private getAllComments(getUnreadComments: boolean) {
    this.commentService.getComments(this.task.id).subscribe((res: CommentResponseInterface) => {
      const allComments = res.data as CommentInterface[];
      this.amountOfComments = allComments.length;

      if (getUnreadComments) this.getUnreadComments(allComments);
    });
  }

  private getTimeEntrySum() {
    this.taskHelperService.timeEntrySumSub.subscribe((res: number) => {
      this.timeEntriesSum = res;
    });
  }

  private getUnreadComments(allComments: CommentInterface[]) {
    const currentUser = this.userService.getUser();
    const assignedUser = this.task.assignees.find((assignee) => assignee.user.id === currentUser.id);
    if (assignedUser) {
      this.currentAssignee = assignedUser;
      const unreadComments = allComments.filter(
        (comment) => new Date(comment.created).getTime() > new Date(assignedUser.lastReadComments).getTime(),
      );

      if (unreadComments.length > 0) {
        this.amountOfUnreadComments = unreadComments.length;
      } else this.amountOfUnreadComments = 0;
    }
  }

  private getTimeEntries() {
    this.subscriptions.add(
      this.timeentryService.getTimeentries(this.task.id).subscribe((res: TimeEntryHelperResponseInterface) => {
        this.timeEntriesSum = +res.data.sum;
        this.task.hoursSpent = +res.data.sum;
        this.timeEntries = this.taskHelperService.formatTimeEntries(res.data.entries);
      }),
    );
  }

  private getSelectedCalcPos() {
    this.subscriptions.add(
      this.calcPositionService.getSelectedCalcPosition().subscribe((calcPos: CalculationPositionInterface) => {
        this.selectedCalculationPosition = calcPos;
      }),
    );
  }

  private getSelectedSubtask() {
    this.subscriptions.add(
      this.taskHelperService.selectedSubtask.subscribe((subtask: SubtaskInterface) => (this.selectedSubtask = subtask)),
    );
  }

  public calculateUtilization() {
    return (this.timeEntriesSum / +this.task.calculationPosition.quantity) * 100;
  }

  private initBasicTask() {
    this.task.id = this.taskId.toString();
    this.task.clientId = this.clientId.toString();
    this.task.status = this.taskService.statusOptions[0];
    this.task.priority = Priority.LOW;
  }

  public onDisableMenu(disableMenu) {
    this.disableMenu = disableMenu;
  }

  private initForm(submittedForm: boolean, formControls?: { [key: string]: AbstractControl }): void {
    this.taskForm = this.taskFormService.initForm(this.task);
  }

  public getTaskStatusOptions() {
    return this.taskService.statusOptions.map(
      (status: StatusInterface): TroiDropdownListModel => ({
        active: true,
        label: this.languageService.getLanguageValue(status.name),
        value: status.id,
        icon: 'icon-black-bold-dot-small',
      }),
    );
  }

  public onSharedUrlClick() {
    const sharedUrl = new URL(window.parent.location.href);
    sharedUrl.searchParams.set('taskId', this.taskId.toString());
    this.clipboard.copy(sharedUrl.href);
    this.notificationService.showSuccess(this.translate.instant('Tasks.labels.notifications.urlCopied'));
  }

  public onAddCalcPos(): void {
    this.showCalcPosContent = true;
  }

  public openPopover(): void {
    if (!this.isCpDeletionAllowed()) this.deleteTimeetriesPopover.open();
  }

  public isCpDeletionAllowed(): boolean {
    if (!this.task.assignees) return true;
    else {
      const timeEntrysExisting = this.timeEntries.length > 0;
      const taskHasTimeentrys = this.task.assignees.some((assignee) => {
        if (assignee.minutesTotal) return true;
      });
      return !(taskHasTimeentrys || timeEntrysExisting);
    }
  }

  public onCloseCalcPos(): void {
    this.showCalcPosContent = false;
  }

  public setPage(newPage: TaskModalPagesEnum): void {
    this.curPage = newPage;
  }

  public onModalClose() {
    if (this.task.subtasks) {
      this.task.subtaskAll = this.task.subtasks.length;
      this.task.subtaskFinished = this.task.subtasks.filter((subtask) => subtask.status.statusGroup.id === 5).length;
      this.tasksService.updateTask(this.task);
    }

    this.modalService.destroy();
  }

  public onPrioChange(priority: number) {
    this.task.priority = priority;
  }

  public onStatusChange(statusId: number) {
    this.task.status = this.taskService.statusOptions.find((x) => x.id === statusId);
    this.task.statusGroup = this.task.status.statusGroup;
  }

  public onDropdownActionOpeningState(event) {
    this.isEditMenuOpen = event;
  }

  public onActionSelect(action): void {
    const value = action.value;
    const task = this.task;
    this.taskHelperService.taskActionSub.next({ value, task });
  }

  public onDeleteTaskClick() {
    const value = TaskActionsEnum.DELETE_TASK;
    const task = this.task;

    if (this.task.hoursSpent) this.deletePopover.open();
    else this.taskHelperService.taskActionSub.next({ value, task });
  }

  public onSavePositionClick() {
    this.subscriptions.add(
      this.getNotAllowedAssignees(
        this.selectedCalculationPosition?.project.id,
        this.selectedCalculationPosition?.client.mandant.id,
      ).subscribe((notAllowedAssignees) => {
        if (notAllowedAssignees && notAllowedAssignees.length && !this.filterService.isProjectContext) {
          const allAssignees: AssigneeInterface[] = [];
          this.task.assignees.forEach((assignee) => allAssignees.push(assignee));
          this.task.subtasks?.forEach((subtask) =>
            subtask.assignees.forEach((assignee) => allAssignees.push(assignee)),
          );

          const modalService = new ModalService(this.domService);

          modalService.init(NotAllowedAssigneesModalComponent, { assignees: allAssignees }, {}, '400px', '500px', true);

          this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
            if (result === UserConfirmationEventEnum.EXECUTE) {
              this.task.assignees = this.task.assignees.filter((assignee) => {
                return !notAllowedAssignees.some((a) => a.user.id === assignee.user.id);
              });
              this.task.subtasks?.forEach((subtask: SubtaskInterface) => {
                subtask.assignees = subtask.assignees.filter((assignee) => {
                  const isNotAllowed = notAllowedAssignees.some((a) => a.user.id === assignee.user.id);
                  if (isNotAllowed)
                    this.subscriptions.add(this.subtaskService.deleteSubtaskAssignee(subtask, assignee).subscribe());

                  return !isNotAllowed;
                });
              });
              this.saveCalcPosition();
            } else {
              this.modalService.destroy();
            }
          });
        } else this.saveCalcPosition();
      }),
    );
  }

  private getNewAssignedEmployees(): AssigneeInterface[] {
    const alreadyAssignedIds = new Set(this.assignedEmployees.map((assignee) => assignee.id));
    return this.task.assignees.filter((assignee) => !alreadyAssignedIds.has(assignee.id));
  }

  private getNotAllowedAssignees(projectId: number, clientId: number): Observable<AssigneeInterface[]> {
    return this.tasksService
      .getAssigneeOptions(projectId, clientId, this.getTimestamp('start'), this.getTimestamp('end'))
      .pipe(
        map((empRes: any) => {
          const allAllowedAssignees = empRes.data;

          if (!allAllowedAssignees) return;
          const allTaskAssignees: AssigneeInterface[] = [];

          const allowedAssigneeIds = allAllowedAssignees.map((assignee) => assignee.id);
          this.task.assignees.forEach((a) => allTaskAssignees.push(a));
          this.task.subtasks?.forEach((s) => s.assignees?.forEach((a) => allTaskAssignees.push(a)));
          const notAllowedAssignees = allTaskAssignees.filter(
            (assignee) => !allowedAssigneeIds.includes(assignee.user.id),
          );

          return notAllowedAssignees;
        }),
      );
  }

  private saveCalcPosition() {
    this.task.projectpath = `${this.selectedCalculationPosition.client.name} -
    ${this.selectedCalculationPosition.project.name} >
    ${this.selectedCalculationPosition.project.number} >
    ${this.selectedCalculationPosition.subproject.name}`;

    this.task.project = this.selectedCalculationPosition.project;
    this.task.project.number = `${this.selectedCalculationPosition.project.number}`;

    this.task.calculationPosition = this.selectedCalculationPosition;
    if (this.task.subtasks) {
      this.task.subtasks.forEach((subtask: SubtaskInterface) => {
        subtask.calculationPosition = this.selectedCalculationPosition;
        this.subscriptions.add(this.subtaskService.updateSubtask(subtask, this.task.id).subscribe());
      });
    }
    this.tasksService.updateTask(this.task);
    this.calcPositionService.setSelectedCalcPosition(null);
    this.showCalcPosContent = false;
  }

  private getTimestamp(type: string): number {
    if (type === 'start' && this.task.startDate) return new Date(this.task.startDate).getTime();
    if (type === 'end' && this.task.endDate) return new Date(this.task.endDate).getTime();

    return undefined;
  }

  public onDeleteCalcPosClick() {
    if (this.isCpDeletionAllowed()) {
      this.task.calculationPosition = null;
      if (this.task.subtasks) this.task.subtasks.forEach((subtask) => (subtask.calculationPosition = null));
      this.calcPositionService.setSelectedCalcPosition(null);
    }
  }

  public onSave(closeModal: boolean) {
    this.submitted = true;
    if (this.taskForm.status === 'VALID') {
      this.task.title = this.taskForm.get('title').value;

      if (this.editModeOn) {
        if (
          this.task.subtasks &&
          this.task.subtasks.some((subtask) => subtask.status.statusGroup.id !== 5) &&
          this.task.status.statusGroup.id === 5
        ) {
          const modalService = new ModalService(this.domService);
          const translations = {
            title: 'Tasks.labels.statusModal.title',
            executeBtnTitle: 'Common.labels.continue',
            cancelBtnTitle: 'Common.labels.cancel',
            description: 'Tasks.labels.statusModal.description',
          };

          modalService.init(TroiUserConfirmationComponent, { translations }, {}, '400px', '500px', true);

          this.confirmationSubscriber.action.pipe(first()).subscribe((result) => {
            if (result === UserConfirmationEventEnum.EXECUTE) {
              this.updateTask(closeModal);
            }
          });
        } else {
          this.updateTask(closeModal);
        }

        if (this.selectedSubtask)
          this.subscriptions.add(
            this.subtaskService.updateSubtask(this.selectedSubtask, this.task.id).subscribe(() => {
              if (closeModal) this.modalService.destroy();
            }),
          );
      } else {
        this.subscriptions.add(
          this.taskService.createTask(this.task).subscribe((newTaskResponse: TasksResponseInterface) => {
            this.task = newTaskResponse.data[0];
            this.editModeOn = true;
            this.initForm(false);
            this.taskCreated.emit('create');

            if (closeModal) this.modalService.destroy();
          }),
        );
      }
    }
  }

  public updateTask(closeModal: boolean) {
    this.subscriptions.add(
      this.taskService.updateTask(new TaskModel(this.task)).subscribe((res) => {
        this.taskCreated.emit('update');
        this.taskHelperService.setTaskChanged(true);

        /*         if (this.getNewAssignedEmployees().length) {
          this.tasksService
            .sendEmailNotificationToAssignees(+this.task.id, this.getNewAssignedEmployees())
            .subscribe((res) => {
              console.log('email send', res);
              // pushes new Assignees to already assigned Employees to not send Email Notification multible times
              this.getNewAssignedEmployees().forEach((employee) =>
                this.assignedEmployees.push(employee),
              );
            });
        } */
        if (this.task.subtasks && this.task.subtasks.some((subtask) => subtask.status.statusGroup.id !== 5))
          this.subscriptions.add(
            this.tasksService.updateSubtasksStatus(this.task).subscribe(() => {
              this.task.subtasks.forEach((subtask) => {
                const taskIsDone = this.task.status.statusGroup.id === 5;
                const subtaskIsNotDone = subtask.status.statusGroup.id !== 5;

                if (subtaskIsNotDone) {
                  subtask.status = this.task.status;
                  if (taskIsDone) this.task.subtaskFinished++;
                }
              });
              if (closeModal) this.modalService.destroy();
            }),
          );
        else if (closeModal) this.modalService.destroy();
      }),
    );
  }

  public formatQuantity(quantity: string) {
    const quantityToNumber = parseFloat(quantity);
    const formattedQuantity = quantityToNumber.toFixed(2);
    return formattedQuantity;
  }

  public toggleSubtaskContent(value: boolean) {
    this.showSubtaskContent = value;
  }

  public setDoneSubtasks(doneSubtasks: number) {
    this.doneSubtasks = doneSubtasks;
  }

  public getBookedAssignments() {
    this.subscriptions.add(
      this.consumptionPopoverService
        .getAllBookings(this.task.calculationPosition.id)
        .subscribe((res: ConsumptionBookedResponseInterface) => {
          const bookings = res.data;
          const bookedAssignments = bookings.booked;
          this.bookedAssignments = bookedAssignments.reduce((acc: number, booking) => {
            return acc + parseFloat(booking.hours);
          }, 0);
        }),
    );
  }

  public roundToInteger(num: number): number {
    return Math.round(num);
  }

  public onStopwatchClick(task: TaskModel) {
    this.stopwatchService.createAndStart(this.stopwatchService.preparePayloadFromTask(task)).subscribe();
  }

  public isTimebookingAllowed(): boolean {
    // timebooking is only allowed for assigned users (checks subtasks, too)
    const currentUser = this.userService.getUser();
    let isAllowed = false;
    if (this.task.subtasks)
      this.task.subtasks.forEach((subtask: SubtaskInterface) => {
        if (
          subtask.assignees &&
          subtask.assignees.find((assignee) => assignee.user.id === currentUser.id) !== undefined
        )
          isAllowed = true;
      });

    return (
      (this.task.assignees &&
        this.task.assignees.find((assignee) => assignee.user.id === currentUser.id) !== undefined) ||
      isAllowed
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    if (this.filterService.isProjectContext && this.header && this.subheader) {
      this.header.style.display = 'flex';
      this.subheader.style.display = 'flex';
    }
  }
}
