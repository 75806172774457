import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PersonInterface } from '../../../../../../../shared/troi-person/interfaces/person.interface';

@Component({
  selector: 'troi-task-modal-employee-avatar',
  templateUrl: './employee-avatar.component.html',
  styleUrls: ['./employee-avatar.component.scss'],
})
export class EmployeeAvatarComponent {
  @Input() employee: PersonInterface;
  @Input() showProgressCircle = true;
  @Input() utilization = 0;
  @Input() width = '75%';
  @Input() height = '75%';
  @Input() position: 'absolute';
  @Input() addEmployee = false;
  @Input() alreadyAssigned = false;
  @Input() animationDuration = 400;

  @Output() selectEmployee = new EventEmitter<PersonInterface>();

  public showAddEmployeePopover = false;

  get outerColor(): string {
    return '#4fadf9';
  }

  get innerColor(): string {
    return '#d8d8d8';
  }

  public employeeHasImage(): boolean {
    return this.employee.image && this.employee.image !== '';
  }

  public getInitials(): string {
    return this.employee.firstName[0]?.toUpperCase() + this.employee.lastName[0]?.toUpperCase();
  }

  public onSelectPersonClick() {
    if (!this.alreadyAssigned) this.selectEmployee.emit(this.employee);
  }
}
