import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LanguagesService } from '../../../../core/services/languages.service';
import { MenuInterface } from '../../interface/menu.interface';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'menu-quick-link',
  templateUrl: './menu-quick-link.component.html',
  styleUrls: ['./menu-quick-link.component.scss'],
})
export class MenuQuickLinkComponent {
  constructor(public menuService: MenuService, public languagesService: LanguagesService) {}

  @Input() item: MenuInterface;
  @Input() active: MenuInterface;
  @Input() skeleton = false;
  @Output() dragStarted = new EventEmitter<Record<string, boolean | MenuInterface>>();

  get isActive(): boolean {
    return this.active?.id === this.item?.id;
  }

  public onDragStart(event) {
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('application/json', JSON.stringify(this.item));
    this.dragStarted.emit({ state: true, item: this.item });
  }

  public onDragEnd(event) {
    this.dragStarted.emit({ state: false, item: this.item });
  }
}
