<div
  [ngStyle]="{
    'font-size': smallDevice ? '8px' : mediumDevice ? '9px' : '10px',
    'margin-bottom': smallDevice ? '8px' : '15px'
  }"
  class="progress-circle"
  [ngClass]="{
    'consumption-popover__progress-circle': consumptionPopoverAlignment
  }"
>
  <div
    class="progress-content"
    [ngStyle]="{ top: smallDevice ? top : mediumDevice ? top : top }"
    [ngClass]="{
      'with-subtitle': solvedItems >= 0,
      active: isActive,
      'consumption-popover__content': consumptionPopoverAlignment
    }"
  >
    <ng-content></ng-content>
    <div class="progress-content__subtitle" *ngIf="solvedItems >= 0">
      <span
        [ngClass]="
          consumptionPopoverAlignment && isNegative
            ? 'consumption-popover__color-red'
            : consumptionPopoverAlignment && !isNegative
            ? 'consumption-popover__color-green'
            : ''
        "
        >{{ solvedItems }}</span
      ><span>/{{ totalItems }}</span>
    </div>
  </div>
  <div
    class="circle-progress"
    [ngClass]="{
      'circle-content': isActive,
      'consumption-popover__circle-progress': consumptionPopoverAlignment
    }"
  >
    <circle-progress
      [backgroundOpacity]="0"
      [backgroundPadding]="-50"
      [radius]="radius"
      [space]="-2"
      [toFixed]="0"
      [percent]="curProgress"
      [maxPercent]="100"
      [outerStrokeWidth]="2"
      [outerStrokeColor]="outerColor"
      [innerStrokeColor]="innerColor"
      [innerStrokeWidth]="2"
      [animationDuration]="400"
      [showUnits]="false"
      [showBackground]="false"
      [showTitle]="false"
      [showSubtitle]="false"
    >
    </circle-progress>
  </div>
</div>
