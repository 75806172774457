import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'troi-top-bar-btns',
  templateUrl: './troi-top-bar-btns.component.html',
  styleUrls: ['./troi-top-bar-btns.component.scss'],
})
export class TroiTopBarBtnsComponent {
  @Input() firstBtnValue = '';
  @Input() secondBtnValue;

  @Output() btnNewClick = new EventEmitter();
  @Output() btnExportClick = new EventEmitter();

  constructor() {}

  onNewClick() {
    this.btnNewClick.emit();
  }

  onExportClick() {
    this.btnExportClick.emit();
  }
}
