import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CalcPositionService } from '../../../../../network/calc-pos.service';
import { CalculationPositionInterface } from '../../../../../interfaces/calculationPosition.interface';
import { SearchSelectComponent } from '../../../../../../../shared/search-select/search-select.component';
import { MandantInterface } from '../../../../../interfaces/mandant.interface';
import { ProjectInterface } from '../../../../../interfaces/project.interface';
import { ClientInterface } from '../../../../../interfaces/client.interface';
import { SubprojectInterface } from '../../../../../interfaces/subproject.interface';
import { TaskInterface } from '../../../../../interfaces/task.interface';
import { Subscription } from 'rxjs';
import { CalcPosResponseInterface, SubprojectResponseInterface } from '../../../../../interfaces/responses.interface';
import { TasksHelperService } from '../../../../../services/helper.service';
import { ProjectService } from '../../../../../network/project.service';

@Component({
  selector: 'troi-task-calc-pos-content',
  templateUrl: './calc-pos-content.component.html',
  styleUrls: ['./calc-pos-content.component.scss'],
})
export class CalcPosContentComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() task: TaskInterface;
  @ViewChild('clientSelect') clientSelect: SearchSelectComponent;
  @ViewChild('customerSelect') customerSelect: SearchSelectComponent;
  @ViewChild('projectSelect') projectSelect: SearchSelectComponent;
  @Output() closeCalcPosContent = new EventEmitter();

  private subscriptions: Subscription = new Subscription();
  public calculationPosition: CalculationPositionInterface = null;
  public calcPosSearch = '';

  public loadedClients = true;
  public loadedCustomers = true;
  public loadedProjects = true;

  public isProjectContext = false;
  public projectId: string;

  public subprojects: SubprojectInterface[];
  public calcPositions: CalculationPositionInterface[];

  public resultClients = [] as MandantInterface[];
  public resultCustomers = [] as ClientInterface[];
  public resultProjects = [] as ProjectInterface[];

  constructor(
    public calcPositionService: CalcPositionService,
    private changeDetectionRef: ChangeDetectorRef,
    private helperService: TasksHelperService,
    private projectService: ProjectService,
  ) {
    this.getProjectContext();
  }

  ngOnInit(): void {
    if (this.task.calculationPosition) {
      this.calculationPosition = this.task.calculationPosition;
      this.calcPositionService.setSelectedCalcPosition(this.calculationPosition);
    } else
      this.calculationPosition = {
        serviceHeadline: null,
        serviceName: null,
        quantity: null,
        id: null,
        project: {
          id: null,
          name: null,
          client: null,
        },
        client: {
          name: null,
          id: null,
          mandant: {
            name: null,
            id: null,
          },
        },
        subproject: {
          name: null,
          id: null,
        },
      };
  }

  ngAfterViewInit() {
    if (this.task.calculationPosition) {
      this.getSelectedClient();
      this.getSelectedProject();
      this.getSelectedCustomer();

      this.changeDetectionRef.detectChanges();
      this.searchCalcPosition();
    }
  }

  private getProjectContext() {
    this.subscriptions.add(
      this.helperService.isProjectContext.subscribe((value) => {
        if (value) this.isProjectContext = true;
      }),
    );

    this.subscriptions.add(
      this.helperService.projectId.subscribe((projectId) => {
        if (this.isProjectContext && projectId) this.getCustomerAndClientByProjectId(projectId);
      }),
    );
  }

  private getCustomerAndClientByProjectId(projectId: string) {
    this.projectService.getProjectInfo(projectId).subscribe((res) => {
      const project = res.data[0];
      this.calculationPosition.client = project.customer;
      this.calculationPosition.client.mandant = project.client;
      this.calculationPosition.project = {
        id: project.id,
        name: project.name,
      };
      this.getSelectedClient();
      this.getSelectedProject();
      this.getSelectedCustomer();
    });
  }

  public getSelectedClient() {
    this.customerSelect.setValue(this.calculationPosition.client);
  }

  public getSelectedProject() {
    this.projectSelect.setValue(this.calculationPosition.project);
  }

  public getSelectedCustomer() {
    this.clientSelect.setValue(this.calculationPosition.client.mandant);
  }

  public searchCalcPos() {
    if (!this.calcPosSearch) return this.calcPositions;
    else
      return this.calcPositions.filter((item) => {
        return item.serviceHeadline.toLowerCase().includes(this.calcPosSearch.toLowerCase());
      });
  }

  public onSubprojectSelect(subproject: SubprojectInterface): void {
    if (
      this.calculationPosition.client.id &&
      this.calculationPosition.client.mandant.id &&
      this.calculationPosition.project.id
    ) {
      if (this.task.subproject === subproject.name.en) this.calculationPosition.subproject = null;
      else {
        this.calculationPosition.subproject.name = subproject.name.en;
        this.calculationPosition.subproject.id = subproject.id;
        this.task.subproject = subproject.id;
        this.searchCalcPosition();
      }
    }
  }

  private searchCalcPosition() {
    const client: string =
      this.calculationPosition.client.mandant && this.calculationPosition.client.mandant.hasOwnProperty('id')
        ? this.calculationPosition.client.mandant['id'].toString()
        : '';

    const customer: string =
      this.calculationPosition.client && this.calculationPosition.client.id
        ? this.calculationPosition.client['id'].toString()
        : '';

    const project: string =
      this.calculationPosition.project && this.calculationPosition.project.id
        ? this.calculationPosition.project['id'].toString()
        : '';

    const subproject: string =
      this.calculationPosition.subproject && this.calculationPosition.subproject.id
        ? this.calculationPosition.subproject['id'].toString()
        : '';

    this.subscriptions.add(
      this.calcPositionService
        .getCalcPositions(client, customer, project, subproject)
        .subscribe((result: CalcPosResponseInterface) => {
          this.calcPositions = result.data.map((position: CalculationPositionInterface) => {
            const quantity = parseFloat(position.quantity);
            position.quantity = quantity.toFixed(2);
            return position;
          });

          if (
            this.calcPositions.length > 0 &&
            !this.calcPositions.some((calcPos) => calcPos.id === this.calculationPosition.id)
          )
            this.autoSelectFirstPosition(result.data[0]);
        }),
    );
  }

  private autoSelectFirstPosition(firstCalcPos: CalculationPositionInterface) {
    this.calculationPosition.serviceHeadline = firstCalcPos.serviceHeadline;
    this.calculationPosition.serviceName = firstCalcPos.serviceName;
    this.calculationPosition.id = firstCalcPos.id;
    this.calculationPosition.quantity = firstCalcPos.quantity;
    this.calculationPosition.project = firstCalcPos.project;

    this.calcPositionService.setSelectedCalcPosition(this.calculationPosition);
  }

  public onClientSearchStringChanged(term: string): void {
    this.loadedClients = false;
    this.subscriptions.add(
      this.calcPositionService.searchClients(term).subscribe((result: MandantInterface[]) => {
        this.resultClients = result;
        this.loadedClients = true;
      }),
    );
  }

  public onCustomerSearchStringChanged(term: string): void {
    const client: string = this.calculationPosition.client.mandant
      ? this.calculationPosition.client.mandant.hasOwnProperty('id')
        ? this.calculationPosition.client.mandant['id'].toString()
        : ''
      : '';

    this.loadedCustomers = false;
    this.subscriptions.add(
      this.calcPositionService.searchCustomers(term, client).subscribe((result: ClientInterface[]) => {
        this.resultCustomers = result;
        this.loadedCustomers = true;
      }),
    );
  }

  public onProjectSearchStringChanged(term: string): void {
    const client: string =
      this.calculationPosition.client.mandant && this.calculationPosition.client.mandant.hasOwnProperty('id')
        ? this.calculationPosition.client.mandant['id'].toString()
        : '';

    const customer: string =
      this.calculationPosition.client && this.calculationPosition.client.hasOwnProperty('id')
        ? this.calculationPosition.client['id'].toString()
        : '';

    this.loadedProjects = false;
    this.subscriptions.add(
      this.calcPositionService.searchProjects(term, client, customer).subscribe((result: ProjectInterface[]) => {
        this.resultProjects = result;
        this.loadedProjects = true;
      }),
    );
  }

  public onClientSelected(client): void {
    if (!client) {
      client = null;
      this.projectSelect.reset();
      this.customerSelect.reset();
      this.subprojects = null;
      this.calcPositions = null;
    } else {
      this.calculationPosition.client.mandant.id = client.id;
      this.calculationPosition.client.mandant.name = client.name;
    }
  }

  public onCustomerSelected(customer): void {
    if (!customer) {
      customer = null;
      this.projectSelect.reset();
      this.resultProjects = [];
      this.subprojects = null;
      this.calcPositions = null;
    } else {
      this.calculationPosition.client.id = customer.id;
      this.calculationPosition.client.name = customer.name;
    }
  }

  public onProjectSelected(project): void {
    if (!project) {
      project = null;
      this.subprojects = null;
      this.calcPositions = null;
    } else {
      this.calculationPosition.project.id = project.id;
      this.calculationPosition.project.name = project.name;

      this.getSubprojects(project.id);
    }
  }

  private getSubprojects(projectId: string) {
    this.calcPositionService.fetchSubprojectList(projectId).subscribe((result: SubprojectResponseInterface) => {
      this.subprojects = result.items;
      if (this.subprojects.length > 0 && !this.calculationPosition.subproject.id)
        this.autoSelectFirstSubproject(this.subprojects[0]);
      else if (this.subprojects.length === 0) this.searchCalcPosition();
    });
  }

  public autoSelectFirstSubproject(firstSubproject: SubprojectInterface) {
    this.calculationPosition.subproject.name = firstSubproject.name.en;
    this.calculationPosition.subproject.id = firstSubproject.id;
    this.task.subproject = firstSubproject.id;

    this.searchCalcPosition();
  }

  public onBackClick(): void {
    this.closeCalcPosContent.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
