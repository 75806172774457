import { UserConfirmationSubscriber } from './../../../../../shared/troi-user-confirmation/user-confirmation.subscriber';
import { UserConfirmationEventEnum } from './../../../../../shared/troi-user-confirmation/user-confirmation-event.enum';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseModalDirective } from '../../../../../shared/troi-base-modal/baseModal.component';
import { ModalService } from '../../../../../shared/troi-modals/modal.service';
import { TaskFiltersService } from '../../../services/filters.service';
import { TaskModel } from '../../../models/task.model';
import { TaskActionsEnum } from '../../../enum/task-actions';
import { ProjectInterface } from '../../../interfaces/project.interface';
import { ClientInterface } from '../../../../../core/interfaces/client.interface';
import { MandantInterface } from '../../../interfaces/mandant.interface';
import { CalculationPositionInterface } from '../../../interfaces/calculationPosition.interface';
import { SubprojectInterface } from '../../../interfaces/subproject.interface';
import { CalcPositionService } from '../../../network/calc-pos.service';
import { SearchSelectComponent } from '../../../../../shared/search-select/search-select.component';
import { CalcPosResponseInterface, SubprojectResponseInterface } from '../../../interfaces/responses.interface';
import { Subscription } from 'rxjs';
import { TaskInterface } from '../../../interfaces/task.interface';
import { TasksService } from '../../../network/tasks.service';
import { AssigneeInterface } from '../../../interfaces/assignee.interface';

@Component({
  selector: 'app-move-copy-task-modal',
  templateUrl: './move-copy-task-modal.component.html',
  styleUrls: ['./move-copy-task-modal.component.scss'],
})
export class MoveCopyTaskModalComponent extends BaseModalDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() translations;
  @Input() task: TaskModel;
  @Input() action: TaskActionsEnum;
  @Input() taskId: number;

  @ViewChild('clientSelect') clientSelect: SearchSelectComponent;
  @ViewChild('customerSelect') customerSelect: SearchSelectComponent;
  @ViewChild('projectSelect') projectSelect: SearchSelectComponent;
  @ViewChild('calcPosSelect') calcPosSelect: SearchSelectComponent;

  public moveCopyTaskForm = new FormGroup({
    projectName: new FormControl('', Validators.required),
    moveCopyTask: new FormControl(),
  });

  public taskToCopy: TaskInterface;

  public submitted = false;
  public taskTitle = '';
  public options;

  public loadedClients = true;
  public loadedCustomers = true;
  public loadedProjects = true;
  public loadedCalcPositions = true;

  public subprojects: SubprojectInterface[];
  public calcPositions: any[];

  public resultClients = [] as MandantInterface[];
  public resultCustomers = [] as ClientInterface[];
  public resultProjects = [] as ProjectInterface[];

  private subscriptions: Subscription = new Subscription();

  public calculationPosition: CalculationPositionInterface = null;
  public cpSearchString = '';

  public constructor(
    public modalService: ModalService,
    public filtersService: TaskFiltersService,
    private subscriber: UserConfirmationSubscriber,
    public calcPositionService: CalcPositionService,
    private changeDetectionRef: ChangeDetectorRef,
    private tasksService: TasksService,
  ) {
    super(modalService, false, true);
  }

  ngOnInit() {
    this.taskToCopy = JSON.parse(JSON.stringify(this.task));
    if (this.action === TaskActionsEnum.COPY) {
      this.taskTitle = this.task.title + ' -Copy';
    } else {
      this.taskTitle = this.task.title;
    }

    if (this.task.calculationPosition) this.taskToCopy.calculationPosition = this.task.calculationPosition;
    else this.initCalcPos();
  }

  ngAfterViewInit() {
    if (this.task.calculationPosition) this.preSelectValues();
  }

  private initCalcPos(client?: MandantInterface) {
    this.taskToCopy.calculationPosition = {
      serviceHeadline: null,
      serviceName: null,
      quantity: null,
      id: null,
      project: {
        id: null,
        name: null,
        client: null,
      },
      client: {
        name: null,
        id: null,
        mandant: client ?? {
          name: null,
          id: null,
        },
      },
      subproject: {
        name: null,
        id: null,
      },
    };
  }

  public close() {
    this.subscriber.action.next(UserConfirmationEventEnum.CLOSE);
    this.subscriber.actionWithData.next({
      event: UserConfirmationEventEnum.CLOSE,
      data: this.task,
    });
    super.closeSecond();
  }

  public execute() {
    this.submitted = true;

    // copying needs a new task id (otherwise it's just moving the task)

    if (this.moveCopyTaskForm.status === 'VALID') {
      this.taskToCopy.title = this.taskTitle;
      this.subscriber.action.next(UserConfirmationEventEnum.EXECUTE);
      this.subscriber.actionWithData.next({
        event: UserConfirmationEventEnum.EXECUTE,
        data: { task: this.taskToCopy, action: this.action },
      });
      super.closeSecond();
    }
  }

  public preSelectValues() {
    this.customerSelect.setValue(this.taskToCopy.calculationPosition.client);
    this.projectSelect.setValue(this.taskToCopy.calculationPosition.project);
    /*     this.clientSelect.setValue(this.taskToCopy.calculationPosition.client.mandant); */
    const calcPos = {
      serviceHeadline: this.task.calculationPosition.serviceName,
      name: this.task.calculationPosition.serviceName,
      id: this.task.calculationPosition.id,
    };
    this.calcPosSelect.setValue(calcPos);

    this.changeDetectionRef.detectChanges();
  }

  private searchCalcPosition() {
    const client: string =
      this.taskToCopy.calculationPosition.client.mandant && this.taskToCopy.calculationPosition.client.mandant.id
        ? this.taskToCopy.calculationPosition.client.mandant['id'].toString()
        : '';

    const customer: string =
      this.taskToCopy.calculationPosition.client && this.taskToCopy.calculationPosition.client.id
        ? this.taskToCopy.calculationPosition.client['id'].toString()
        : '';

    const project: string =
      this.taskToCopy.calculationPosition.project && this.taskToCopy.calculationPosition.project.id
        ? this.taskToCopy.calculationPosition.project['id'].toString()
        : '';

    this.subscriptions.add(
      this.calcPositionService
        .getCalcPositions(client, customer, project)
        .subscribe((result: CalcPosResponseInterface) => {
          this.calcPositions = result.data.map((position) => {
            return {
              ...position,
              name: position.serviceName,
            };
          });
        }),
    );
  }

  public onClientSearchStringChanged(term: string): void {
    this.loadedClients = false;
    this.subscriptions.add(
      this.calcPositionService.searchClients(term).subscribe((result: MandantInterface[]) => {
        this.resultClients = result;
        this.loadedClients = true;
      }),
    );
  }

  public onCustomerSearchStringChanged(term: string): void {
    const client: string = this.taskToCopy.calculationPosition.client.mandant.id
      ? this.taskToCopy.calculationPosition.client.mandant['id'].toString()
      : '';

    this.loadedCustomers = false;
    this.subscriptions.add(
      this.calcPositionService.searchCustomers(term, client).subscribe((result: ClientInterface[]) => {
        this.resultCustomers = result;
        this.loadedCustomers = true;
      }),
    );
  }

  public onProjectSearchStringChanged(term: string): void {
    const client: string =
      this.taskToCopy.calculationPosition.client.mandant.id && this.taskToCopy.calculationPosition.client.mandant.id
        ? this.taskToCopy.calculationPosition.client.mandant['id'].toString()
        : '';

    const customer: string =
      this.taskToCopy.calculationPosition.client && this.taskToCopy.calculationPosition.client.id
        ? this.taskToCopy.calculationPosition.client['id'].toString()
        : '';

    this.loadedProjects = false;
    this.subscriptions.add(
      this.calcPositionService.searchProjects(term, client, customer).subscribe((result: ProjectInterface[]) => {
        this.resultProjects = result;
        this.loadedProjects = true;
      }),
    );
  }

  public onCalcPosSearchStringChanged(term: string) {
    this.cpSearchString = term;
  }

  public findCalcPosition() {
    return this.calcPositions ? this.calcPositions.filter((pos) => pos.serviceName.includes(this.cpSearchString)) : [];
  }

  public onClientSelected(client): void {
    if (!client) {
      client = null;
      this.projectSelect.reset();
      this.customerSelect.reset();
      this.calcPosSelect.reset();
      this.calcPositions = null;
    } else {
      this.taskToCopy.calculationPosition.client.mandant.id = client.id;
      this.taskToCopy.calculationPosition.client.mandant.name = client.name;
    }
  }

  public onCustomerSelected(customer): void {
    if (!customer) {
      customer = null;
      this.projectSelect.reset();
      this.calcPosSelect.reset();
      this.taskToCopy.assignees = [];
      this.initCalcPos(this.task.calculationPosition.client.mandant);
      this.calcPositions = null;
    } else {
      this.taskToCopy.calculationPosition.client.id = customer.id;
      this.taskToCopy.calculationPosition.client.name = customer.name;
      this.detectProjectLength();
    }
  }

  // detects if there are projects based on seleced customer in client
  // and starts search for calculation positions if not.
  public detectProjectLength() {
    const client: string =
      this.taskToCopy.calculationPosition.client.mandant && this.taskToCopy.calculationPosition.client.mandant.id
        ? this.taskToCopy.calculationPosition.client.mandant['id'].toString()
        : '';

    const customer: string =
      this.taskToCopy.calculationPosition.client && this.taskToCopy.calculationPosition.client.id
        ? this.taskToCopy.calculationPosition.client['id'].toString()
        : '';

    this.subscriptions.add(
      this.calcPositionService.searchProjects('', client, customer).subscribe((result: ProjectInterface[]) => {
        const projects = result;
        if (projects.length === 0) this.searchCalcPosition();
      }),
    );
  }

  public onProjectSelected(project): void {
    if (!project) {
      this.calcPosSelect.reset();
      project = null;
      this.calcPositions = null;
      this.initCalcPos(this.task.calculationPosition.client.mandant);
      this.taskToCopy.assignees = [];
    } else {
      this.taskToCopy.calculationPosition.project.id = project.id;
      this.taskToCopy.calculationPosition.project.name = project.name;

      this.taskToCopy.project = project;

      this.searchCalcPosition();
    }
  }

  public removeAssigneeFromTask(assignee: AssigneeInterface): void {
    this.taskToCopy.assignees = this.taskToCopy.assignees.filter((x) => x.id !== assignee.id);
  }

  public onCalcPosSelect(calcPos: CalculationPositionInterface) {
    if (calcPos && this.taskToCopy.calculationPosition.serviceHeadline !== calcPos.serviceHeadline) {
      this.taskToCopy.calculationPosition.serviceHeadline = calcPos.serviceHeadline;
      this.taskToCopy.calculationPosition.serviceName = calcPos.serviceName;
      this.taskToCopy.calculationPosition.id = calcPos.id;
      this.taskToCopy.calculationPosition.quantity = calcPos.quantity;
      this.taskToCopy.calculationPosition.subproject = calcPos.subproject;
      this.taskToCopy.projectpath = `${calcPos.client.name} -
    ${calcPos.project.name} >
    ${calcPos.project.number} >
    ${calcPos.subproject.name}`;
    } else if (!calcPos) this.taskToCopy.assignees = [];
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
