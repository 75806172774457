<div class="calc-pos-assignment">
  <div class="assign-hours mb">
    <span class="paragraph-title">{{
      'Tasks.labels.createTaskModal.assignHours' | translate
    }}</span>

    <input
      class="assign-hours__input"
      [placeholder]="'00.00'"
      type="number"
      [value]="curTask.hoursBudget"
      (focusout)="onAssignHoursInput($event.target)"
      (keyup.enter)="onAssignHoursInput($event.target)"
    />
  </div>

  <troi-popover [popoverClass]="'consumptionPopover'" [width]="'35rem'">
    <div
      (click)="onShowConsumptionPopoverClick()"
      popoverTrigger
      style="cursor: pointer"
      *ngIf="curTask.calculationPosition"
      class="selected-calc-pos"
    >
      <span class="selected-calc-pos__calc-pos"
        >K-{{ curTask.calculationPosition.id }} -
        {{ curTask.calculationPosition.serviceHeadline }}</span
      ><span class="selected-calc-pos__assigned-hours"
        ><p>{{ calculateAssignedHours() }}</p>
        /
        <p>{{ formatString(curTask.calculationPosition.quantity) }}</p>
      </span>
      <troi-icon
        class="selected-calc-pos__stopwatch-icon"
        [icon]="'icon-clock-with-minus'"
      ></troi-icon>
    </div>
    <troi-consumption-popover
      *ngIf="loadConsumptionPopover"
      (totalTaskHours)="getAssignedHoursOfAllTasks($event)"
      [task]="curTask"
      popoverContent
    ></troi-consumption-popover>
  </troi-popover>
</div>
<div class="general-content-scrollable">
  <div class="mtb">
    <div class="title-row">
      <div class="title-row__col1">
        <span class="paragraph-title">{{
          'Tasks.labels.createTaskModal.description' | translate
        }}</span>
      </div>
      <div class="title-row__col2">
        <troi-dropdown-select
          [initValue]="phrasesOptions[0]"
          [options]="phrasesOptions"
          size="11px"
          [treeMode]="true"
          [enabled]="phrasesOptions.length > 0"
          textAlignment="center"
          placeholder="{{ 'Tasks.labels.createTaskModal.textModule' | translate }}"
          [noBorder]="true"
          (selected)="onTextModuleSelect($event)"
        ></troi-dropdown-select>
      </div>
    </div>
    <div class="content-row">
      <quill-editor
        [(ngModel)]="curTask.description"
        (ngModelChange)="convertURL($event)"
        placeholder="{{ 'Tasks.labels.createTaskModal.addDescription' | translate }}"
        [modules]="helperService.quillConfig"
      ></quill-editor>
    </div>
    <div class="content-row">
      <troi-tag-manager
        [task]="curTask"
        [assignedTags]="curTask.tags"
        (tagsChanged)="onTagsChanged($event)"
      ></troi-tag-manager>
    </div>
  </div>
  <div class="mtb">
    <troi-attachments [curTask]="curTask"></troi-attachments>
  </div>
</div>
