import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { TaskInterface } from '../../interfaces/task.interface';
import { ConsumptionPopoverService } from '../../network/consumption-popover.service';

import {
  ConsumptionBookedResponseInterface,
  ConsumptionPlanResponseInterface,
} from '../../interfaces/responses.interface';
import {
  ConsumptionAssignmentsInterface,
  ConsumptionPlanInterface,
  PlannedValueInterface,
} from '../../interfaces/consumption.interface';
import { Subscription, take } from 'rxjs';
import { TasksHelperService } from '../../services/helper.service';

@Component({
  selector: 'troi-consumption-popover',
  templateUrl: './consumption-popover.component.html',
  styleUrls: ['./consumption-popover.component.scss'],
})
export class ConsumptionPopoverComponent implements OnInit, OnDestroy {
  @Input() task: TaskInterface;
  @Output() totalTaskHours = new EventEmitter<number>();

  public totalHoursOfAssignedTasks = 0;
  public calculationPositionData: ConsumptionPlanInterface;
  public sumOfBookedAssignments: number;
  public loaded = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private consumptionPopoverService: ConsumptionPopoverService,
    private helperService: TasksHelperService,
  ) {}

  ngOnInit() {
    if (this.task.calculationPosition) {
      this.getBookedAssignments();
      this.getAssignedTasks();
    }
    this.onTaskHourChange();
  }

  public onTaskHourChange() {
    this.subscriptions.add(
      this.helperService.taskHoursChangedSub.pipe(take(1)).subscribe(() => {
        this.getAssignedTasks();
      }),
    );
  }

  public sumIsNegative(): boolean {
    return this.calculationPositionData.calculationPosition
      ? +this.calculationPositionData.calculationPosition.hours - this.sumOfBookedAssignments < 0
      : false;
  }

  private getAssignedTasks(): void {
    this.subscriptions.add(
      this.consumptionPopoverService
        .getAllPlans(this.task.calculationPosition.id)
        .subscribe((res: ConsumptionPlanResponseInterface) => {
          this.calculationPositionData = res.data;
          this.totalTaskHours.emit(+this.calculationPositionData.sum);
          this.loaded = true;
        }),
    );
  }

  public getNotAssignedHours(): string {
    if (this.calculationPositionData.calculationPosition) {
      const calculatedTotal = +this.calculationPositionData.calculationPosition.hours;
      const notAssigned = calculatedTotal - +this.calculationPositionData.sum;
      return notAssigned.toFixed(2);
    }
  }

  public getActualAndTargetDifference(): string {
    if (this.calculationPositionData.calculationPosition) {
      const calculatedTotal = +this.calculationPositionData.calculationPosition.hours;
      const difference = calculatedTotal - this.sumOfBookedAssignments;
      return difference.toFixed(2);
    } else return '0.00';
  }

  public getTotalItems(): string {
    if (this.calculationPositionData.calculationPosition) {
      return this.formatString(this.calculationPositionData.calculationPosition.hours);
    } else return '0.00';
  }

  public formatString(hours: string): string {
    if (hours) return parseFloat(hours).toFixed(2);
    else return '0.00';
  }

  private getBookedAssignments(): void {
    this.subscriptions.add(
      this.consumptionPopoverService
        .getAllBookings(this.task.calculationPosition.id)
        .subscribe((res: ConsumptionBookedResponseInterface) => {
          const bookings = res.data;
          const bookedAssignments = bookings.booked;
          this.sumOfBookedAssignments = bookedAssignments.reduce((acc: number, booking) => {
            return acc + parseFloat(booking.hours);
          }, 0);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
