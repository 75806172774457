import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import * as _ from 'lodash';

interface Dictionary<T> {
  [key: string]: T;
}

export enum BackendError {
  TRANSLATED = 'backendErrorTranslated',
  NOT_TRANSLATED = 'backendErrorNotTranslated',
}

@Injectable()
export class ErrorTranslationService {
  private errorMessages: Dictionary<string> = {
    required: 'Form.Error.Required',
    minlength: 'Form.Error.MinLength',
    email: 'Form.Error.Email',
    pattern: 'Form.Error.Pattern',
  };

  public constructor(protected translationService: TranslateService) {}

  public getErrorMessages(errors) {
    const messages = [];
    _.forEach(errors, (interpolateParams, key) => {
      if (key === BackendError.TRANSLATED) {
        messages.push(interpolateParams.value);
      } else if (key === BackendError.NOT_TRANSLATED) {
        this.translationService.get(key, interpolateParams).subscribe((result: string) => {
          messages.push(result);
        });
      } else if (this.errorMessages[key]) {
        this.translationService.get(this.errorMessages[key], interpolateParams).subscribe((result: string) => {
          messages.push(result);
        });
      }
    });

    return messages;
  }
  public getErrorMessagesForTextfield(control: AbstractControl): Record<string, string>[] {
    const messages = [];
    if (control.hasError('required')) {
      messages.push({ text: this.errorMessages.required });
    }
    if (control.hasError('pattern')) {
      const requiredPattern = control.errors['pattern'].requiredPattern;
      messages.push({
        text: this.errorMessages.pattern,
        params: requiredPattern.substring(requiredPattern.indexOf('[') + 1, requiredPattern.lastIndexOf(']')),
      });
    }
    if (control.hasError('email')) {
      messages.push({ text: this.errorMessages.email });
    }
    return messages;
  }
}
